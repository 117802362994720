import { BodyParts } from '../types';
import { partsOrder } from './bodyData';

export const createName = (parts: BodyParts) => {
  let partsInvolved = Object.values(parts || {}).reduce(
    (arr: string[], sides) => {
      Object.entries(sides).forEach(([partName, partObj]) => {
        if (partObj.left || partObj.right) {
          const partChanged = partName.replace(/-/g, ' ');
          if (!arr.includes(partChanged)) {
            arr.push(partChanged);
          }
        }
      });
      return arr;
    },
    [],
  );
  const backParts = partsInvolved.filter((part) => part.includes('back'));
  if (backParts?.length > 1) {
    partsInvolved = partsInvolved.filter((part) => !part.includes('back'));
    partsInvolved.push('back');
  }
  return partsInvolved
    .sort((a, b) => {
      const aOrder = partsOrder[a] || 20;
      const bOrder = partsOrder[b] || 20;
      if (aOrder < bOrder) {
        return -1;
      }
      if (aOrder > bOrder) {
        return 1;
      }
      return 0;
    })
    .reduce(
      (acc, curr, i) =>
        `${acc}${
          i ? (partsInvolved.length === i + 1 ? ' and ' : ', ') : ''
        }${curr}`,
      '',
    );
};
