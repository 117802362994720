import { Transition } from '@headlessui/react';
import React from 'react';

const verbiage = {
  en: {
    complete: 'Survey complete!',
    thankYou: 'Thank you.',
    more: 'It looks like you have a little more left to do, please click "Next" below to continue.',
    back: 'Back to request',
    next: 'Next',
  },
  es: {
    complete: 'Ha terminado.',
    thankYou: 'Gracias.',
    more: 'Parece que aun debe llenar otros cuestionarios. Haga click en continuar.',
    back: 'Volver a solicitar',
    next: 'Continuar',
  },
};

type Props = {
  show: boolean;
  message: string;
  primaryColor: string;
  language: 'en' | 'es';
  nextStep: () => void;
};

export const SurveyComplete: React.FC<Props> = ({
  show,
  message,
  primaryColor,
  language = 'en',
  nextStep,
}) => {
  return (
    <Transition
      show={show}
      enter="transition ease-out duration-300"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-300"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div className="sm:min-h-screen flex items-center justify-center px-4 w-full">
        <div className="max-w-5xl mx-auto">
          <div className="text-center">
            <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
              {verbiage[language === 'es' ? 'es' : 'en'].complete}
              <br />
              <span
                style={{
                  color: primaryColor,
                }}
              >
                {verbiage[language === 'es' ? 'es' : 'en'].thankYou}
              </span>
            </h2>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              {message}
            </p>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              {verbiage[language === 'es' ? 'es' : 'en'].more}
            </p>
            <span className="mt-8 inline-flex">
              <button
                type="button"
                data-cy="next-step"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white hover:opacity-90 focus:outline-none transition ease-in-out duration-150"
                style={{
                  backgroundColor: primaryColor,
                }}
                onClick={() => nextStep()}
              >
                {verbiage[language === 'es' ? 'es' : 'en'].next}
              </button>
            </span>
          </div>
        </div>
      </div>
    </Transition>
  );
};
