import React from 'react';

const months = [
  { value: 0, label: 'January' },
  { value: 1, label: 'February' },
  {
    value: 2,
    label: 'March',
  },
  { value: 3, label: 'April' },
  { value: 4, label: 'May' },
  { value: 5, label: 'June' },
  {
    value: 6,
    label: 'July',
  },
  { value: 7, label: 'August' },
  { value: 8, label: 'September' },
  { value: 9, label: 'October' },
  {
    value: 10,
    label: 'November',
  },
  { value: 11, label: 'December' },
];
type Props = {
  selectedMonth: number;
  onChange: (val: number) => void;
  primaryColor: string;
};

const MonthSelect: React.FC<Props> = ({
  onChange,
  selectedMonth,
  primaryColor,
}) => {
  return (
    <div className="grid grid-cols-3 gap-x-3 gap-y-9">
      {months.map((month) => {
        const isSelected = month.value === selectedMonth;

        return (
          <div
            onClick={() => onChange(month.value)}
            key={month.value}
            className={`col-span-1 text-center cursor-pointer px-3  rounded-full transition duration-150 ease-in-out hover:bg-gray-100 focus:bg-gray-100 focus:text-gray-900 focus:outline-none p-1  ${
              isSelected ? `text-white` : 'text-gray-600 hover:text-gray-900'
            }`}
            style={isSelected ? { backgroundColor: primaryColor } : {}}
          >
            {month.label}
          </div>
        );
      })}
    </div>
  );
};

export default MonthSelect;
