import React, { useContext, useEffect } from 'react';
import { MeContext } from '../../contexts/me.context';
import KioskAssistance from './KioskAssistance';

const verbiage = {
  en: {
    title: 'All done!',
    subtitle: 'You have been checked in.',
    description: 'A staff member will be with you shortly.',
    logout: 'Logout',
  },
  es: {
    title: '¡Todo listo!',
    subtitle: 'Usted ha sido registrado.',
    description: 'Un miembro del personal estará con usted en breve.',
    logout: 'Cerrar sesión',
  },
};

type Props = {
  logout: () => void;
};

const KioskComplete: React.FC<Props> = ({ logout }) => {
  const {
    clinicInfo: { primaryColor },
  } = useContext(MeContext);

  useEffect(() => {
    setTimeout(() => {
      logout();
    }, 5000);
  }, [logout]);

  return (
    <KioskAssistance
      title={verbiage.en.title}
      subtitle={verbiage.en.subtitle}
      message={verbiage.en.description}
      buttons={
        <button
          type="button"
          className="inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white hover:opacity-90 focus:outline-none transition ease-in-out duration-150"
          style={{
            backgroundColor: primaryColor,
          }}
          onClick={logout}
        >
          Logout
        </button>
      }
      show={true}
    />
  );
};

export default KioskComplete;
