import { MeContext } from '../../../contexts/me.context';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  title: string;
  description: string;
  stepNumber: number;
  requestId: string;
  complete?: boolean;
  upcoming?: boolean;
  finalStep?: boolean;
  kiosk?: boolean;
  disabled?: boolean;
  language?: string;
};

const Step: React.FC<Props> = ({
  title,
  description,
  stepNumber,
  requestId,
  complete,
  upcoming,
  finalStep,
  kiosk,
  disabled,
  language = 'en',
}) => {
  const {
    clinicInfo: { primaryColor },
  } = useContext(MeContext);
  return (
    <li className="relative pb-10">
      {!finalStep && (
        <div
          className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
          style={
            complete
              ? {
                  backgroundColor: primaryColor,
                }
              : {}
          }
        />
      )}
      <Link
        to={
          kiosk
            ? `/kiosk/${stepNumber}`
            : `/requests/${requestId}/${stepNumber}`
        }
        className={[
          'relative flex items-center space-x-4 group focus:outline-none',
          complete || disabled ? 'pointer-events-none cursor-not-allowed' : '',
        ].join(' ')}
      >
        <div className="h-9 flex items-center">
          {complete ? (
            <span
              className="relative z-10 w-8 h-8 flex items-center justify-center rounded-full hover:opacity-90 transition ease-in-out duration-150"
              style={{
                backgroundColor: primaryColor,
              }}
            >
              <svg
                className="w-5 h-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          ) : (
            <span
              className={[
                'relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 rounded-full',
                upcoming
                  ? ''
                  : 'border-gray-300 group-hover:border-gray-400 group-focus:border-gray-400 transition ease-in-out duration-150',
              ].join(' ')}
              style={
                upcoming && !disabled
                  ? {
                      borderColor: primaryColor,
                    }
                  : {}
              }
            >
              <span
                className={[
                  'h-2.5 w-2.5 rounded-full',
                  upcoming
                    ? ''
                    : 'group-hover:bg-gray-300 group-focus:bg-gray-300 transition ease-in-out duration-150',
                ].join(' ')}
                style={
                  upcoming && !disabled
                    ? {
                        backgroundColor: primaryColor,
                      }
                    : {}
                }
              />
            </span>
          )}
        </div>
        <div className="min-w-0">
          <h3
            className={[
              'text-xs leading-4 font-semibold uppercase tracking-wide',
              upcoming && !disabled ? '' : 'text-gray-500',
            ].join(' ')}
            style={
              upcoming && !disabled
                ? {
                    color: primaryColor,
                  }
                : {}
            }
            data-cy={title}
          >
            {title}
          </h3>
          <p className="text-sm leading-5 text-gray-500">
            {disabled && language === 'es'
              ? 'Este paso debe completarse desde un dispositivo personal'
              : disabled
                ? 'This step must be completed from a personal device'
                : description}
          </p>
        </div>
      </Link>
    </li>
  );
};

export default Step;
