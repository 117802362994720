import {
  DateValue,
  DateValueEstimate,
  SurveyQuestionTypeOptions,
} from '@chiroup/core';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import DatepickerDays from './DatepickerDays';
import DatepickerDaysOfWeek from './DatepickerDaysOfWeek';
import DatepickerEstimate from './DatepickerEstimate';
import MonthYearDialog from './MonthYearDialog';

type Props = {
  options?: SurveyQuestionTypeOptions;
  onChange: (val?: DateValue) => void;
  primaryColor: string;
  language?: string;
};

export const Datepicker: React.FC<Props> = ({
  options = { allowEstimate: false, pastOnly: false, futureOnly: false },
  onChange,
  primaryColor,
  language = 'en',
}) => {
  const startOfMonth = dayjs().startOf('month');
  const [viewDate, setViewDate] = useState(startOfMonth);
  const [skipDays, setSkipDays] = useState(startOfMonth.day());
  const [selectedDate, setSelectedDate] = useState<Dayjs>();
  const [exactDate, setExactDate] = useState(true);
  const [monthDialogOpen, setMonthDialogOpen] = useState<boolean>(false);
  const [estimate, setEstimate] = useState<DateValueEstimate>({
    unit: 'day',
    value: 1,
  });

  useEffect(() => {
    setSkipDays(viewDate.day());
  }, [viewDate]);

  const prevMonth = () => {
    setViewDate((prev) => prev.subtract(1, 'month'));
  };

  const nextMonth = () => {
    setViewDate((prev) => prev.add(1, 'month'));
  };

  const prevYear = () => {
    setViewDate((prev) => prev.subtract(1, 'year'));
  };

  const nextYear = () => {
    setViewDate((prev) => prev.add(1, 'year'));
  };

  const setMonthYear = (month: number, year: number) => {
    setViewDate(viewDate.set('month', month).set('year', year));
  };

  const selectDate = (day: number) => {
    const newDate = viewDate.set('date', day);
    setSelectedDate(newDate);
    onChange({ exact: newDate.format('MM/DD/YYYY') });
  };

  const selectExactDate = (val: boolean) => {
    setSelectedDate(undefined);
    setExactDate(val);
    onChange(val ? undefined : { estimate });
  };

  const onChangeEstimate = useCallback(
    (val: DateValueEstimate) => {
      setEstimate(val);
      onChange({ estimate: val });
    },
    [onChange],
  );

  return (
    <div>
      {options.allowEstimate && (
        <div className="font-small sm:align-center sm:flex sm:flex-col">
          <div className="relative mb-6 flex self-center rounded-lg bg-gray-300 p-0.5">
            <button
              onClick={selectExactDate.bind(null, true)}
              type="button"
              className={[
                'relative w-1/2 border-gray-300 rounded-md py-2 text-sm leading-5 text-gray-700 whitespace-nowrap hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue focus:z-10 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 sm:w-auto sm:px-8',
                exactDate ? 'bg-white shadow-sm' : '',
              ].join(' ')}
            >
              {language === 'es' ? 'Fecha Exacta' : 'Exact date'}
            </button>
            <button
              onClick={selectExactDate.bind(null, false)}
              type="button"
              className={[
                'relative w-1/2 border-gray-300 rounded-md py-2 text-sm leading-5 text-gray-700 whitespace-nowrap hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue focus:z-10 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 sm:w-auto sm:px-8',
                exactDate ? '' : 'bg-white shadow-sm',
              ].join(' ')}
              data-cy="estimate"
            >
              {language === 'es' ? 'Fecha Estimada' : 'Estimate'}
            </button>
          </div>
        </div>
      )}
      {exactDate ? (
        <div>
          {monthDialogOpen ? (
            <MonthYearDialog
              selectedDate={selectedDate ?? dayjs()}
              closeMonthDialog={() => setMonthDialogOpen(false)}
              setMonthYear={setMonthYear}
              primaryColor={primaryColor}
            />
          ) : (
            <div className="rounded-lg bg-white p-4 text-lg shadow-xl w-96">
              <div className="flex justify-between pb-6">
                <div>
                  <button
                    onClick={prevYear}
                    type="button"
                    className="h-8 w-8 rounded-full border-2 border-transparent p-1 text-gray-600 transition duration-150 ease-in-out hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
                    aria-label="Previous year"
                  >
                    <ChevronDoubleLeftIcon />
                  </button>
                  <button
                    onClick={prevMonth}
                    type="button"
                    className="h-8 w-8 rounded-full border-2 border-transparent p-1 text-gray-600 transition duration-150 ease-in-out hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
                    aria-label="Previous month"
                  >
                    <ChevronLeftIcon />
                  </button>
                </div>
                <div
                  className="cursor-pointer place-self-center p-1 px-3 rounded-full text-gray-600 transition duration-150 ease-in-out hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
                  onClick={() => setMonthDialogOpen(true)}
                >
                  {viewDate.format('MMMM YYYY')}
                </div>
                <div>
                  <button
                    onClick={nextMonth}
                    type="button"
                    className="h-8 w-8 rounded-full border-2 border-transparent p-1 text-gray-600 transition duration-150 ease-in-out hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
                    aria-label="Next month"
                  >
                    <ChevronRightIcon />
                  </button>
                  <button
                    onClick={nextYear}
                    type="button"
                    className="h-8 w-8 rounded-full border-2 border-transparent p-1 text-gray-600 transition duration-150 ease-in-out hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
                    aria-label="Next year"
                  >
                    <ChevronDoubleRightIcon />
                  </button>
                </div>
              </div>
              <div className="grid grid-cols-7 gap-4">
                <DatepickerDaysOfWeek />
                <DatepickerDays
                  days={viewDate.daysInMonth()}
                  skipDays={skipDays}
                  selectDate={selectDate}
                  selectedDate={selectedDate}
                  viewDate={viewDate}
                  pastOnly={options.pastOnly}
                  futureOnly={options.futureOnly}
                  primaryColor={primaryColor}
                />
              </div>
            </div>
          )}
          <div className="pb-4">
            {selectedDate && (
              <div className="pt-6 text-2xl font-extrabold leading-10 tracking-tight text-gray-900 sm:text-3xl sm:leading-none">
                <div>
                  {language === 'es' ? 'Haz seleccionado' : "You've selected"}
                </div>
                <div
                  className="pt-1"
                  style={{
                    color: primaryColor,
                  }}
                >
                  {' '}
                  {selectedDate.format('MMMM D, YYYY')}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <DatepickerEstimate
          value={estimate}
          onChange={onChangeEstimate}
          primaryColor={primaryColor}
          language={language}
        />
      )}
    </div>
  );
};
