import { HealthcareProvider } from '@chiroup/core';
import { useQuery } from 'react-query';
import HTTPClient from '../HTTPClient';

const apiUrl = import.meta.env.VITE_HEALTHCOM_API;

const useHealthcareProvidersAll = (requestId: string) => {
  const { data, isFetching, refetch } = useQuery<HealthcareProvider[]>(
    'healthcareProviders',
    (key) => HTTPClient.get(`${apiUrl}/healthcare-providers/${requestId}`),
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data,
    isFetching,
    refetch,
  };
};

export default useHealthcareProvidersAll;
