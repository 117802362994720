import {
  ReevalSurveyQuestionIds,
  SurveyOption,
  SurveyQuestionAnswer,
  SurveyQuestionAnswerNonGroup,
  SurveyQuestionTypeOptions,
} from '@chiroup/core';
import React from 'react';
import { PatientCreateCCSPicker } from '../body/PatientCreateCCSPicker';
import { SurveyQuestionCheckbox } from './SurveyQuestionCheckbox';
import { SurveyQuestionDate } from './SurveyQuestionDate';
import { SurveyQuestionGroup } from './SurveyQuestionGroup';
import { SurveyQuestionInput } from './SurveyQuestionInput';
import { SurveyQuestionRadio } from './SurveyQuestionRadio';
import { SurveyQuestionSlider } from './SurveyQuestionSlider';
import { SurveyQuestionTextarea } from './SurveyQuestionTextarea';

export const questionContentVerbiage = {
  en: {
    next: 'Next',
    noneOfTheAbove: 'None of the above',
  },
  es: {
    next: 'Continuar',
    noneOfTheAbove: 'Ninguna de las anteriores',
  },
};

type Props = {
  id: string;
  type: string;
  typeOptions?: SurveyQuestionTypeOptions;
  options?: SurveyOption[];
  onSelect: (
    val: SurveyQuestionAnswer | SurveyQuestionAnswerNonGroup,
  ) => Promise<void>;
  disabled: boolean;
  images?: string[];
  isGroup?: boolean;
  language?: 'en' | 'es';
  primaryColor: string;
  parts?: any;
  source?: any;
};

export const SurveyQuestionContent: React.FC<Props> = ({
  id,
  type,
  typeOptions,
  options,
  onSelect,
  disabled,
  images,
  isGroup = false,
  language = 'en',
  primaryColor,
  parts,
  source,
}) => {
  const surveyQuestion =
    type === 'radio' ? (
      <SurveyQuestionRadio
        id={id}
        options={options}
        onSelect={onSelect}
        disabled={disabled}
        includeOther={typeOptions?.includeOther}
        hideNext={isGroup}
        language={language}
        forceAnswer={typeOptions?.forceAnswer}
        primaryColor={primaryColor}
      />
    ) : type === 'checkbox' ? (
      <SurveyQuestionCheckbox
        id={id}
        options={options}
        onSelect={onSelect}
        disabled={disabled}
        includeOther={typeOptions?.includeOther}
        hideNext={isGroup}
        language={language}
        primaryColor={primaryColor}
      />
    ) : type === 'input' ? (
      <SurveyQuestionInput
        id={id}
        onSelect={onSelect}
        disabled={disabled}
        language={language}
        type={typeOptions?.type || 'text'}
        primaryColor={primaryColor}
      />
    ) : type === 'textarea' ? (
      <SurveyQuestionTextarea
        id={id}
        onSelect={onSelect}
        disabled={disabled}
        language={language}
        primaryColor={primaryColor}
        textOptions={typeOptions}
        forceAnswer={typeOptions?.forceAnswer}
      />
    ) : type === 'date' ? (
      <SurveyQuestionDate
        dateOptions={typeOptions}
        onSelect={onSelect}
        disabled={disabled}
        hideNext={isGroup}
        language={language}
        primaryColor={primaryColor}
      />
    ) : type === 'slider' ? (
      <SurveyQuestionSlider
        sliderOptions={typeOptions}
        onSelect={onSelect}
        hideNext={isGroup}
        language={language}
        primaryColor={primaryColor}
        disabled={disabled}
      />
    ) : // ) : type === 'bodypicker' ? (
    //   <SurveyQuestionSlider
    //     id={id}
    //     sliderOptions={typeOptions}
    //     onSelect={onSelect}
    //     disabled={disabled}
    //     hideNext={isGroup}
    //     language={language}
    //   />
    type === 'group' ? (
      <SurveyQuestionGroup
        onSelect={onSelect}
        disabled={disabled}
        questions={typeOptions?.subQuestions}
        language={language}
        primaryColor={primaryColor}
      />
    ) : (
      <div>Invalid question type.</div>
    );

  return (
    <div className="flex flex-col">
      {/* {surveyQuestionImages} */}
      {parts &&
        id.includes(ReevalSurveyQuestionIds.reevalsymptomsSpreadQuestionId) && (
          <PatientCreateCCSPicker parts={parts} source={source} />
        )}
      <fieldset className="mt-4 text-xl sm:text-2xl font-medium text-gray-500 pb-24 md:pb-8">
        {surveyQuestion}
      </fieldset>
    </div>
  );
};
