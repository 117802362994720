import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import MonthSelect from './MonthSelect';
import YearSelect from './YearSelect';

type Props = {
  selectedDate?: Dayjs;
  closeMonthDialog: () => void;
  setMonthYear: (month: number, year: number) => void;
  primaryColor: string;
};

const MonthYearDialog: React.FC<Props> = ({
  selectedDate,
  closeMonthDialog,
  setMonthYear,
  primaryColor,
}) => {
  const [selectedMonth, setSelectedMonth] = useState<number>(dayjs().month());
  const [selectedYear, setSelectedYear] = useState<number>(dayjs().year());
  const [openYearSelect, setOpenYearSelect] = useState<boolean>(false);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [years, setYears] = useState<number[]>([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearList = [];
    for (let i = currentYear; i >= currentYear - 125; i--) {
      yearList.push(i);
    }
    setYears(yearList);
  }, []);

  const handleYearChange = (val: number) => {
    setSelectedYear(val);
  };

  const handleMonthChange = (val: number) => {
    setSelectedMonth(val);
    setMonthYear(val, selectedYear);
    closeMonthDialog();
  };

  const handleNext = () => {
    openYearSelect
      ? setStartIndex(startIndex + 18)
      : setSelectedYear(() => selectedYear - 1);
  };
  const handlePrev = () => {
    if (openYearSelect) {
      setStartIndex(startIndex - 18);
    } else if (!(selectedYear >= dayjs().year())) {
      setSelectedYear(selectedYear + 1);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      setSelectedMonth(selectedDate.month());
      setSelectedYear(selectedDate.year());
    }
  }, [selectedDate]);

  const chevronHoverClass =
    'text-gray-600 rounded-full hover:text-gray-900 focus:outline-none focus:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 transition duration-150 ease-in-out';
  const getChevronHoverClass = () => {
    if (openYearSelect) {
      return startIndex - 18 >= 0 ? chevronHoverClass : '';
    } else if (!(selectedYear >= dayjs().year())) {
      return chevronHoverClass;
    } else {
      return '';
    }
  };

  const getDisabledChevron = () => {
    if (openYearSelect) {
      return startIndex - 18 < 0;
    } else if (selectedYear >= dayjs().year()) {
      return true;
    }
    return false;
  };

  const shouldShowChevron = () => {
    if (openYearSelect) {
      return !(startIndex - 18 < 0);
    } else if (selectedYear >= dayjs().year()) {
      return false;
    }
    return true;
  };

  return (
    <div className="rounded-lg bg-white p-4 text-lg shadow-xl h-96 w-96">
      <div className="flex justify-between pb-6">
        <button
          onClick={handleNext}
          type="button"
          className="h-8 w-8 rounded-full border-2 border-transparent p-1 text-gray-600 transition duration-150 ease-in-out hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
          disabled={startIndex + 18 >= years.length}
        >
          <ChevronLeftIcon />
        </button>
        <span
          onClick={() => setOpenYearSelect(!openYearSelect)}
          className={
            'flex justify-center items-center cursor-pointer rounded-full mb-4 h-8 w-16 text-gray-600 transition duration-150 ease-in-out hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none'
          }
        >
          {selectedYear}
        </span>
        <button
          type="button"
          onClick={handlePrev}
          disabled={getDisabledChevron()}
          className={`flex h-8 w-8 p-1 border-2 border-transparent ${getChevronHoverClass()}`}
        >
          {shouldShowChevron() && <ChevronRightIcon />}
        </button>
      </div>

      {!openYearSelect ? (
        <MonthSelect
          selectedMonth={selectedMonth}
          onChange={handleMonthChange}
          primaryColor={primaryColor}
        />
      ) : (
        <YearSelect
          selectedYear={selectedYear}
          onChange={handleYearChange}
          close={() => setOpenYearSelect(false)}
          years={years}
          startIndex={startIndex}
          primaryColor={primaryColor}
        />
      )}
    </div>
  );
};
export default MonthYearDialog;
