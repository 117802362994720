import { RequestStep } from '@chiroup/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { MeContext } from '../../contexts/me.context';
import Step from '../common/stepper/Step';

const verbiage = {
  en: {
    introTitle: 'We have a few questions',
    introSubtitle: "We'll try to make this quick...",
    continue: 'Continue',
    getStarted: 'Get started',
    exit: 'Exit',
  },
  es: {
    introTitle: 'Tenemos algunas preguntas.',
    introSubtitle: 'Intentaremos hacer esto rápido...',
    continue: 'Seguir',
    getStarted: 'Empezar',
    exit: 'Salida',
  },
};

type Props = {
  handleReset: (changeScreen?: boolean) => void;
  id?: string;
  kiosk?: boolean;
  language: 'en' | 'es';
  nextStep: (id?: string) => void;
  steps: RequestStep[];
  validationCode: string | number;
};

const RequestStepper: React.FC<Props> = ({
  steps,
  nextStep,
  language = 'en',
  id,
  kiosk,
  handleReset,
}) => {
  const {
    clinicInfo: { primaryColor },
  } = useContext(MeContext);
  const [scroll, setScroll] = useState(
    document.documentElement.scrollHeight ===
      document.documentElement.clientHeight
      ? 100
      : 0,
  );

  const hasNotStarted = useMemo(() => {
    return steps.every((step) => !step.complete);
  }, [steps]);

  const onScroll = () => {
    const scrolled = document.documentElement.scrollTop;
    const maxHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrollPercent = (scrolled / maxHeight) * 100;
    setScroll(maxHeight ? scrollPercent : 100);
  };

  const shouldExit = () => {
    return (
      steps.length ===
      steps.filter(({ complete }) => complete).length +
        steps.filter(({ disabled }) => disabled).length
    );
  };

  useEffect(() => {
    onScroll();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    window.addEventListener('click', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('click', onScroll);
    };
  }, []);

  const realNextStep = useMemo(() => {
    const nextOneNotComplete = steps?.findIndex(
      (step) => !step.complete && !step.disabled,
    );
    return nextOneNotComplete !== -1 ? nextOneNotComplete + 1 : 0;
  }, [steps]);

  return (
    <div id="stepper-container">
      <h2 className="text-4xl tracking-tight leading-8 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
        {verbiage[language === 'es' ? 'es' : 'en'].introTitle}
      </h2>
      <h3 className="text-xl tracking-tight leading-6 font-extrabold text-gray-900 sm:text-2xl sm:leading-none md:text-4xl mt-2">
        <span
          style={{
            color: primaryColor,
          }}
        >
          {verbiage[language === 'es' ? 'es' : 'en'].introSubtitle}
        </span>
      </h3>
      {!!id && (
        <nav className="mt-8">
          <ul className="overflow-hidden">
            {steps.map((step, i: number) => (
              <Step
                key={i}
                title={
                  language === 'es' && step?.esTitle ? step.esTitle : step.title
                }
                description={
                  language === 'es' && step?.esDescription
                    ? step.esDescription
                    : step.description
                }
                complete={step.complete}
                upcoming={realNextStep === i + 1}
                finalStep={steps.length === i + 1}
                stepNumber={i + 1}
                requestId={id}
                kiosk={kiosk}
                disabled={step.disabled}
                language={language}
              />
            ))}
          </ul>
        </nav>
      )}
      {scroll < 100 && (
        <div
          className="animate-bounce bg-white p-2 w-10 h-10 lg:w-16 lg:h-16 shadow-lg rounded-full flex items-center justify-center fixed bottom-4 right-4 cursor-pointer"
          onClick={() => {
            window.scrollBy(0, 800);
          }}
        >
          <svg
            className="w-6 h-6 lg:h-10 lg:w-10"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            style={{
              color: primaryColor,
            }}
          >
            <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
          </svg>
        </div>
      )}
      <button
        className="cursor-pointer rounded-md shadow px-5 py-3 border border-transparent text-base font-medium text-white hover:opacity-90 focus:outline-none focus:ring transition duration-150 ease-in-out"
        style={{ width: 'fit-content', backgroundColor: primaryColor }}
        onClick={() => {
          shouldExit() ? handleReset(true) : nextStep();
        }}
        type="button"
        data-cy="get-started"
      >
        {shouldExit()
          ? verbiage[language === 'es' ? 'es' : 'en'].exit
          : hasNotStarted
            ? verbiage[language === 'es' ? 'es' : 'en'].getStarted
            : verbiage[language === 'es' ? 'es' : 'en'].continue}
      </button>
    </div>
  );
};

export default RequestStepper;
