import { ChiroUpAPI } from '@chiroup/client-core';
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

const getSubscriptionStatus = (token: string) => {
  return async () => {
    return ChiroUpAPI.get('api-noauth', '/subscription-status', {
      queryParams: { token: encodeURIComponent(token) },
    });
  };
};

const useEmailSubscription = (token: string) => {
  const queryClient = useQueryClient();
  const [submitting, setSubmitting] = useState(false);
  const { data, isFetching } = useQuery(
    'emailSubscription',
    getSubscriptionStatus(token),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  const subscribe = async () => {
    setSubmitting(true);
    try {
      await ChiroUpAPI.put('api-noauth', '/subscription-status', {
        queryParams: { token: encodeURIComponent(token) },
        body: { action: 'subscribe' },
      });
      queryClient.setQueryData('emailSubscription', {
        subscribed: true,
      });
      setSubmitting(false);
    } catch (err) {
      console.error(err);
      setSubmitting(false);
    }
  };

  const unsubscribe = async () => {
    setSubmitting(true);
    try {
      await ChiroUpAPI.put('api-noauth', '/subscription-status', {
        queryParams: { token: encodeURIComponent(token) },
        body: { action: 'unsubscribe' },
      });
      queryClient.setQueryData('emailSubscription', {
        subscribed: false,
      });
      setSubmitting(false);
    } catch (err) {
      console.error(err);
      setSubmitting(false);
    }
  };

  return {
    subscribed: !!data?.subscribed,
    isFetching,
    subscribe,
    unsubscribe,
    submitting,
  };
};

export default useEmailSubscription;
