import { SurveyQuestionTitle, SurveyQuestionRadio } from '@chiroup/components';
import { CommunicationMethodTypes } from '@chiroup/core';
import React, { useContext, useState } from 'react';
import { MeContext } from '../../contexts/me.context';
import Button, { ButtonColors } from '../common/Button';

const verbiage: any = {
  en: {
    selectVerificiation: 'Select verification method',
    selectDestination: 'Select destination',
    emailRequest: 'Email request to',
    emailCode: 'Email code to',
    textRequest: 'Text request to',
    textCode: 'Text code to',
    submit: 'Submit',
  },
  es: {
    selectVerificiation: 'Seleccione un método de verificación',
    selectDestination: 'Seleccionar destino',
    emailRequest: 'Solicitud de correo electrónico a',
    emailCode: 'Correo electonico a',
    textRequest: 'Solicitud de texto a',
    textCode: 'Mensaje de texto a',
    submit: 'Enviar',
  },
};

type Props = {
  methods: { email: string; phone: string; language: string };
  loading: boolean;
  onSubmitMethod: (method: string) => void;
  kiosk: boolean;
  invalidCode?: boolean;
};

const RequestMethodSelect: React.FC<Props> = ({
  methods,
  loading,
  onSubmitMethod,
  kiosk,
  invalidCode = false,
}) => {
  const {
    clinicInfo: { primaryColor },
  } = useContext(MeContext);
  const [val, setVal] = useState<string>('');

  const onSelect = (val: any) => {
    setVal(val.value);
    return val;
  };

  const submitMethod = () => {
    onSubmitMethod(val);
  };

  const { email, phone, language } = methods;

  const requestOptions = [
    {
      display: `${
        verbiage[language === 'es' ? 'es' : 'en'].emailCode
      } ${email}`,
      id: CommunicationMethodTypes.email,
    },
    {
      display: `${verbiage[language === 'es' ? 'es' : 'en'].textCode} ${phone}`,
      id: CommunicationMethodTypes.text,
    },
  ];

  const kioskOptions = [
    {
      display: `${
        verbiage[language === 'es' ? 'es' : 'en'].emailRequest
      } ${email}`,
      id: CommunicationMethodTypes.email,
    },
    {
      display: `${
        verbiage[language === 'es' ? 'es' : 'en'].textRequest
      } ${phone}`,
      id: CommunicationMethodTypes.text,
    },
  ];

  return (
    <div className="flex min-h-screen mx-auto justify-center flex-col p-4">
      <div className="flex items-start mx-auto justify-center w-full max-w-xl flex-col p-4">
        <div className="pb-4">
          <SurveyQuestionTitle
            title={
              kiosk
                ? verbiage[language === 'es' ? 'es' : 'en'].selectDestination
                : verbiage[language === 'es' ? 'es' : 'en'].selectVerificiation
            }
          />
          {invalidCode && (
            <p className="text-red-500 text-center text-sm italics">
              {language === 'es'
                ? 'Código inválido. Por favor, intente de nuevo.'
                : 'Invalid code. Please try again.'}
            </p>
          )}
        </div>
        <SurveyQuestionRadio
          id="method"
          options={kiosk ? kioskOptions : requestOptions}
          onSelect={onSelect}
          disabled={loading}
          includeOther={false}
          hideNext={true}
          forceAnswer={true}
          primaryColor={primaryColor}
        />
      </div>
      <div className="flex items-center justify-center ">
        <Button
          text={verbiage[language === 'es' ? 'es' : 'en'].submit}
          color={ButtonColors.primary}
          className="mt-4"
          type="button"
          disabled={!val || loading}
          onClick={submitMethod}
          loading={loading}
          data-cy="submit-button"
        />
      </div>
    </div>
  );
};

export default RequestMethodSelect;
