import {
  confirmSignIn,
  getCurrentUser,
  rememberDevice,
  signIn,
  signOut,
} from '@aws-amplify/auth';
import { StopListenerCallback } from '@aws-amplify/core/dist/esm/Hub/types';
import { Loading, LoadingPage } from '@chiroup/components';
import { Hub } from 'aws-amplify/utils';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useLocalStorage from '../../hooks/useLocalStorage';
import kioskService from '../../services/kiosk.service';
import KioskLoggedIn from './KioskLoggedIn';
import KioskLoggedInLegacy from './KioskLoggedInLegacy';
import { detect } from 'detect-browser';

const KioskSelect = () => {
  const [authState, setAuthState] = useState<string>();
  const [user, setUser] = useState<any>();
  const { setItem, getItem } = useLocalStorage();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [pairCode, setPairCode] = useState<string>('');
  const [needsPairing, setNeedsPairing] = useState<boolean>(true);
  const [fetchingClinic, setFetchingClinic] = useState<boolean>(true);
  const [clinicInfo, setClinicInfo] = useState<{
    name: string;
    logo: string;
    primaryColor: string;
    accentColor: string;
    flags: string[];
  }>();

  useEffect(() => {
    let isDestroyed = false;
    const doIt = async () => {
      try {
        const clinic = await kioskService.kioskMe();
        if (!isDestroyed) {
          setClinicInfo(clinic);
        }
      } catch (err) {
        console.error({ err });
      } finally {
        setFetchingClinic(false);
      }
    };
    if (!needsPairing) {
      doIt();
    }
    return () => {
      isDestroyed = true;
    };
  }, [needsPairing]);

  const hasAccess = (flagToCheck: string) => {
    return clinicInfo?.flags?.includes(flagToCheck);
  };

  useEffect(() => {
    let isDestroyed = false;
    const doIt = async () => {
      try {
        const user = await getCurrentUser();
        if (user && !isDestroyed) {
          setUser(user);
          setAuthState('authenticated');
          setNeedsPairing(false);
        }
      } catch (err) {
        console.error({ err });
        if (!isDestroyed) {
          setUser(undefined);
          setAuthState(undefined);
          setNeedsPairing(true);
          setFetchingClinic(false);
        }
      }
    };
    doIt();
    return () => {
      isDestroyed = true;
    };
  }, []);

  useEffect(() => {
    const kioskId = getItem('kiosk-id', false);
    if (!kioskId) {
      setItem('kiosk-id', uuidv4());
    }
  }, [getItem, setItem]);

  useEffect(() => {
    let isDestroyed = false;
    let stopListener: StopListenerCallback;
    const doIt = async () => {
      const currentUser = await getCurrentUser().catch((err) => {
        console.error({ err });
      });
      if (currentUser && !isDestroyed) {
        setAuthState('authenticated');
        setUser(currentUser);
      }
      stopListener = Hub.listen('auth', (data) => {
        if (!isDestroyed) {
          if (data.payload.event === 'tokenRefresh_failure') {
            setAuthState('signedOut');
            // setInactivityModalOpen(true);
          } else if (data.payload.event === 'signedOut') {
            setAuthState('signedOut');
          } else if (data.payload.event === 'signedIn') {
            setAuthState('authenticated');
            setUser(data.payload.data);
          }
        }
      });
    };
    doIt();
    return () => {
      isDestroyed = true;
      stopListener?.();
    };
  }, []);

  const waitForPairing = async () => {
    return new Promise((resolve, reject) => {
      let attempts = 0;
      const interval = setInterval(async () => {
        attempts++;
        try {
          const clinic = await kioskService.kioskMe();
          setClinicInfo(clinic);
          clearInterval(interval);
          resolve(true);
        } catch (err) {
          if (attempts > 180) {
            clearInterval(interval);
            reject(err);
          }
        }
      }, 5000);
    });
  };

  const pairDevice = async () => {
    setSubmitting(true);
    try {
      // Adding pass makes an error

      const user = (await signIn({
        username: getItem('kiosk-id', false),
        options: {
          authFlowType: 'CUSTOM_WITHOUT_SRP',
        },
      })) as any;

      const pairCodeFromLogin = user?.nextStep?.additionalInfo?.pairCode;
      const signInStep = user?.nextStep?.signInStep;

      if (!pairCodeFromLogin) {
        console.error('Failed to create pair code.');
      }

      setPairCode(pairCodeFromLogin);

      if (signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
        // to send the answer of the custom challenge
        const browser = detect();
        const resAnswer = await confirmSignIn({
          challengeResponse: pairCodeFromLogin,
          options: {
            clientMetadata: {
              operatingSystem: browser?.os ? String(browser.os) : '',
              browser: browser?.name ? String(browser.name) : '',
            },
          },
        });
        await rememberDevice();
        await waitForPairing();
        setSubmitting(false);
        setUser(resAnswer);
        setAuthState('authenticated');
        setNeedsPairing(false);
      }

      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      setPairCode('');
      await signOut();
      console.error({ err });
    }
  };

  return (
    <div>
      {fetchingClinic ? (
        <LoadingPage />
      ) : authState === 'authenticated' &&
        user &&
        !needsPairing &&
        clinicInfo !== undefined ? (
        hasAccess('kioskEhrNotes') ? (
          <KioskLoggedIn clinicInfo={clinicInfo} />
        ) : (
          <KioskLoggedInLegacy clinicInfo={clinicInfo} />
        )
      ) : pairCode ? (
        <div className="pt-16 pb-12 flex flex-col items-center min-h-screen max-w-lg mx-auto">
          <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="py-16">
              <div className="text-center">
                <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">
                  HealthCom
                </p>
                <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  Kiosk pairing
                </h1>
                <p className="mt-2 text-base text-gray-500">
                  Enter the pair code below into ChiroUp (Clinic settings &gt;
                  Kiosks &gt; Pair device). This screen will refresh after the
                  device has been successfully paired with your account.
                </p>
                <div className="mt-6">
                  <span className="font-medium text-indigo-600 hover:text-indigo-500 text-4xl">
                    {pairCode}
                  </span>
                </div>
              </div>
            </div>
          </main>
        </div>
      ) : (
        <div className="pt-16 pb-12 flex flex-col items-center min-h-screen max-w-lg mx-auto">
          <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="py-16">
              <div className="text-center">
                <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">
                  HealthCom
                </p>
                <h1
                  className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl"
                  data-cy="pairing"
                >
                  Kiosk pairing
                </h1>
                <p className="mt-2 text-base text-gray-500">
                  Click "Pair device" below to begin the kiosk pairing process.
                  A pair code will be generated that you can enter in ChiroUp
                  (Clinic settings &gt; Kiosks &gt; Pair device).
                </p>
                <div className="mt-6 flex flex-col gap-6 text-center items-center justify-center">
                  {submitting ? (
                    <Loading color="text-indigo-600" />
                  ) : (
                    <button
                      className="text-base font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                      onClick={pairDevice}
                      disabled={submitting}
                    >
                      Pair device<span aria-hidden="true"> &rarr;</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </main>
        </div>
      )}
    </div>
  );
};

export default KioskSelect;
