import {
  abbreviatedCCSQuestionsIds,
  BodyParts,
  CCSBodyPickerQuestionIds,
  CCSPrimaryQuestionIds,
  createName,
  ReevalSurveyQuestionIds,
  SurveyQuestion,
  SurveyQuestionIfType,
} from '@chiroup/core';

export const enum RadioScaleOptions {
  SignificantlyBetter = 'Significantly Better',
  Better = 'Better',
  Same = 'About the same',
  Worse = 'Worse',
  SignificantlyWorse = 'Significantly Worse',
  NotApplicable = 'Not Applicable',
}
const spanishRegions: {
  [key: string]: { translation: string; gender: string };
} = {
  front: { translation: 'frente', gender: 'm' },
  rear: { translation: 'parte trasera', gender: 'f' },
  left: { translation: 'izquierdo', gender: 'm' },
  right: { translation: 'derecho', gender: 'm' },
  hand: { translation: 'mano', gender: 'f' },
  foot: { translation: 'pie', gender: 'm' },
  ankle: { translation: 'tobillo', gender: 'm' },
  leg: { translation: 'pierna', gender: 'f' },
  knee: { translation: 'rodilla', gender: 'f' },
  thigh: { translation: 'muslo', gender: 'm' },
  hip: { translation: 'cadera', gender: 'f' },
  groin: { translation: 'ingle', gender: 'f' },
  abdomen: { translation: 'abdomen', gender: 'm' },
  rib: { translation: 'costilla', gender: 'f' },
  wrist: { translation: 'muñeca', gender: 'f' },
  forearm: { translation: 'antebrazo', gender: 'm' },
  elbow: { translation: 'codo', gender: 'm' },
  arm: { translation: 'brazo', gender: 'm' },
  chest: { translation: 'pecho', gender: 'm' },
  shoulder: { translation: 'hombro', gender: 'm' },
  head: { translation: 'cabeza', gender: 'f' },
  neck: { translation: 'cuello', gender: 'm' },
  lower: { translation: 'inferior', gender: 'm' },
  back: { translation: 'espalda', gender: 'f' },
  upper: { translation: 'superior', gender: 'm' },
};
const createSpanishRegions = (region: string) => {
  const regionList = region.split(' ');
  const regions = regionList.map((word) => {
    if (word === 'and') {
      return ' y ';
    }
    if (spanishRegions[word]) {
      const { translation, gender } = spanishRegions[word];
      const article = gender === 'f' ? 'la' : 'el';
      if (word === 'upper' || word === 'lower') {
        return `la parte ${translation} de`;
      } else {
        return `${article} ${translation}`;
      }
    }
    return '';
  });
  return regions.join(' ');
};

const spanishPain: { [key: string]: string } = {
  'Pain / Discomfort': 'dolor / malestar',
  'Stiffness / Tightness': 'rigidez / estanqueidad',
  'Altered sensation': 'sensación alterada',
  Weakness: 'debilidad',
  'Noise / Grinding': 'ruido / rechinamiento',
};

const createOptions = (
  options: { en: string; es: string }[],
  language = 'en',
  optionOptions?: { askForInput: boolean; inputPlaceholder: string },
) => {
  return options.map((opt) => {
    const option: any = {
      display: language === 'es' ? opt.es : opt.en,
      id: opt.en,
    };
    if (optionOptions?.askForInput) {
      option.data = {
        askForInput: true,
        inputPlaceholder: optionOptions.inputPlaceholder,
      };
    }
    return option;
  });
};

const data = {
  yesNo: [
    { en: 'Yes', es: 'Sí' },
    { en: 'No', es: 'No' },
  ],
  sinceLastVisit: [
    { en: 'Significantly Better', es: 'Significativamente Mejor' },
    { en: 'Better', es: 'Mejor' },
    { en: 'Same', es: 'Igual' },
    { en: 'Worse', es: 'Peor' },
    { en: 'Significantly Worse', es: 'Significativamente Peor' },
  ],
  yesNoUnknown: [
    { en: 'Yes', es: 'sí' },
    { en: 'No', es: 'No' },
    { en: 'Unknown', es: 'No Recuerdo' },
  ],
  spread: [
    { en: 'Expanded', es: 'Expandido' },
    { en: 'Shrunk', es: 'Encogido' },
    { en: 'About the same', es: 'Sobre el mismo' },
  ],
  bilateral: [
    {
      en: 'Relatively equal on each side',
      es: 'Relativamente igual en cada lado',
    },
    { en: 'Slightly worse on the right', es: 'Ligeramente peor a la derecha' },
    {
      en: 'Significantly worse on the right',
      es: 'Bastante peor a la derecha',
    },
    { en: 'Slightly worse on the left', es: 'Ligeramente peor a la izquierda' },
    {
      en: 'Significantly worse on the left',
      es: 'Bastante peor a la izquierda',
    },
  ],
  describe: [
    { en: 'Pain / Discomfort', es: 'Dolor / Molestia' },
    { en: 'Stiffness / Tightness', es: 'Rigidez / Tension' },
    { en: 'Altered sensation', es: 'Sensación alterada' },
    { en: 'Weakness', es: 'Debilidad' },
    { en: 'Noise / Grinding', es: 'Ruido / Chirrido' },
  ],
  bestDescribes: {
    painDiscomfort: [
      {
        en: 'Consistently dull and occasionally sharp',
        es: 'Dolor constante y a veces agudo',
      },
      { en: 'Sore', es: 'Doloroso' },
      { en: 'Dull / aching', es: 'Dolor Constante' },
      { en: 'Sharp / stabbing', es: 'Agudo' },
      { en: 'Shooting', es: 'Punzante' },
      { en: 'Pinching / pricking', es: 'Pellizcante / Puntiagudo' },
      { en: 'Burning', es: 'Ardor' },
      { en: 'Throbbing', es: 'Palpitante' },
      { en: 'Pins & needles', es: 'Alfileres y agujas' },
      { en: 'Pressing / squeezing', es: 'Presión / Apretón' },
      { en: 'Torsion', es: 'Retorcimiento' },
      { en: 'Cutting / lacerating', es: 'Cortante / Lacerante' },
    ],
    alteredSensation: [
      { en: 'Diminished sensitivity', es: 'Sensibilidad Disminuida' },
      { en: 'Numbness', es: 'Entumecimiento' },
      { en: 'Tingling', es: 'Hormigueo' },
      { en: 'Itchy', es: 'Picazón' },
      { en: 'Pins & needles', es: 'Alfileres y agujas' },
    ],
    weakness: [
      { en: 'Fatigue', es: 'Fatiga' },
      { en: 'Pain-induced weakness', es: 'Debilidad inducida por dolor' },
      {
        en: 'Strength loss independent of pain',
        es: 'Pérdida de fuerza independiente del dolor',
      },
    ],
  },
  symptomsBegin: [
    { en: 'Abruptly', es: 'Abruptamente' },
    { en: 'Gradual over time', es: 'Gradual en el tiempo' },
  ],
  caused: [
    { en: 'Repetitive activity', es: 'Actividad repetitiva' },
    { en: 'Single activity', es: 'Actividad única' },
    { en: 'Auto accident', es: 'Accidente automovilístico' },
    { en: 'Employment-related injury', es: 'Lesión relacionada con el empleo' },
    { en: 'No identifiable cause', es: 'Sin causa identificable' },
  ],
  onset: [
    { en: 'Worsening', es: 'Empeorando' },
    { en: 'Improving', es: 'Mejorando' },
    {
      en: 'Not changing significantly',
      es: 'No han cambiado significativamente',
    },
  ],
  reevalSymptoms: [
    { en: 'Dramatically improved', es: 'Dramáticamente mejorado' },
    { en: 'Improved', es: 'Mejorado' },
    { en: 'About the same', es: 'Sobre el mismo' },
    { en: 'Worse', es: 'Peor' },
  ],
  aggravates: {
    footAnkle: [
      { en: 'Touch your foot or ankle', es: 'Toca tu pie o tobillo' },
      {
        en: 'Flex or extend your ankle',
        es: 'Flexiona o extiende tu tobillo',
      },
      { en: 'Stand or walk', es: 'Ponte de Pie o camina' },
      {
        en: 'First steps after resting',
        es: 'Primeros pasos después de descansar',
      },
      { en: 'Run', es: 'Corre' },
      { en: 'Pivot or twist', es: 'Pivote o gira' },
      { en: 'Stand tip-toe', es: 'Párate de puntillas' },
      { en: 'Walk stairs or hills', es: 'Caminar escaleras o colinas' },
    ],
    kneeLeg: [
      { en: 'Touch your leg', es: 'Contacto con la pierna' },
      {
        en: 'Flex or extend your knee',
        es: 'Flexionar o extender la rodilla',
      },
      { en: 'Kneel', es: 'Arrodillarme' },
      {
        en: 'Arise after sitting',
        es: 'Levantarme despues de estar sentado',
      },
      { en: 'Stand or walk', es: 'Pararme o caminar' },
      { en: 'Run', es: 'Correr' },
      { en: 'Pivot or twist', es: 'Girar' },
      { en: 'Walk stairs or hills', es: 'Subir escaleras o colinas' },
      { en: 'Deep squat', es: 'Sentarme' },
      { en: 'Jump', es: 'Saltar' },
    ],
    hip: [
      { en: 'Touch your hip', es: 'Toca tu cadera' },
      { en: 'Lay on your hip', es: 'Acuéstate de lado' },
      { en: 'Bring your knee to your chest', es: 'Lleva tu rodilla al pecho' },
      { en: 'Long strides when walking', es: 'Zancadas largas al caminar' },
      {
        en: 'Cross your legs, i.e., put socks on',
        es: 'Cruza las piernas, es decir, ponerse los calcetines',
      },
      { en: 'Spread your legs', es: 'Abre las piernas' },
      {
        en: 'Get in and out of a vehicle',
        es: 'Entrar y salir de un vehículo',
      },
      { en: 'Arise after sitting', es: 'Levántate después de sentarte' },
      { en: 'Sitting or car rides', es: 'Sentarse o paseos en carro' },
      { en: 'Stand or walk', es: 'Pararse o Caminar' },
      { en: 'Run', es: 'Correr' },
      { en: 'Walk stairs or hills', es: 'Caminar escaleras o colinas' },
      { en: 'Kick', es: 'Patear' },
      { en: 'Squeeze your thighs together', es: 'Aprieta los muslos juntos' },
    ],
    back: [
      { en: 'Bend or lean forward', es: 'Inclínate hacia adelante' },
      { en: 'Bend or lean backward', es: 'Inclínate hacia atrás' },
      {
        en: 'Twist or bend, i.e. roll in bed',
        es: 'Girar o doblarse, i.e. dar la viuelta en la cama',
      },
      { en: 'Sitting or car rides', es: 'Sentarse o paseos en carro' },
      { en: 'Stand or walk', es: 'Párate o camina' },
      { en: 'Cough or sneeze', es: 'Toser o estornudar' },
      {
        en: 'Get in and out of a vehicle',
        es: 'Entrar y salir de un vehículo',
      },
      { en: 'Arise after sitting', es: 'Levántate después de sentarte' },
    ],
    backRib: [
      { en: 'Bend or lean forward', es: 'Inclínate hacia adelante' },
      {
        en: 'Extend or lean backward',
        es: 'Extiende hacia atrás o inclínate hacia atrás',
      },
      {
        en: 'Twist or bend, i.e. roll in bed',
        es: 'Girar o doblar hacia un lado',
      },
      { en: 'Move your head & neck', es: 'Mover la cabeza y el cuello' },
      { en: 'Sitting or car rides', es: 'Sentado o paseos en carro' },
      { en: 'Stand or walk', es: 'Párate o camina' },
      { en: 'Breathe', es: 'Respira' },
      { en: 'Cough or sneeze', es: 'Toser o estornudar' },
      { en: 'Push or pull', es: 'Empujar o tirar' },
    ],
    neck: [
      { en: 'Move your head & neck', es: 'Mover la cabeza y el cuello' },
      { en: 'Look down', es: 'Mirar hacia abajo' },
      { en: 'Look up', es: 'Mirar hacia arriba' },
      { en: 'Turn your head', es: 'Girar tu cabeza' },
      { en: 'Use your arms', es: 'Usar tus brazos' },
      { en: 'Cough or sneeze', es: 'Toser o estornudar' },
      {
        en: 'Workstation use (prolonged look down)',
        es: 'Uso de la estación de trabajo (mirada prolongada hacia abajo)',
      },
      {
        en: 'Overhead work',
        es: 'Actividades que requieren movimientos por encima de tu cabeza',
      },
      { en: 'Drive', es: 'Conducir' },
      { en: 'Sleep', es: 'Dormir' },
    ],
    elbowForearm: [
      { en: 'Touch your elbow', es: 'Toca tu codo' },
      { en: 'Flex or bend your elbow', es: 'Flexiona o dobla el codo' },
      {
        en: 'Fully straighten your elbow',
        es: 'Endereza completamente el codo',
      },
      {
        en: 'Grip, i.e. turn doorknob or shake hands',
        es: 'Agarra, i.e. agarrar la manilla de la puerta o un apretón de manos',
      },
      { en: 'Lift weight', es: 'Levantar pesas' },
    ],
    arm: [
      { en: 'Touch your shoulder', es: 'Toca tu hombro' },
      { en: 'Reach overhead ', es: 'Alcance por encima de tu cabeza' },
      { en: 'Reach behind your back', es: 'Alcance detrás de tu espalda' },
      {
        en: 'Reach across your body',
        es: 'Alcance diagonalmente a través del cuerpo',
      },
      { en: 'Move your head & neck', es: 'Mueve tu cabeza & cuello' },
      {
        en: 'Workstation use (prolonged look down)',
        es: 'Uso de la estación de trabajo (mirada prolongada hacia abajo)',
      },
      { en: 'Lay on your shoulder', es: 'Acuéstate sobre tu hombro' },
    ],
    hand: [
      { en: 'Touch your hand or wrist', es: 'Toca tu mano o muñeca' },
      {
        en: 'Flex or extend your wrist or hand',
        es: 'Flexiona o extiende la muñeca o la mano',
      },
      { en: 'Pinch with your fingers', es: 'Pellizca con tus dedos' },
      {
        en: 'Grip, i.e. turn doorknob or shake hands',
        es: 'Agarrar- abrir una puerta o estrechar la mano',
      },
    ],
    head: [
      { en: 'Touch your head or face', es: 'Toca tu cabeza o cara' },
      {
        en: 'Workstation use (prolonged look down)',
        es: 'Uso de la estación de trabajo (mirada prolongada hacia abajo)',
      },
      { en: 'Move your head & neck', es: 'Mover la cabeza y el cuello' },
      { en: 'Bend or lean forward', es: 'Inclínate hacia adelante' },
      { en: 'Stress', es: 'Estrés' },
      { en: 'Sleep', es: 'Dormir' },
      { en: 'Cough or sneeze', es: 'toser o estornudar' },
      { en: 'Chew food', es: 'Masticar' },
      { en: 'Light or sound', es: 'Luz o sonido' },
    ],
    fullSpine: [
      { en: 'Move your head & neck', es: 'Mover la cabeza y el cuello' },
      {
        en: 'Workstation use (prolonged look down)',
        es: 'Uso de la estación de trabajo (mirada prolongada hacia abajo)',
      },
      {
        en: 'Overhead work',
        es: 'Actividades que requieren movimientos por encima de tu cabeza',
      },
      { en: 'Breathe', es: 'Respira' },
      { en: 'Cough or sneeze', es: 'toser o estornudar' },
      { en: 'Sitting or car rides', es: 'Sentado o paseos en carro' },
      {
        en: 'Get in and out of a vehicle',
        es: 'Entrar y salir de un vehículo',
      },
      { en: 'Twist or bend', es: 'Girar o doblar' },
      { en: 'Stand or walk', es: 'Pararse o caminar' },
      { en: 'Arise after sitting', es: 'Levántate después de sentarte' },
      { en: 'Sleep', es: 'Dormir' },
    ],
    all: [{ en: 'Heavy activity', es: 'Actividad pesada' }],
  },

  sleepPositions: [
    { en: 'Side', es: 'Lateral' },
    { en: 'Stomach', es: 'Estómago' },
    { en: 'Back', es: 'Atrás' },
  ],
  mattressTypes: [
    { en: 'Coil spring', es: 'Muelle helicoidal' },
    { en: 'Foam', es: 'Espuma' },
    { en: 'Air', es: 'Aire' },
    { en: 'Water', es: 'Agua' },
  ],
  otherVehicleSpeedEstimate: [
    { en: 'Stationary', es: 'Estacionado' },
    { en: 'Moving', es: 'En movimiento' },
    { en: 'Moving quickly', es: 'Moviéndose rápido' },
  ],
  pillowTypes: [
    { en: 'Foam', es: 'Espuma' },
    { en: 'Memory foam', es: 'Espuma de memoria' },
    { en: 'Fiberfill', es: 'Relleno de fibra' },
    { en: 'Feather', es: 'Pluma' },
    { en: 'None', es: 'Ninguno' },
  ],
  relief: [
    { en: 'Activity', es: 'Actividad o movimiento' },
    { en: 'Rest', es: 'Descansar' },
    { en: 'Sitting', es: 'Sentarme' },
    { en: 'Standing', es: 'Pararme' },
    { en: 'Hot', es: 'Aplicar calor' },
    { en: 'Cold', es: 'Aplicar frio' },
    { en: 'Stretches', es: 'Estirarme' },
    { en: 'Massage/rubbing', es: 'Masaje' },
    { en: 'Creams', es: 'Cremas' },
    {
      en: 'Over the counter pain meds',
      es: 'Medicamentos para el dolor sin prescripción',
    },
    { en: 'Prescription meds', es: 'Medicamentos con prescripción' },
  ],
  providers: [
    { en: 'Primary medical doctor', es: 'Médico de atención primaria' },
    { en: 'Chiropractor', es: 'Quiropráctico' },
    { en: 'Physical therapist', es: 'Fisioterapeuta' },
    { en: 'Massage therapist', es: 'Masajista' },
    { en: 'Medical specialist', es: 'Especialista Médico' },
    { en: 'Emergency department', es: 'Departamento de emergencia' },
  ],
  pregnant: [
    { en: 'No', es: 'No' },
    {
      en: 'Yes (confirmed due date)',
      es: 'Sí (fecha de parto confirmada)',
    },
    {
      en: 'Possibly (unconfirmed/suspected)',
      es: 'Posiblemente (no confirmado / sospechado)',
    },
    { en: 'Not applicable', es: 'No aplica' },
  ],
  yellowFlagsOptions: [
    {
      en: `It's terrible and I think it's never going to get any better.`,
      es: 'Es terrible y creo que nunca mejorará.',
    },
    {
      en: 'I feel down-hearted, blue, and sad.',
      es: 'Me siento abatido, y triste.',
    },
    {
      en: `In the past two weeks, I've experienced poor appetite or overeating.`,
      es: 'En las últimas dos semanas, he tenido poco apetito o he comido en exceso.',
    },
    { en: 'I am a hotheaded person.', es: 'Soy una persona exaltada.' },
    {
      en: 'Worrying thoughts go through my mind.',
      es: 'Pensamientos preocupantes pasan por mi mente.',
    },
    {
      en: 'Physical activity might harm my body.',
      es: 'La actividad física puede dañar mi cuerpo.',
    },
    {
      en: `I wouldn't have this much pain if there weren't something potentially dangerous going on in my body.`,
      es: 'No tendría tanto dolor si no hubiera algo potencialmente peligroso sucediendo en mi cuerpo.',
    },
    {
      en: `I can't seem to keep thoughts and feelings about my pain out of my mind.`,
      es: 'Parece que no puedo mantener los pensamientos y sentimientos sobre mi dolor fuera de mi mente.',
    },
    {
      en: 'I cannot do physical activities which might make my pain worse.',
      es: 'No puedo realizar actividades físicas que podrían empeorar mi dolor.',
    },
    {
      en: 'My work is too heavy for me.',
      es: 'Mi trabajo es demasiado pesado para mí.',
    },
    {
      en: 'I may not be able to perform my therapy because of how I feel emotionally.',
      es: 'Es posible que no pueda realizar mi terapia debido a cómo me siento emocionalmente.',
    },
  ],
  whereOccur: [
    { en: `At my employer's business`, es: 'En el negocio de mi empleador' },
    { en: 'On a remote worksite', es: 'En un lugar de trabajo remoto' },
    { en: 'While working from home', es: 'Mientras trabaja desde casa' },
    {
      en: 'During work-related travel',
      es: 'Durante viajes relacionados con el trabajo',
    },
  ],
  accidentDescriptions: [
    { en: `Rear-ended`, es: `Trasero` },
    { en: `Head-on`, es: `De frente` },
    { en: `Side impact`, es: `Impacto lateral` },
  ],
  secondaryCollisionsDescriptions: [
    { en: `None`, es: `Ninguno` },
    {
      en: `Pushed into vehicle in front`,
      es: `Empujado en el vehículo en frente`,
    },
    { en: `Hit guardrail`, es: `Golpear la barandilla` },
  ],
  vehicleTypes: [
    { en: `Car`, es: `Coche` },
    { en: `Pickup truck`, es: `Camioneta` },
    { en: `SUV`, es: `SUV` },
  ],
  vehicleDamage: [
    { en: `Light`, es: `Poco daño` },
    { en: `Moderate`, es: `Daño moderado` },
    { en: `Heavy`, es: `Bastante daño` },
  ],
  vehicleDrivable: [
    { en: `Drivable`, es: `Manejable` },
    { en: `Not drivable`, es: `No manejable` },
  ],
  vehicleSitting: [
    { en: `Front driver`, es: `Conductor` },
    { en: `Front passenger`, es: `Co-piloto` },
    { en: `Rear driver`, es: `Atrás del conductor` },
    { en: `Rear passenger`, es: `Atrás del co-piloto` },
  ],
  roadConditions: [
    { en: `Dry`, es: `Seca` },
    { en: `Wet`, es: `Mojada` },
    { en: `Snow / Ice`, es: `Nieve/Hielo` },
  ],
  looking: [
    { en: `Toward left`, es: `Hacia la izquierda` },
    { en: `Straight ahead`, es: `Hacia al frente` },
    { en: `Toward right`, es: `Hacia la derecha` },
    { en: `Up`, es: `Hacia arriba` },
    { en: `Down`, es: `Hacia abajo` },
  ],
  bracedForImpact: [
    { en: `Braced for impact`, es: `Preparado para el impacto` },
    {
      en: `Unaware of the impending collision`,
      es: `Sin darse cuenta de la inminente colisión`,
    },
  ],
  noticeSymptoms: [
    {
      en: `Immediately after the injury`,
      es: `Inmediatamente después de la lesión`,
    },
    { en: `Later that same day`, es: `Más tarde ese mismo día` },
    { en: `The next day`, es: `El día siguiente` },
    { en: `In the days that followed`, es: `En los días que siguieron` },
  ],
  seekCare: [
    {
      en: `Immediately after the injury`,
      es: `Inmediatamente después de la lesión`,
    },
    { en: `Later that same day`, es: `Más tarde ese mismo día` },
    { en: `The next day`, es: `El día siguiente` },
    { en: `In the days that followed`, es: `En los días que siguieron` },
  ],
};

const createDescribeQuestion = (
  mainQuestionId: string,
  es: boolean,
  language: string,
  region: string,
  spanishRegion: string,
  order: number,
  problemArea: number,
  complaintId?: string,
) => {
  const optionData = [...data.describe];
  if (region.includes('head')) {
    optionData.push({ en: 'Dizziness', es: 'Convulsiones Mareo' });
  }
  const options = createOptions(optionData, language);

  return {
    id: mainQuestionId,
    question: es
      ? `¿Cómo describiría sus síntomas en ${spanishRegion}?`
      : `How would you describe your ${region} symptoms?`,
    options,
    order: ++order,
    type: 'checkbox',
    problemArea,
    complaintId,
    typeOptions: {
      includeOther: true,
    },
  };
};

const createBestDescribesQuestion = (
  region: string,
  spanishRegion: string,
  pain: string,
  order: number,
  mainQuestionId: string,
  questionId: string,
  language = 'en',
  problemArea: number,
  complaintId?: string,
) => {
  const es = language === 'es';
  let options: { display: string; id: string }[] = [];
  if (pain === 'Pain / Discomfort') {
    options = createOptions(data.bestDescribes.painDiscomfort, language);
  } else if (pain === 'Altered sensation') {
    options = createOptions(data.bestDescribes.alteredSensation, language);
  } else if (pain === 'Weakness') {
    options = createOptions(data.bestDescribes.weakness, language);
  }
  return {
    id: questionId,
    question: es
      ? `¿Qué describe mejor su ${spanishPain[pain]} en ${spanishRegion}?`
      : `What best describes your ${region} ${pain}?`,
    order,
    options,
    type: 'checkbox',
    problemArea,
    complaintId,
    if: [
      {
        type: SurveyQuestionIfType.equal,
        id: mainQuestionId,
        value: pain,
      },
    ],
  };
};

const createAggravatesQuestion = (
  region: string,
  spanishRegion: string,
  parts: BodyParts,
  order: number,
  questionId: string,
  language = 'en',
  problemArea: number,
  complaintId?: string,
) => {
  const es = language === 'es';
  let options: { en: string; es: string }[] = data.aggravates.all;
  if (
    parts.front.head?.left ||
    parts.front.head?.right ||
    parts.rear.head?.left ||
    parts.rear.head?.right
  ) {
    options = options.concat(data.aggravates.head);
  }
  if (
    parts.front.foot?.left ||
    parts.front.foot?.right ||
    parts.rear.foot?.left ||
    parts.rear.foot?.right ||
    parts.front.ankle?.left ||
    parts.front.ankle?.right ||
    parts.rear.ankle?.left ||
    parts.rear.ankle?.right
  ) {
    options = options.concat(data.aggravates.footAnkle);
  }
  if (
    parts.front.knee?.left ||
    parts.front.knee?.right ||
    parts.rear.knee?.left ||
    parts.rear.knee?.right ||
    parts.front.leg?.left ||
    parts.front.leg?.right ||
    parts.rear.leg?.left ||
    parts.rear.leg?.right ||
    parts.front.thigh?.left ||
    parts.front.thigh?.right ||
    parts.rear.thigh?.left ||
    parts.rear.thigh?.right
  ) {
    options = options.concat(data.aggravates.kneeLeg);
  }
  if (
    parts.front.hip?.left ||
    parts.front.hip?.right ||
    parts.rear.hip?.left ||
    parts.rear.hip?.right
  ) {
    options = options.concat(data.aggravates.hip);
  }

  const hasLowerBackIssue =
    parts.rear['lower-back']?.left ||
    parts.rear['lower-back']?.right ||
    parts.rear['upper-lower-back']?.left ||
    parts.rear['upper-lower-back']?.right;
  const hasUpperBackIssue =
    parts.rear['upper-back']?.left || parts.rear['upper-back']?.right;
  const hasRearNeckIssue = parts.rear.neck?.left || parts.rear.neck?.right;

  if (hasLowerBackIssue && hasUpperBackIssue && hasRearNeckIssue) {
    options = options.concat(data.aggravates.fullSpine);
  } else if (
    parts.rear['lower-back']?.left ||
    parts.rear['lower-back']?.right ||
    parts.rear['upper-lower-back']?.left ||
    parts.rear['upper-lower-back']?.right ||
    parts.rear['upper-back']?.left ||
    parts.rear['upper-back']?.right
  ) {
    options = options.concat(data.aggravates.back);
    if (
      (parts.front.rib?.left || parts.front.rib?.right) &&
      (parts.rear['upper-back']?.left || parts.rear['upper-back']?.right)
    ) {
      options = options.concat(data.aggravates.backRib);
    }
  } else if (
    parts.rear.neck?.left ||
    parts.rear.neck?.right ||
    parts.front.neck?.left ||
    parts.front.neck?.right
  ) {
    options = options.concat(data.aggravates.neck);
  }

  if (
    parts.front.elbow?.left ||
    parts.front.elbow?.right ||
    parts.rear.elbow?.left ||
    parts.rear.elbow?.right ||
    parts.front.forearm?.left ||
    parts.front.forearm?.right ||
    parts.rear.forearm?.left ||
    parts.rear.forearm?.right
  ) {
    options = options.concat(data.aggravates.elbowForearm);
  }

  if (
    parts.front.shoulder?.left ||
    parts.front.shoulder?.right ||
    parts.rear.shoulder?.left ||
    parts.rear.shoulder?.right ||
    parts.front.arm?.left ||
    parts.front.arm?.right ||
    parts.rear.arm?.left ||
    parts.rear.arm?.right
  ) {
    options = options.concat(data.aggravates.arm);
  }

  if (
    parts.front.hand?.left ||
    parts.front.hand?.right ||
    parts.rear.hand?.left ||
    parts.rear.hand?.right ||
    parts.front.wrist?.left ||
    parts.front.wrist?.right ||
    parts.rear.wrist?.left ||
    parts.rear.wrist?.right
  ) {
    options = options.concat(data.aggravates.hand);
  }
  const alreadyAdded: any = {};
  options = options.filter((item) => {
    if (alreadyAdded[item.en]) {
      return false;
    } else {
      alreadyAdded[item.en] = true;
      return true;
    }
  });

  const optionsToUse = createOptions(options, language);

  return {
    id: questionId,
    question: es
      ? `¿Qué empeora su dolor en ${spanishRegion}?`
      : `What makes your ${region} symptoms worse?`,
    order,
    options: optionsToUse,
    type: 'checkbox',
    problemArea,
    complaintId,
  };
};

// Question 6: Do you have any other new complaints to report?
// Yes

export const createAbbreviatedChiefComplaintQuestions = (
  parts: BodyParts,
  language: 'en' | 'es',
) => {
  const region = createName(parts);
  const es = language === 'es';
  const spanishRegion = es ? createSpanishRegions(region) : '';
  const questions: SurveyQuestion[] = [];

  questions.push(
    createDescribeQuestion(
      CCSPrimaryQuestionIds.mainQuestionId,
      es,
      language,
      region,
      spanishRegion,
      2,
      1,
    ),
  );

  questions.push(
    createBestDescribesQuestion(
      region,
      spanishRegion,
      'Pain / Discomfort',
      3,
      CCSPrimaryQuestionIds.mainQuestionId,
      CCSPrimaryQuestionIds.bestDescribesQuestionPainId,
      language,
      1,
    ),
  );
  questions.push(
    createBestDescribesQuestion(
      region,
      spanishRegion,
      'Altered sensation',
      3,
      CCSPrimaryQuestionIds.mainQuestionId,
      CCSPrimaryQuestionIds.bestDescribesQuestionAlteredId,
      language,
      1,
    ),
  );
  questions.push(
    createBestDescribesQuestion(
      region,
      spanishRegion,
      'Weakness',
      3,
      CCSPrimaryQuestionIds.mainQuestionId,
      CCSPrimaryQuestionIds.bestDescribesQuestionWeaknessId,
      language,
      1,
    ),
  );
  questions.push({
    id: '23b575bd-37fd-4624-ae3c-a276lld13eab',
    question: es
      ? `Desde la última visita, ¿tienes síntomas`
      : `Since the last visit, are your symptoms:`,
    order: 4,
    type: 'radio',
    problemArea: 1,
    options: createOptions(data.sinceLastVisit, language),
  });
  questions.push({
    id: '23b575bd-37fd-4624-ae3c-a27664d1jjab',
    question: es
      ? `Califique la gravedad promedio de los síntomas en ${spanishRegion}:`
      : `Please rate the <u>average</u> symptom severity of your ${region}:`,
    order: 5,
    type: 'slider',
    problemArea: 1,
    typeOptions: {
      min: 0,
      max: 10,
      left: es ? 'Sin dolor' : 'No pain',
      right: es ? 'Peor dolor' : 'Worst pain',
      iconScale: true,
    },
  });
  // order++;
  questions.push({
    id: abbreviatedCCSQuestionsIds.moreComplaintsQuestionId,
    question: es
      ? `¿Tiene algún otro síntoma que le gustaría informar?`
      : `Do you have any other symptoms you would like to report?`,
    order: 6,
    type: 'radio',
    options: createOptions(data.yesNo, language),
  });
  return questions;
};

export const createChiefComplaintQuestions = (
  parts: BodyParts,
  order: number,
  {
    mainQuestionId,
    providerCompleteContinueQuestionId,
    bilateralQuestionId,
    bestDescribesQuestionPainId,
    bestDescribesQuestionAlteredId,
    bestDescribesQuestionWeaknessId,
    symptomsArePresentQuestionId,
    aggravatesQuestionId,
    intensityQuestionId,
    intensity2QuestionId,
    symptomsBeginQuestionId,
    causedQuestionId,
    brieflyDescribeQuestionId,
    presentEpisodeQuestionId,
    onsetQuestionId,
    sameSymptomsId,
    estimateQuestionId,
    sleepPositionQuestionId,
    mattressQuestionId,
    providerQuestionId,
    currentMattressQuestionId,
    pillowQuestionId,
    reliefQuestionId,
    interferedQuestionId,
    generalInterferedQuestionId,
    pregnantQuestionId,
    pregnantConfirmedDateQuestionId,
    pregnantSuspectedDateQuestionId,
    yellowFlagsQuestionId,
    employerQuestionId,
    contactPersonQuestionId,
    contactPersonInfoQuestionId,
    occupationQuestionId,
    whenDidInjuryQuestionId,
    whereDidInjuryQuestionId,
    descriptionInjuryQuestionId,
    lostWorkInjuryQuestionId,
    howOftenLostWorkInjuryQuestionId,
    currentlyOffWorkInjuryQuestionId,
    didYouReportInjuryQuestionId,
    whoReportInjuryQuestionId,
    attorneyInjuryQuestionId,
    attorneyNameInjuryQuestionId,
    dateOfAccidentQuestionId,
    descriptionOfAccidentQuestionId,
    secondaryCollisionsQuestionId,
    vehicleTypeQuestionId,
    speedEstimateQuestionId,
    otherVehicleTypeQuestionId,
    otherSpeedEstimateQuestionRadioId,
    vehicleDamageQuestionId,
    vehicleDrivableQuestionId,
    vehicleSittingQuestionId,
    roadConditionsQuestionId,
    impactLookingQuestionId,
    onBrakeQuestionId,
    autoWereYouQuestionId,
    seatbeltQuestionId,
    airbagQuestionId,
    noticeSymptomsQuestionId,
    seekCareQuestionId,
    percentOfDaysQuestionId,
    sameIncidentQuestionId,
  }: any,
  language = 'en',
  problemArea: number,
  isProvider?: boolean,
  aaWI?: string,
  startingProblemArea?: number,
  additionalComplaintSurvey = false,
) => {
  const region = createName(parts);
  const es = language === 'es';
  const spanishRegion = es ? createSpanishRegions(region) : '';
  const questions: SurveyQuestion[] = [];
  const hasNeckIssueOnBothSides =
    (parts.front.neck?.left || parts.rear.neck?.left) &&
    (parts.front.neck?.right || parts.rear.neck?.right);
  const hasUpperBackIssueOnBothSides =
    (parts.front['upper-back']?.left || parts.rear['upper-back']?.left) &&
    (parts.front['upper-back']?.right || parts.rear['upper-back']?.right);
  const hasLowerBackIssueOnBothSides =
    (parts.front['lower-back']?.left || parts.rear['lower-back']?.left) &&
    (parts.front['lower-back']?.right || parts.rear['lower-back']?.right);
  const hasUpperLowerBackIssueOnBothSides =
    (parts.front['upper-lower-back']?.left ||
      parts.rear['upper-lower-back']?.left) &&
    (parts.front['upper-lower-back']?.right ||
      parts.rear['upper-lower-back']?.right);
  const hasHeadIssueOnBothSides =
    (parts.front.head?.left || parts.rear.head?.left) &&
    (parts.front.head?.right || parts.rear.head?.right);
  const hasChestIssueOnBothSides =
    (parts.front.chest?.left || parts.rear.chest?.left) &&
    (parts.front.chest?.right || parts.rear.chest?.right);
  const hasAbdomenIssueOnBothSides =
    (parts.front.abdomen?.left || parts.rear.abdomen?.left) &&
    (parts.front.abdomen?.right || parts.rear.abdomen?.right);
  const hasGroinIssueOnBothSides =
    (parts.front.groin?.left || parts.rear.groin?.left) &&
    (parts.front.groin?.right || parts.rear.groin?.right);

  if (
    hasNeckIssueOnBothSides ||
    hasUpperBackIssueOnBothSides ||
    hasLowerBackIssueOnBothSides ||
    hasUpperLowerBackIssueOnBothSides ||
    hasHeadIssueOnBothSides ||
    hasChestIssueOnBothSides ||
    hasAbdomenIssueOnBothSides ||
    hasGroinIssueOnBothSides
  ) {
    questions.push({
      id: bilateralQuestionId,
      question: es
        ? `Es la intensidad de sus síntomas:`
        : `Is the intensity of your symptoms:`,
      order: ++order,
      type: 'radio',
      problemArea: (startingProblemArea ?? 0) + problemArea,
      options: createOptions(data.bilateral, language),
    });
  }

  if (problemArea === 2 && aaWI) {
    questions.push({
      id: sameIncidentQuestionId,
      question: es
        ? `¿Es esta lesión el resultado del mismo incidente que la lesión sobre la que acaba de responder preguntas?`
        : `Is this injury the result of the same incident as the injury you just answered questions about?`,
      order: ++order,
      type: 'radio',
      problemArea: (startingProblemArea ?? 0) + problemArea,
      options: createOptions(data.yesNo, language),
    });
  }
  questions.push(
    createDescribeQuestion(
      mainQuestionId,
      es,
      language,
      region,
      spanishRegion,
      ++order,
      (startingProblemArea ?? 0) + problemArea,
    ),
  );
  order++;
  questions.push(
    createBestDescribesQuestion(
      region,
      spanishRegion,
      'Pain / Discomfort',
      order,
      mainQuestionId,
      bestDescribesQuestionPainId,
      language,
      (startingProblemArea ?? 0) + problemArea,
    ),
  );
  questions.push(
    createBestDescribesQuestion(
      region,
      spanishRegion,
      'Altered sensation',
      order,
      mainQuestionId,
      bestDescribesQuestionAlteredId,
      language,
      (startingProblemArea ?? 0) + problemArea,
    ),
  );
  questions.push(
    createBestDescribesQuestion(
      region,
      spanishRegion,
      'Weakness',
      order,
      mainQuestionId,
      bestDescribesQuestionWeaknessId,
      language,
      (startingProblemArea ?? 0) + problemArea,
    ),
  );
  if (isProvider) {
    questions.push({
      id: providerCompleteContinueQuestionId,
      question: `Would you like to continue the survey or add the complaint now?`,
      order: ++order,
      type: 'radio',
      problemArea: (startingProblemArea ?? 0) + problemArea,
      options: [
        {
          id: '0',
          display: 'Continue',
        },
        {
          id: '1',
          display: 'Complete complaint now',
        },
      ],
      typeOptions: {
        forceAnswer: true,
      },
    });
  }
  questions.push({
    id: percentOfDaysQuestionId,
    question: es
      ? `¿Qué porcentaje de días experimentas dolor en ${spanishRegion}?`
      : `What percent of days do you experience ${region} symptoms?`,
    order: ++order,
    type: 'slider',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    typeOptions: {
      min: 0,
      max: 100,
      step: 5,
      left: es ? 'Casi nunca' : 'Rarely',
      right: es ? 'Continuamente' : 'Continually',
    },
  });
  questions.push({
    id: symptomsArePresentQuestionId,
    question: es
      ? `¿Qué porcentaje de cada día está presente su dolor en ${spanishRegion}?`
      : `What percent of each day is your ${region} symptoms present?`,
    order: ++order,
    type: 'slider',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    typeOptions: {
      min: 0,
      max: 100,
      step: 5,
      left: es ? 'Casi nunca' : 'Rarely',
      right: es ? 'Continuamente' : 'Continually',
    },
  });
  questions.push({
    id: intensityQuestionId,
    question: es
      ? `Califique la gravedad promedio de los síntomas en ${spanishRegion}:`
      : `Please rate the <u>average</u> symptom severity of your ${region}:`,
    order: ++order,
    type: 'slider',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    typeOptions: {
      min: 0,
      max: 10,
      left: es ? 'Sin dolor' : 'No pain',
      right: es ? 'Peor dolor' : 'Worst pain',
      iconScale: true,
    },
    if: [
      {
        type: SurveyQuestionIfType.equal,
        id: mainQuestionId,
        value: 'Pain / Discomfort',
      },
    ],
  });
  questions.push({
    id: intensity2QuestionId,
    question: es
      ? `¿Cuál es la intensidad <u>más alta</u> de su molestia en ${spanishRegion}?`
      : `What is the <u>highest</u> intensity of your ${region} discomfort at <u>worst</u>?`,
    order: ++order,
    type: 'slider',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    typeOptions: {
      min: 0,
      max: 10,
      left: es ? 'Sin dolor' : 'No pain',
      right: es ? 'Peor dolor' : 'Worst pain',
      notLessThan: intensityQuestionId,
      iconScale: true,
    },
    if: [
      {
        type: SurveyQuestionIfType.equal,
        id: mainQuestionId,
        value: 'Pain / Discomfort',
      },
    ],
  });
  questions.push({
    id: symptomsBeginQuestionId,
    question: es
      ? `¿Cómo comenzaron sus síntomas en ${spanishRegion}?`
      : `How did your ${region} symptoms begin?`,
    options: createOptions(data.symptomsBegin, language),
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    if:
      // TODO: Fix this - it should show this question if the sameIncidentQuestionId is not answered
      problemArea === 2 && aaWI
        ? [
            {
              type: SurveyQuestionIfType.equal,
              id: sameIncidentQuestionId,
              value: 'No',
            },
          ]
        : [],
  });

  let sameIncidentIf: any;
  if (problemArea === 1 && sameIncidentQuestionId) {
    questions.push({
      id: sameIncidentQuestionId,
      question: es
        ? `¿Es esta lesión el resultado del mismo incidente que la lesión sobre la que acaba de responder preguntas?`
        : `Is this injury the result of the same incident as the injury you just answered questions about?`,
      options: createOptions(data.yesNo, language),
      order: ++order,
      type: 'radio',
      problemArea: (startingProblemArea ?? 0) + problemArea,
    });
    sameIncidentIf = {
      type: SurveyQuestionIfType.equal,
      id: sameIncidentQuestionId,
      value: 'No',
    };
  }

  // Start questions about the cause of the injury
  questions.push({
    id: causedQuestionId,
    question: es
      ? `¿Qué crees que causó los síntomas en ${spanishRegion}?`
      : `What do you think caused your ${region} symptoms?`,
    options: createOptions(data.caused, language),
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    typeOptions: {
      includeOther: true,
      forceAnswer: true,
    },
    if:
      problemArea === 2 && aaWI
        ? [
            {
              type: SurveyQuestionIfType.equal,
              id: sameIncidentQuestionId,
              value: 'No',
            },
          ]
        : [sameIncidentIf],
  });
  // START employment injury questions
  questions.push({
    id: employerQuestionId,
    question: es
      ? `¿Quién es o era su empleador cuando ocurrió esta lesión? Proporcione nombre, dirección y teléfono si lo tiene.`
      : `Who is or was your employer when this injury occurred? Please provide name, address and phone if available.`,
    order: ++order,
    type: 'textarea',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    if: [
      {
        type: SurveyQuestionIfType.notAnswered,
        id: CCSPrimaryQuestionIds.occupationQuestionId,
      },
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Employment-related injury',
      },
    ],
  });
  questions.push({
    id: contactPersonQuestionId,
    question: es
      ? `Con respecto a esta lesión, ¿tiene una persona de contacto específica en su lugar de trabajo?`
      : `Regarding this injury, do you have a specific contact person at your employer?`,
    options: createOptions(data.yesNo, language),
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Employment-related injury',
      },
    ],
  });
  questions.push({
    id: contactPersonInfoQuestionId,
    question: es
      ? `¿Cuál es el nombre, correo electrónico y número de teléfono de la persona de contacto de su empleador?`
      : `What is the name, email and phone number of your contact person at your employer?`,
    order: ++order,
    type: 'textarea',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: contactPersonQuestionId,
        value: 'Yes',
      },
    ],
  });
  questions.push({
    id: occupationQuestionId,
    question: es
      ? `¿Cuál es o era su ocupación en este empleador cuando ocurrió la lesión?`
      : `What is or was your occupation at this employer when the injury occurred?`,
    order: ++order,
    type: 'textarea',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    if: [
      {
        type: SurveyQuestionIfType.notAnswered,
        id: CCSPrimaryQuestionIds.occupationQuestionId,
      },
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Employment-related injury',
      },
    ],
  });
  questions.push({
    id: whenDidInjuryQuestionId,
    question: es
      ? `¿Cuándo ocurrió su lesión en este empleador?`
      : `When did your injury at this employer occur?`,
    order: ++order,
    type: 'date',
    typeOptions: {
      allowEstimate: true,
      pastOnly: true,
    },
    problemArea: (startingProblemArea ?? 0) + problemArea,
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Employment-related injury',
      },
    ],
  });
  questions.push({
    id: whereDidInjuryQuestionId,
    question: es
      ? `¿Dónde ocurrió esta lesión?`
      : `Where did this injury occur?`,
    options: createOptions(data.whereOccur, language),
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    typeOptions: {
      includeOther: true,
    },
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Employment-related injury',
      },
    ],
  });
  questions.push({
    id: descriptionInjuryQuestionId,
    question: es
      ? `Proporcione una breve descripción de la lesión.`
      : `Provide a brief description of the injury`,
    order: ++order,
    type: 'textarea',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Employment-related injury',
      },
    ],
  });
  questions.push({
    id: lostWorkInjuryQuestionId,
    question: es
      ? `¿Ha perdido el trabajo como resultado de esta lesión?`
      : `Have you lost work as a result of this injury?`,
    options: createOptions(data.yesNo, language),
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Employment-related injury',
      },
    ],
  });
  questions.push({
    id: howOftenLostWorkInjuryQuestionId,
    question: es
      ? `¿Cuánto trabajo ha perdido hasta ahora como resultado de su lesión (días, semanas, meses, años)?`
      : `How much work have you missed so far as a result of your injury (days, weeks, months, years)?`,
    order: ++order,
    type: 'textarea',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: lostWorkInjuryQuestionId,
        value: 'Yes',
      },
    ],
  });
  questions.push({
    id: currentlyOffWorkInjuryQuestionId,
    question: es
      ? `¿Está actualmente fuera del trabajo como resultado de esta lesión?`
      : `Are you currently off work as a result of this injury?`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.yesNo, language),
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: lostWorkInjuryQuestionId,
        value: 'Yes',
      },
    ],
  });
  questions.push({
    id: didYouReportInjuryQuestionId,
    question: es
      ? `¿Reportaste el accidente a tu empleador?`
      : `Did you report the accident to your employer?`,
    options: createOptions(data.yesNo, language),
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Employment-related injury',
      },
    ],
  });
  questions.push({
    id: whoReportInjuryQuestionId,
    question: es
      ? `¿A quién le informó de su lesión? (nombre y posición)`
      : `Who did you report your injury to? (name and position)`,
    order: ++order,
    type: 'textarea',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: didYouReportInjuryQuestionId,
        value: 'Yes',
      },
    ],
  });
  // END employment injury questions

  // START auto accident questions
  questions.push({
    id: dateOfAccidentQuestionId,
    question: es ? `Fecha del accidente` : `Date of accident`,
    order: ++order,
    type: 'date',
    typeOptions: {
      allowEstimate: true,
      pastOnly: true,
    },
    problemArea: (startingProblemArea ?? 0) + problemArea,
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
    ],
  });
  questions.push({
    id: descriptionOfAccidentQuestionId,
    question: es
      ? `Breve descripción del accidente`
      : `Brief description of accident`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.accidentDescriptions, language),
    typeOptions: {
      includeOther: true,
    },
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
    ],
  });
  questions.push({
    id: secondaryCollisionsQuestionId,
    question: es
      ? `Describe cualquier colisión secundaria.`
      : `Describe any secondary collisions`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.secondaryCollisionsDescriptions, language),
    typeOptions: {
      includeOther: true,
    },
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
    ],
  });
  questions.push({
    id: vehicleTypeQuestionId,
    question: es
      ? `¿En qué tipo de vehículo estabas?`
      : `What type of vehicle were you in?`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.vehicleTypes, language),
    typeOptions: {
      includeOther: true,
    },
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
    ],
  });
  questions.push({
    id: speedEstimateQuestionId,
    question: es
      ? `Velocidad estimada de su vehículo`
      : `Estimated speed of your vehicle`,
    order: ++order,
    type: 'slider',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    typeOptions: {
      min: 0,
      max: 100,
      step: 5,
      left: '0 mph / stopped',
      right: 'More than 100 mph',
    },
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
    ],
  });
  questions.push({
    id: otherVehicleTypeQuestionId,
    question: es
      ? `¿Qué tipo de vehículo era el otro vehículo?`
      : `What type of vehicle was the other vehicle?`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.vehicleTypes, language),
    typeOptions: {
      includeOther: true,
    },
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
    ],
  });
  questions.push({
    id: otherSpeedEstimateQuestionRadioId,
    question: es
      ? `Estima la velocidad del otro vehículo involucrado en el accidente`
      : `Estimate the speed of the other vehicle involved in the accident`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.otherVehicleSpeedEstimate, language),
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
    ],
  });
  questions.push({
    id: vehicleDamageQuestionId,
    question: es
      ? `¿Qué daño le hizo al vehículo?`
      : `How significant was the damage to your vehicle?`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.vehicleDamage, language),
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
    ],
  });
  questions.push({
    id: vehicleDrivableQuestionId,
    question: es
      ? `Después del accidente, ¿se podía conducir su vehículo?`
      : `Following the accident, was your vehicle driveable?`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.yesNo, language),
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
    ],
  });
  questions.push({
    id: vehicleSittingQuestionId,
    question: es ? `Donde estabas sentado` : `Where were you sitting?`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.vehicleSitting, language),
    typeOptions: {
      includeOther: true,
    },
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
    ],
  });
  questions.push({
    id: roadConditionsQuestionId,
    question: es
      ? `¿Cuáles eran las condiciones de la carretera en el momento del accidente?`
      : `What were the road conditions at the time of the accident?`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.roadConditions, language),
    typeOptions: {
      includeOther: true,
    },
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
    ],
  });
  questions.push({
    id: impactLookingQuestionId,
    question: es
      ? `En el momento del impacto, ¿en qué dirección miraba?`
      : `At the time of impact, which direction were you looking?`,
    order: ++order,
    type: 'checkbox',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.looking, language),
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
    ],
  });
  questions.push({
    id: onBrakeQuestionId,
    question: es
      ? `¿Estaba tu pie en el freno?`
      : `Was your foot on the brake?`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.yesNoUnknown, language),
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
      {
        type: SurveyQuestionIfType.equal,
        id: vehicleSittingQuestionId,
        value: 'Front driver',
      },
    ],
  });
  questions.push({
    id: autoWereYouQuestionId,
    question: es ? `Estabas tú?` : `Were you?`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.bracedForImpact, language),
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
    ],
  });
  questions.push({
    id: seatbeltQuestionId,
    question: es
      ? `¿Llevabas cinturón de seguridad?`
      : `Were you wearing a seatbelt?`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.yesNo, language),
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
    ],
  });
  questions.push({
    id: airbagQuestionId,
    question: es ? `¿Se desplegó su airbag?` : `Did your airbag deploy?`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.yesNo, language),
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
    ],
  });
  questions.push({
    id: noticeSymptomsQuestionId,
    question: es
      ? `¿Cuándo notó los síntomas por primera vez?`
      : `When did you first notice symptoms?`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.noticeSymptoms, language),
    typeOptions: {
      includeOther: true,
    },
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
    ],
  });
  questions.push({
    id: seekCareQuestionId,
    question: es
      ? `¿Cuándo buscó por primera vez atención para sus síntomas?`
      : `When did you first seek care for your symptoms?`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.seekCare, language),
    typeOptions: {
      includeOther: true,
    },
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
    ],
  });
  // END auto accident questions

  // START both auto accident and employer related injury questions
  questions.push({
    id: attorneyInjuryQuestionId,
    question: es
      ? `¿Tiene un abogado que lo represente por esta lesión?`
      : `Do you have an attorney representing you for this injury?`,
    options: createOptions(data.yesNo, language),
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.notAnswered,
        id: CCSPrimaryQuestionIds.attorneyInjuryQuestionId,
      },
    ],
    ifSome: [
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Employment-related injury',
      },
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Auto accident',
      },
    ],
  });
  questions.push({
    id: attorneyNameInjuryQuestionId,
    question: es
      ? `¿Cuál es el nombre y número de teléfono del abogado que lo representa por esta lesión?`
      : `What is the name and phone number of the attorney representing you for this injury?`,
    order: ++order,
    type: 'textarea',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    if: [
      sameIncidentIf,
      {
        type: SurveyQuestionIfType.equal,
        id: attorneyInjuryQuestionId,
        value: 'Yes',
      },
    ],
  });
  // END both auto accident and employer related injury questions
  // END questions about the cause of the injury

  questions.push({
    id: brieflyDescribeQuestionId,
    question: es
      ? `Describa brevemente lo que cree que causó su dolor en ${spanishRegion}:`
      : `Briefly describe what you think caused your your ${region} pain:`,
    order: ++order,
    type: 'input',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    ifSome: [
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Repetitive activity',
      },
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Single activity',
      },
    ],
  });
  questions.push({
    id: presentEpisodeQuestionId,
    question: es
      ? `¿Cuándo comenzó su actual episodio de síntomas en ${spanishRegion}?`
      : `When did your present episode of ${region} symptoms begin?`,
    order: ++order,
    type: 'date',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    ifSome: [
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Repetitive activity',
      },
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'Single activity',
      },
      {
        type: SurveyQuestionIfType.equal,
        id: causedQuestionId,
        value: 'No identifiable cause',
      },
    ],
    typeOptions: {
      allowEstimate: true,
      pastOnly: true,
    },
  });
  questions.push({
    id: onsetQuestionId,
    question: es
      ? `Desde el inicio del problema, cree que sus sintomas en ${spanishRegion} están:`
      : `Since the onset, do you feel that your ${region} symptoms are:`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.onset, language),
  });
  questions.push({
    id: sameSymptomsId,
    question: es
      ? `¿Ha tenido los mismos síntomas o síntomas similares en ${spanishRegion} en el pasado?`
      : `Have you had the same or very similar ${region} symptoms in the past?`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    options: createOptions(data.yesNo, language),
  });
  questions.push({
    id: estimateQuestionId,
    question: es
      ? `¿Puede estimar cuándo fue el primer episodio de sus síntomas en ${spanishRegion}?`
      : `Can you estimate when was the first episode of your ${region} symptoms?`,
    order: ++order,
    type: 'date',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    typeOptions: {
      allowEstimate: true,
      pastOnly: true,
    },
    if: [
      {
        type: SurveyQuestionIfType.equal,
        id: sameSymptomsId,
        value: 'Yes',
      },
    ],
  });
  const aggravatesQuestion = createAggravatesQuestion(
    region,
    spanishRegion,
    parts,
    ++order,
    aggravatesQuestionId,
    language,
    (startingProblemArea ?? 0) + problemArea,
  );
  questions.push(aggravatesQuestion);
  questions.push({
    id: sleepPositionQuestionId,
    question: es
      ? `¿En qué posición(s) sueles dormir?`
      : `What position(s) do you typically sleep in?`,
    order: ++order,
    type: 'checkbox',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    typeOptions: {
      includeOther: true,
    },
    options: createOptions(data.sleepPositions, language),
    if: [
      {
        type: SurveyQuestionIfType.notAnswered,
        id: CCSPrimaryQuestionIds.sleepPositionQuestionId,
      },
      {
        type: SurveyQuestionIfType.equal,
        id: aggravatesQuestionId,
        value: 'Sleeping',
      },
    ],
  });
  questions.push({
    id: mattressQuestionId,
    question: es
      ? `¿En qué tipo de colchón duermes?`
      : `What type of mattress do you sleep on?`,
    order: ++order,
    type: 'checkbox',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    typeOptions: {
      includeOther: true,
    },
    options: createOptions(data.mattressTypes, language),
    if: [
      {
        type: SurveyQuestionIfType.notAnswered,
        id: CCSPrimaryQuestionIds.mattressQuestionId,
      },
      {
        type: SurveyQuestionIfType.equal,
        id: aggravatesQuestionId,
        value: 'Sleeping',
      },
    ],
  });
  questions.push({
    id: currentMattressQuestionId,
    question: es
      ? `¿Qué edad tiene su colchón actual?`
      : `How old is your current mattress?`,
    order: ++order,
    type: 'input',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    if: [
      {
        type: SurveyQuestionIfType.notAnswered,
        id: CCSPrimaryQuestionIds.currentMattressQuestionId,
      },
      {
        type: SurveyQuestionIfType.equal,
        id: aggravatesQuestionId,
        value: 'Sleeping',
      },
    ],
  });
  questions.push({
    id: pillowQuestionId,
    question: es
      ? `¿En qué tipo de almohada duermes?`
      : `What type of pillow do you sleep on?`,
    order: ++order,
    type: 'radio',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    typeOptions: {
      includeOther: true,
    },
    options: createOptions(data.pillowTypes, language),
    if: [
      {
        type: SurveyQuestionIfType.notAnswered,
        id: CCSPrimaryQuestionIds.pillowQuestionId,
      },
      {
        type: SurveyQuestionIfType.equal,
        id: aggravatesQuestionId,
        value: 'Sleeping',
      },
    ],
  });
  questions.push({
    id: reliefQuestionId,
    question: es
      ? `¿Hay algo que te haya ayudado a aliviar los síntomas en ${spanishRegion}?`
      : `Does/has anything helped relieve your ${region} symptoms?`,
    order: ++order,
    type: 'checkbox',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    typeOptions: {
      includeOther: true,
    },
    options: createOptions(data.relief, language),
  });
  questions.push({
    id: providerQuestionId,
    question: es
      ? `¿Ha consultado con otros doctores acerca de sus síntomas en ${spanishRegion}?`
      : `Have you seen any other providers for your ${region} symptoms?`,
    order: ++order,
    type: 'checkbox',
    problemArea: (startingProblemArea ?? 0) + problemArea,
    typeOptions: {
      includeOther: true,
    },
    options: [
      ...createOptions(data.providers, language, {
        askForInput: true,
        inputPlaceholder: 'Name of provider',
      }),
    ],
  });
  if (problemArea === 1) {
    questions.push({
      id: interferedQuestionId,
      question: es
        ? `¿Qué número describe mejor cómo, durante la semana pasada, sus síntomas en ${spanishRegion} han interferido con su disfrute de la vida?`
        : `What number best describes how, during the past week, your ${region} symptoms have interfered with your enjoyment of life?`,
      order: ++order,
      type: 'slider',
      problemArea: (startingProblemArea ?? 0) + problemArea,
      typeOptions: {
        min: 0,
        max: 10,
        left: es ? 'En lo absoluto' : 'Not at all',
        right: es ? 'Drásticamente' : 'Drastically',
      },
    });
    questions.push({
      id: generalInterferedQuestionId,
      question: es
        ? `¿Qué número describe mejor cómo, durante la última semana, sus síntomas en ${spanishRegion} han interferido con su actividad general?`
        : `What number best describes how, during the past week, your ${region} symptoms have interfered with your general activity?`,
      order: ++order,
      type: 'slider',
      problemArea: (startingProblemArea ?? 0) + problemArea,
      typeOptions: {
        min: 0,
        max: 10,
        left: es ? 'En lo absoluto' : 'Not at all',
        right: es ? 'Drásticamente' : 'Drastically',
      },
    });
    questions.push({
      id: yellowFlagsQuestionId,
      question: es
        ? `¿Siente que alguna de estas afirmaciones aplica a usted o a su enfermedad?`
        : `Do you feel that any of these statements apply to you or your condition?`,
      order: ++order,
      type: 'checkbox',
      problemArea: (startingProblemArea ?? 0) + problemArea,
      options: createOptions(data.yellowFlagsOptions, language),
      ifSome: [
        {
          type: SurveyQuestionIfType.greaterThan,
          id: interferedQuestionId,
          value: 3, // More than 3
        },
        {
          type: SurveyQuestionIfType.greaterThan,
          id: generalInterferedQuestionId,
          value: 3, // More than 3
        },
      ],
    });
    questions.push({
      id: pregnantQuestionId,
      question: es
        ? `¿Existe alguna posibilidad de que esté embarazada?`
        : `Is there any chance that you are pregnant?`,
      order: ++order,
      type: 'radio',
      problemArea: (startingProblemArea ?? 0) + problemArea,
      options: createOptions(data.pregnant, language),
      if: [
        {
          demographic: true,
          type: SurveyQuestionIfType.greaterThan,
          property: 'age',
          value: 11, // More than 11
        },
        {
          demographic: true,
          type: SurveyQuestionIfType.lessThan,
          property: 'age',
          value: 61, // Less than 61
        },
        {
          demographic: true,
          type: SurveyQuestionIfType.equal,
          property: 'gender',
          value: 'female',
        },
      ],
    });
    questions.push({
      id: pregnantConfirmedDateQuestionId,
      question: es ? `¿Cuál es su fecha de parto?` : `What is your due date?`,
      order: ++order,
      type: 'date',
      typeOptions: {
        futureOnly: true,
      },
      problemArea: (startingProblemArea ?? 0) + problemArea,
      if: [
        {
          type: SurveyQuestionIfType.equal,
          id: pregnantQuestionId,
          value: 'Yes (confirmed due date)',
        },
      ],
    });
    questions.push({
      id: pregnantSuspectedDateQuestionId,
      question: es
        ? `Aproximadamente, ¿cuándo fue su última menstruación?`
        : `Approximately, when was your last menstrual period?`,
      order: ++order,
      type: 'date',
      problemArea: (startingProblemArea ?? 0) + problemArea,
      typeOptions: {
        allowEstimate: true,
        pastOnly: true,
      },
      if: [
        {
          type: SurveyQuestionIfType.equal,
          id: pregnantQuestionId,
          value: 'Possibly (unconfirmed/suspected)',
        },
      ],
    });
    if (!additionalComplaintSurvey) {
      questions.push({
        id: '23b575bd-37fd-4624-ae3c-a27664d13eab',
        question: es
          ? `¿Tiene algún otro síntoma que le gustaría informar?`
          : `Do you have any other symptoms you would like to report?`,
        order: ++order,
        type: 'radio',
        options: createOptions(data.yesNo, language),
      });
      questions.push({
        id: CCSBodyPickerQuestionIds.secondary, // BODYPICKER 2
        question: es
          ? `Haga clic en el cuerpo para seleccionar las partes que le causan dolor.`
          : `Please click on the body to select the parts that are causing you pain.`,
        order: ++order,
        type: 'bodypicker',
        problemArea: (startingProblemArea ?? 0) + 2,
        if: [
          {
            type: SurveyQuestionIfType.equal,
            id: '23b575bd-37fd-4624-ae3c-a27664d13eab',
            value: 'Yes',
          },
        ],
      });
    }
  }

  if (problemArea === 2 && !additionalComplaintSurvey) {
    questions.push({
      id: '374cc214-6ba2-4b50-875a-d6268e8e39e5',
      question: es
        ? `¿Tiene algún otro síntoma que le gustaría informar?`
        : `Do you have any other symptoms you would like to report?`,
      order: ++order,
      type: 'radio',
      options: createOptions(data.yesNo, language),
    });
    questions.push({
      id: CCSBodyPickerQuestionIds.tertiary, // BODYPICKER 3
      question: es
        ? `Haga clic en el cuerpo para seleccionar las partes que le causan dolor.`
        : `Please click on the body to select the parts that are causing you pain.`,
      order: ++order,
      type: 'bodypicker',
      problemArea: (startingProblemArea ?? 0) + 3,
      if: [
        {
          type: SurveyQuestionIfType.equal,
          id: '374cc214-6ba2-4b50-875a-d6268e8e39e5',
          value: 'Yes',
        },
      ],
    });
  }
  return questions;
};

//REEVAL SURVEY
export const createReevalQuestions = (
  parts: BodyParts,
  problemArea: number,
  complaintId: string,
  order: number,
  {
    mainQuestionId,
    bestDescribesQuestionPainId,
    bestDescribesQuestionAlteredId,
    bestDescribesQuestionWeaknessId,
    symptomsArePresentQuestionId,
    aggravatesQuestionId,
    intensityQuestionId,
    intensity2QuestionId,
    onsetQuestionId,
    providerQuestionId,
    reliefQuestionId,
    interferedQuestionId,
    generalInterferedQuestionId,
    percentOfDaysQuestionId,
  }: any,
  language = 'en',
  aaWI?: string,
  weeks = 1,
) => {
  const region = createName(parts);
  const spanishRegion = createSpanishRegions(region);
  const es = language === 'es';
  const questions: SurveyQuestion[] = [];

  questions.push({
    id: `${ReevalSurveyQuestionIds.reevalSymptomsQuestionId}-${complaintId}`,
    question: es
      ? `Desde que comenzó la atención, sus síntomas en ${spanishRegion} son:`
      : `Since starting care, are your ${region} symptoms:`,
    order: ++order,
    type: 'radio',
    problemArea,
    complaintId,
    options: createOptions(data.reevalSymptoms, language),
  });
  questions.push({
    id: `${ReevalSurveyQuestionIds.reevalSymptomsPercentImprovedQuestionId}-${complaintId}`,
    question: es
      ? `
      En comparación con el inicio, ¿cómo calificaría el porcentaje general de mejora de sus síntomas en ${spanishRegion}?`
      : `As compared to initially, how would you rate your overall percent improvement for your ${region} symptoms?`,
    order: ++order,
    type: 'slider',
    typeOptions: {
      min: 0,
      max: 100,
      step: 5,
    },
    problemArea,
    complaintId,
    if: [
      {
        type: SurveyQuestionIfType.equal,
        id: `${ReevalSurveyQuestionIds.reevalSymptomsQuestionId}-${complaintId}`,
        value: 'Improved',
      },
    ],
  });

  questions.push({
    id: `${ReevalSurveyQuestionIds.reevalsymptomsSpreadQuestionId}-${complaintId}`,
    question: es
      ? `En comparación con ${weeks} ${
          weeks > 1 ? 'semanas' : 'semana'
        } ¿hace que el área de sus síntomas se expandió, se encogió o permaneció más o menos igual?`
      : `As compared to ${weeks} ${
          weeks > 1 ? 'weeks' : 'week'
        } ago, has the area of your symptoms expanded, shrunk, or stayed about the same?`,
    type: 'radio',
    order: ++order,
    problemArea,
    complaintId,
    options: createOptions(data.spread, language),
  });

  questions.push(
    createDescribeQuestion(
      `${mainQuestionId}-${complaintId}`,
      es,
      language,
      region,
      spanishRegion,
      ++order,
      problemArea,
      complaintId,
    ),
  );
  order++;
  questions.push(
    createBestDescribesQuestion(
      region,
      spanishRegion,
      'Pain / Discomfort',
      order,
      `${mainQuestionId}-${complaintId}`,
      `${bestDescribesQuestionPainId}-${complaintId}`,
      language,
      problemArea,
      complaintId,
    ),
  );
  questions.push(
    createBestDescribesQuestion(
      region,
      spanishRegion,
      'Altered sensation',
      order,
      `${mainQuestionId}-${complaintId}`,
      `${bestDescribesQuestionAlteredId}-${complaintId}`,
      language,
      problemArea,
      complaintId,
    ),
  );
  questions.push(
    createBestDescribesQuestion(
      region,
      spanishRegion,
      'Weakness',
      order,
      `${mainQuestionId}-${complaintId}`,
      `${bestDescribesQuestionWeaknessId}-${complaintId}`,
      language,
      problemArea,
      complaintId,
    ),
  );
  questions.push({
    id: `${percentOfDaysQuestionId}-${complaintId}`,
    question: es
      ? `¿Qué porcentaje de días experimentas dolor en ${spanishRegion}?`
      : `What percent of days do you experience ${region} symptoms?`,
    order: ++order,
    type: 'slider',
    problemArea,
    complaintId,
    typeOptions: {
      min: 0,
      max: 100,
      step: 5,
      left: es ? 'Casi nunca' : 'Rarely',
      right: es ? 'Continuamente' : 'Continually',
    },
  });
  questions.push({
    id: `${symptomsArePresentQuestionId}-${complaintId}`,
    question: es
      ? `¿Qué porcentaje de cada día está presente su dolor en ${spanishRegion}?`
      : `What percent of each day is your ${region} symptoms present?`,
    order: ++order,
    type: 'slider',
    problemArea,
    complaintId,
    typeOptions: {
      min: 0,
      max: 100,
      step: 5,
      left: es ? 'Casi nunca' : 'Rarely',
      right: es ? 'Continuamente' : 'Continually',
    },
  });
  questions.push({
    id: `${intensityQuestionId}-${complaintId}`,
    question: es
      ? `Califique la gravedad promedio de los síntomas en ${spanishRegion}:`
      : `Please rate the <u>average</u> symptom severity of your ${region}:`,
    order: ++order,
    type: 'slider',
    problemArea,
    complaintId,
    typeOptions: {
      min: 0,
      max: 10,
      left: es ? 'Sin dolor' : 'No pain',
      right: es ? 'Peor dolor' : 'Worst pain',
      iconScale: true,
    },
    if: [
      {
        type: SurveyQuestionIfType.equal,
        id: `${mainQuestionId}-${complaintId}`,
        value: 'Pain / Discomfort',
      },
    ],
  });
  questions.push({
    id: `${intensity2QuestionId}-${complaintId}`,
    question: es
      ? `¿Cuál es la intensidad <u>más alta</u> de su molestia en ${spanishRegion}?`
      : `What is the <u>highest</u> intensity of your ${region} discomfort at <u>worst</u>?`,
    order: ++order,
    type: 'slider',
    problemArea,
    complaintId,
    typeOptions: {
      min: 0,
      max: 10,
      left: es ? 'Sin dolor' : 'No pain',
      right: es ? 'Peor dolor' : 'Worst pain',
      notLessThan: intensityQuestionId,
      iconScale: true,
    },
    if: [
      {
        type: SurveyQuestionIfType.equal,
        id: `${mainQuestionId}-${complaintId}`,
        value: 'Pain / Discomfort',
      },
    ],
  });

  questions.push({
    id: `${onsetQuestionId}-${complaintId}`,
    question: es
      ? `Desde que comenzó la atención, ¿siente que sus síntomas en ${spanishRegion} son`
      : `Since starting care, do you feel that your ${region} symptoms are:`,
    order: ++order,
    type: 'radio',
    problemArea,
    complaintId,
    options: createOptions(data.onset, language),
  });

  const aggravatesQuestion = createAggravatesQuestion(
    region,
    spanishRegion,
    parts,
    ++order,
    `${aggravatesQuestionId}-${complaintId}`,
    language,
    problemArea,
    complaintId,
  );
  questions.push(aggravatesQuestion);

  questions.push({
    id: `${reliefQuestionId}-${complaintId}`,
    question: es
      ? `¿Hay algo que te haya ayudado a aliviar los síntomas en ${spanishRegion}?`
      : `Does/has anything helped relieve your ${region} symptoms?`,
    order: ++order,
    type: 'checkbox',
    problemArea,
    complaintId,
    typeOptions: {
      includeOther: true,
    },
    options: createOptions(data.relief, language),
  });
  questions.push({
    id: `${providerQuestionId}-${complaintId}`,
    question: es
      ? `¿Ha consultado a otros proveedores para sus síntomas de en ${spanishRegion}?`
      : `Have you seen any other providers for your ${region} symptoms?`,
    order: ++order,
    type: 'checkbox',
    problemArea,
    complaintId,
    typeOptions: {
      includeOther: true,
    },
    options: [
      ...createOptions(data.providers, language, {
        askForInput: true,
        inputPlaceholder: 'Name of provider',
      }),
    ],
  });
  questions.push({
    id: `${interferedQuestionId}-${complaintId}`,
    question: es
      ? `¿Qué número describe mejor cómo, durante la semana pasada, sus síntomas en ${spanishRegion} han interferido con su disfrute de la vida?`
      : `What number best describes how, during the past week, your ${region} symptoms have interfered with your enjoyment of life?`,
    order: ++order,
    type: 'slider',
    problemArea,
    complaintId,
    typeOptions: {
      min: 0,
      max: 10,
      left: es ? 'En lo absoluto' : 'Not at all',
      right: es ? 'Drásticamente' : 'Drastically',
    },
  });
  questions.push({
    id: `${generalInterferedQuestionId}-${complaintId}`,
    question: es
      ? `¿Qué número describe mejor cómo, durante la última semana, sus síntomas en ${spanishRegion} han interferido con su actividad general?`
      : `What number best describes how, during the past week, your ${region} symptoms have interfered with your general activity?`,
    order: ++order,
    type: 'slider',
    problemArea,
    complaintId,
    typeOptions: {
      min: 0,
      max: 10,
      left: es ? 'En lo absoluto' : 'Not at all',
      right: es ? 'Drásticamente' : 'Drastically',
    },
  });
  return questions;
};
