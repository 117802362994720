import { Transition } from '@headlessui/react';
import React from 'react';

const verbiage = {
  en: {
    getStarted: 'Get started',
  },
  es: {
    getStarted: 'Empezar',
  },
};

type Props = {
  show: boolean;
  title: string;
  subtitle: string;
  startMessage: string;
  start: () => void;
  language: 'en' | 'es';
  primaryColor: string;
};

export const SurveyIntro: React.FC<Props> = ({
  show,
  title,
  subtitle,
  startMessage,
  start,
  language = 'en',
  primaryColor,
}) => {
  return (
    <Transition
      show={show}
      enter="transition ease-out duration-300"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-300"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div className="flex items-center justify-center px-4 w-full">
        <div className="max-w-5xl mx-auto">
          <div className="text-center">
            <h2
              className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl"
              data-cy={title}
            >
              {title}
              <br />
              <span style={{ color: primaryColor }}>{subtitle}</span>
            </h2>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              {startMessage}
            </p>

            <button
              onClick={start}
              className="cursor-pointer rounded-md shadow items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white hover:opacity-90 focus:outline-none focus:ring transition duration-150 ease-in-out mt-8"
              style={{
                backgroundColor: primaryColor,
              }}
              data-cy="get-started-button"
            >
              {verbiage[language === 'es' ? 'es' : 'en'].getStarted}
            </button>
          </div>
        </div>
      </div>
    </Transition>
  );
};
