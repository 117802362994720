import { LoadingPage } from '@chiroup/components';
import React, { useEffect } from 'react';
import RequestMethodSelect from './RequestMethodSelect';

type Props = {
  handleSubmit: () => Promise<void>;
  handleSubmitMethod: (method: string) => void;
  methods?: { phone: string; email: string; language: string };
  error?: string;
  loading: boolean;
  invalidCode?: boolean;
};

const RequestAuth: React.FC<Props> = ({
  handleSubmit,
  error,
  loading,
  methods,
  handleSubmitMethod,
  invalidCode = false,
}) => {
  useEffect(() => {
    const doIt = async () => {
      await handleSubmit();
    };
    doIt();
  }, [handleSubmit]);

  return error ? (
    <div className="min-h-screen flex items-center mx-auto justify-center w-full max-w-xl flex-col p-4">
      <p className="ext-2xl font-extrabold tracking-tight text-gray-700 sm:text-4xl w-full text-center">
        {error}
      </p>
    </div>
  ) : !methods ? (
    <LoadingPage />
  ) : (
    <RequestMethodSelect
      methods={methods}
      loading={loading}
      onSubmitMethod={handleSubmitMethod}
      kiosk={false}
      invalidCode={invalidCode}
    />
  );
};

export default RequestAuth;
