import React from 'react';

const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const DatepickerDaysOfWeek: React.FC = () => {
  return (
    <>
      {dayNames.map((day) => (
        <div key={day} className="col-span-1 text-center text-gray-400 text-md">
          {day}
        </div>
      ))}
    </>
  );
};

export default DatepickerDaysOfWeek;
