import { titleCase } from '@chiroup/core';
import React, { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MeContext } from '../../../contexts/me.context';
import requestService from '../../../services/request.service';
import Button, { ButtonColors } from '../../common/Button';

type Props = {
  requestId: string;
  kiosk?: boolean;
  validationCode: string | number;
  prMap: { [key: string]: string };
  side: 'front' | 'back';
  imagePreview: string | null;
  onChangeImagePreview: (url: string | null) => void;
  className?: string;
  onUploadInsuranceCard: (url: string) => void;
  language?: string;
};

const UploadInsuranceSide: React.FC<Props> = ({
  requestId,
  validationCode,
  prMap,
  side,
  onChangeImagePreview,
  imagePreview,
  onUploadInsuranceCard,
  className,
  language = 'en',
}) => {
  const {
    clinicInfo: { primaryColor },
  } = useContext(MeContext);
  const [uploading, setUploading] = useState<boolean>(false);
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles?.length) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const arrayBuffer = reader.result;
          if (arrayBuffer) {
            const urlObject = URL.createObjectURL(new Blob([arrayBuffer]));
            onChangeImagePreview(urlObject);
          }
        };
        reader.readAsArrayBuffer(acceptedFiles[0]);
        setUploading(true);
        try {
          const res = await requestService.uploadInsuranceCard(
            requestId,
            validationCode,
            acceptedFiles[0],
            side,
            prMap,
            'insurance',
          );
          onUploadInsuranceCard?.(res);
        } catch (err) {
          onChangeImagePreview(null);
          console.error(err);
          // TODO: Show error message
        }
        setUploading(false);
      }
    },
    [
      onUploadInsuranceCard,
      prMap,
      requestId,
      onChangeImagePreview,
      side,
      validationCode,
    ],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: 10485760, // 10MB
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
    } as any,
  });

  return (
    <div className={className || ''}>
      <div className={`flex flex-col justify-center sm:min-h-screen`}>
        <div className="flex-grow">
          <div className="px-2 py-2 bg-white border-b border-gray-300 rounded-t-md sm:px-6">
            <div className="flex flex-wrap items-center justify-between w-full -mt-2 -ml-4 sm:flex-nowrap">
              <div className="flex items-center mt-2 ml-4">
                <h4 className="block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2 dark:text-darkGray-200">
                  {language === 'es'
                    ? 'Tarjeta de Seguro - ' +
                      `${
                        side === 'front' ? 'Parte Frontal' : 'Parte Posterior'
                      }`
                    : `Insurance Card - ${titleCase(side)}`}
                </h4>
              </div>
              {imagePreview && (
                <Button
                  text="Try again"
                  className="pt-4"
                  color={ButtonColors.plain}
                  onClick={() => onChangeImagePreview(null)}
                />
              )}
            </div>
          </div>
          <div
            {...getRootProps()}
            className="flex justify-center max-w-lg mx-2 mt-4 border-2 border-dashed rounded-md"
            style={{
              borderColor: isDragActive ? primaryColor : '#d1d5db',
            }}
          >
            {imagePreview ? (
              <div className="relative w-full rounded-md">
                <img
                  className="w-full rounded-md"
                  src={imagePreview}
                  alt="ID"
                />
                {uploading && (
                  <div
                    className="absolute top-0 left-0 flex items-center justify-center w-full h-full rounded-md opacity-75"
                    style={{
                      backgroundColor: primaryColor,
                    }}
                  >
                    <svg
                      className="w-20 h-20 mr-3 -ml-1 text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  </div>
                )}
              </div>
            ) : (
              <div className="px-6 pt-5 pb-6">
                <input {...getInputProps()} />
                <div className="text-center">
                  <svg
                    className="w-12 h-12 mx-auto text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className="mt-1 text-sm text-gray-600">
                    <button
                      type="button"
                      className="font-medium transition duration-150 ease-in-out hover:opacity-90 focus:outline-none focus:underline"
                      style={{
                        color: primaryColor,
                      }}
                    >
                      Upload a file
                    </button>{' '}
                    or drag and drop
                  </p>
                  <p className="mt-1 text-xs text-gray-500">
                    PNG, JPG, GIF up to 10MB
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadInsuranceSide;
