import React from 'react';

type Props = {
  title: string;
  center?: boolean;
};

export const SurveyQuestionTitle: React.FC<Props> = ({
  title,
  center = false,
}) => {
  return (
    <h2
      className={[
        'text-2xl font-extrabold tracking-tight text-gray-700 sm:text-4xl',
        center ? 'text-center' : '',
      ].join(' ')}
    >
      <span dangerouslySetInnerHTML={{ __html: title }} />
    </h2>
  );
};
