import qs from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../common/Button';
import useEmailSubscription from './useEmailSubscription';
import { LoadingPage } from '@chiroup/components';

const EmailSubscription: React.FC = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  const { subscribed, submitting, subscribe, unsubscribe, isFetching } =
    useEmailSubscription(queryParams.token as string);

  return isFetching ? (
    <LoadingPage />
  ) : (
    <div className="flex items-center justify-center w-full min-h-screen">
      <div className="flex items-center justify-center px-4 w-full">
        <div className="max-w-5xl mx-auto">
          <div className="text-center">
            <h2 className="text-5xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none md:text-6xl">
              Subscription preferences
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl">
              You are currently{' '}
              {subscribed ? 'subscribed to ' : 'unsubscribed from '}
              our clinic's reminders. By unsubscribing, you will no longer
              receive email reminders of your upcoming appointments.
            </p>
            <Button
              className="mt-12"
              onClick={subscribed ? unsubscribe : subscribe}
              text={subscribed ? 'Unsubscribe' : 'Subscribe'}
              loading={submitting}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSubscription;
