import { Transition } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Fragment, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

const IDLE_TIME = 5 * 60;
const SHOW_IDLE_MODAL_TIME = 30;

type Props = {
  activatePrompt?: boolean;
  onInactive?: () => void;
  children: React.ReactNode;
};

const KioskInactivityModal: React.FC<Props> = ({
  activatePrompt = true,
  onInactive,
  children,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [idleResetTimer, setIdleResetTimer] = useState<number | null>(
    SHOW_IDLE_MODAL_TIME,
  );
  const [intervalId, setIntervalId] =
    useState<ReturnType<typeof setInterval>>();

  const onPrompt = () => {
    if (activatePrompt) {
      setShow(true);
      resetInterval();
      setIdleResetTimer(Math.round(getRemainingTime() / 1000));
      const inter = setInterval(() => {
        setIdleResetTimer(Math.round(getRemainingTime() / 1000));
      }, 1000);
      setIntervalId(inter);
    }
  };

  const resetInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  };

  const onIdle = () => {
    setShow(false);
    resetInterval();
    onInactive?.();
  };

  const onActive = () => {
    setShow(false);
    resetInterval();
    reset();
  };

  const onAction = () => {
    setShow(false);
    resetInterval();
    reset();
  };

  const { getRemainingTime, reset } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: 1000 * IDLE_TIME,
    promptTimeout: 1000 * SHOW_IDLE_MODAL_TIME,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false,
  });
  return (
    <>
      {children}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="ml-4 flex-shrink-0 flex">
                    <div>
                      <ExclamationCircleIcon className="bg-white w-10 inline-flex text-gray-900" />
                    </div>
                  </div>
                  <div className="ml-3 w-0 flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      Are You Still There?
                    </p>
                    <p className="mt-1 text-sm text-gray-500  px-3 py-2">
                      {`You will be logged out in ${idleResetTimer} ${
                        idleResetTimer && idleResetTimer > 1
                          ? 'seconds'
                          : 'second'
                      }.`}
                    </p>
                    <p className="mt-1 text-sm text-gray-500  px-3 py-2">
                      Press any key to continue.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default KioskInactivityModal;
