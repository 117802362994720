import { SurveyQuestionRadio, SurveyQuestionTitle } from '@chiroup/components';
import {
  CommunicationMethodTypes,
  formatPhone,
  Genders,
  Patient,
  RequestStep,
  SurveyQuestionAnswer,
} from '@chiroup/core';
import { Transition } from '@headlessui/react';
import { useState } from 'react';
import { MeContext } from '../../contexts/me.context';
import { KioskScreens } from '../../helpers/constants';
import kioskService from '../../services/kiosk.service';
import Request from '../request/Request';
import RequestMethodSelect from '../request/RequestMethodSelect';
import KioskEnterPhone from './KioskEnterPhone';
import KioskForwardRequest from './KioskForwardRequest';
import KioskInactivityModal from './KioskInactivityModal';
import KioskNeedAssistancePending from './KioskNeedAssistancePending';
import KioskSimpleDemographicForm from './KioskSimpleDemographicForm';
import KioskAssistance from './KioskAssistance';
import KioskComplete from './KioskComplete';
import { signOut } from '@aws-amplify/auth';

type Props = {
  clinicInfo: {
    name: string;
    logo: string;
    primaryColor: string;
    accentColor: string;
    flags: string[];
  };
};

const KioskLoggedIn: React.FC<Props> = ({ clinicInfo }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [request, setRequest] = useState<any>();
  const [phone, setPhone] = useState<string>();
  const [patients, setPatients] = useState<Partial<Patient>[]>([]);
  const [noSurveyMessage, setNoSurveyMessage] = useState<string | null>(null);
  const [currentScreen, setCurrentScreen] = useState(KioskScreens.EnterPhone);
  const [show, setShow] = useState(true);
  const [methods, setMethods] = useState<{
    email: string;
    phone: string;
    language: string;
  }>({ email: '', phone: '', language: '' });
  const [selectedMethod, setSelectedMethod] = useState<string>('');
  const [forwardMessage, setForwardMessage] = useState<string>('');

  const handlePhoneSubmit = async (phone: string) => {
    if (!phone) {
      return;
    }
    setPhone(phone);
    await fetchData({ phone });
  };

  const handlePatientSubmit = async (patientId: string) => {
    if (!patientId) {
      return;
    }
    return await fetchData({ patientId });
  };

  const handleReset = (changeScreen = false) => {
    if (changeScreen) {
      setCurrentScreen(KioskScreens.EnterPhone);
    }
    setError('');
    setPhone('');
    setPatients([]);
    setNoSurveyMessage(null);
    setRequest(null);
  };

  const updateRequest = (
    data: {
      prMap: { [key: string]: string };
      steps: RequestStep[];
      language: 'en' | 'es';
      gender: Genders;
      age: number;
      id: string;
    },
    callback?: () => void,
  ) => {
    const values = Object.values(data?.prMap);
    setRequest({ ...data, id: values?.[0] });
    if (callback) {
      setTimeout(() => {
        callback();
      }, 1000);
    }
  };

  const fetchData = async (request: { phone?: string; patientId?: string }) => {
    setError('');
    setLoading(true);
    setNoSurveyMessage(null);
    try {
      if (request.phone) {
        // Here we are just getting a patient, or multiple patients so we can select one...
        const res = await kioskService
          .getRequest(request)
          .then((res) => res.patients);
        if (res?.length) {
          if (res?.length > 1) {
            setPatients(res);
          } else {
            await fetchData({ patientId: res[0].ID });
          }
        } else {
          setError('Invalid Phone Number');
        }
      } else if (request.patientId) {
        // Here we are checking in...
        const res = await kioskService.getRequest(request);
        if (res.pateintId) {
          setRequest(res);
          setLoading(false);
          return true;
        }

        if (res.methods) {
          setMethods(res.methods.data);
        }
        if (res.steps) {
          const values = Object.values(res.prMap);
          setRequest({ ...res, id: values?.[0] });
          changeScreens(KioskScreens.Request);
          setLoading(false);
          return false;
        } else {
          changeScreens(KioskScreens.Complete);
        }
      }
      setLoading(false);
      return true;
    } catch (err: any) {
      console.error('error???', err);
      setLoading(false);
      let errMessage =
        err?.response?.data?.message ||
        'No patient records found with this number. Please see receptionist.';
      if (errMessage === 'PATIENT_NOT_FOUND') {
        changeScreens(KioskScreens.PhoneNotFound);
      } else if (errMessage === 'APPOINTMENT_NOT_FOUND') {
        changeScreens(KioskScreens.AppointmentNotFound);
      } else {
        changeScreens(KioskScreens.EnterPhone);
        if (err?.response?.status === 401) {
          errMessage += ' Redirecting in 5 seconds.';
          setTimeout(() => {
            signOut();
            window.location.reload();
          }, 5000);
        }
        setError(errMessage);
      }
      return true;
    }
  };

  const onSelectPhoneAnswer = async (val: SurveyQuestionAnswer) => {
    if (val.value === 'yes') {
      changeScreens(KioskScreens.AreYouNew);
    } else {
      changeScreens(KioskScreens.EnterPhone);
      handleReset();
    }
  };

  const onSelectAreYouNew = async (val: SurveyQuestionAnswer) => {
    if (val.value === 'newPatient') {
      changeScreens(KioskScreens.NewPatient);
    } else {
      changeScreens(KioskScreens.EstablishedPatient);
    }
  };

  const onSelectRecentlyCompleted = async (val: SurveyQuestionAnswer) => {
    if (val.value === 'yes') {
      changeScreens(KioskScreens.RecentlyCompletedYes);
    } else {
      changeScreens(KioskScreens.RecentlyCompletedNo);
    }
  };
  const changeScreens = (newScreen: KioskScreens, logoutAfter?: number) => {
    setShow(false);
    setTimeout(async () => {
      setCurrentScreen(newScreen);
      setShow(true);
    }, 500);

    if (logoutAfter) {
      setTimeout(() => {
        handleReset(true);
      }, logoutAfter);
    }
  };

  const forwardMethod = async () => {
    let res;
    if (methods?.email && methods?.phone) {
      changeScreens(KioskScreens.EnterMethod);
      return;
    } else if (methods?.email) {
      setLoading(true);
      setSelectedMethod(CommunicationMethodTypes.email);
      res = await kioskService.forwardRequest(
        request.id,
        CommunicationMethodTypes.email,
        methods.email,
      );
    } else if (methods?.phone) {
      setLoading(true);
      res = await kioskService.forwardRequest(
        request.id,
        CommunicationMethodTypes.text,
        methods.phone,
      );
    }
    if (res.message) {
      setForwardMessage(res.message);
      changeScreens(KioskScreens.ForwardRequest);
    } else {
      setError('Failed to forward request');
    }
    setLoading(false);
  };

  const submitForwardRequest = async (methodType: string) => {
    setLoading(true);
    let maskedMethod = '';
    if (methods?.phone || methods?.email) {
      maskedMethod =
        methodType === CommunicationMethodTypes.email
          ? methods?.email
          : methods?.phone;

      setSelectedMethod(methodType);
    }

    const res = await kioskService.forwardRequest(
      request.id,
      methodType,
      maskedMethod,
    );
    if (res.message) {
      setForwardMessage(res.message);
      changeScreens(KioskScreens.ForwardRequest);
    } else {
      setError('Failed to forward request');
    }
    setLoading(false);
  };

  return (
    <MeContext.Provider
      value={{
        clinicInfo,
      }}
    >
      <KioskInactivityModal
        onInactive={() => handleReset(true)}
        activatePrompt={!!(phone || request)}
      >
        <div>
          {currentScreen === KioskScreens.Request ? (
            <Request
              data={request}
              id={request?.id}
              kiosk
              updateRequest={updateRequest}
              validationCode={request?.validationCode}
              guardianName={request?.guardianName}
              patientName={request?.patientName}
              handleReset={handleReset}
              forwardMethod={forwardMethod}
              methods={methods}
              loading={loading}
              weeks={request?.weeks}
              encounterId={request?.encounterId}
            />
          ) : currentScreen === KioskScreens.PhoneNotFound ? (
            <Transition
              show={show}
              enter="transition ease-out duration-300"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-300"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <div className="flex min-h-screen mx-auto justify-center flex-col p-4">
                <div className="flex items-start mx-auto justify-center w-full max-w-4xl flex-col p-4">
                  <div className="pb-4">
                    <SurveyQuestionTitle
                      title={`Our system does not recognize the phone number ${formatPhone(
                        phone,
                        true,
                      )}, are you sure this is the correct phone number?`}
                    />
                  </div>
                  <SurveyQuestionRadio
                    id="phoneNotFound"
                    options={[
                      {
                        display: `Yes`,
                        id: 'yes',
                      },
                      {
                        display: `No`,
                        id: 'no',
                      },
                    ]}
                    onSelect={onSelectPhoneAnswer}
                    disabled={false}
                    includeOther={false}
                    hideNext={true}
                    forceAnswer={true}
                    primaryColor={clinicInfo?.primaryColor}
                  />
                </div>
              </div>{' '}
            </Transition>
          ) : currentScreen === KioskScreens.AppointmentNotFound ? (
            <Transition
              show={show}
              enter="transition ease-out duration-300"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-300"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <KioskAssistance
                title="Appointment not found"
                subtitle="Assistance required"
                message="An appointment was not found in our system. In order to continue, please see the front office staff and let them know that you would like to check in."
                buttons={
                  <button
                    type="button"
                    className="inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white hover:opacity-90 focus:outline-none transition ease-in-out duration-150"
                    style={{
                      backgroundColor: clinicInfo?.primaryColor,
                    }}
                    onClick={() => {
                      changeScreens(KioskScreens.EnterPhone);
                      setPhone('');
                    }}
                  >
                    Logout
                  </button>
                }
                show={true}
              />{' '}
            </Transition>
          ) : currentScreen === KioskScreens.EnterPhone ? (
            <Transition
              show={show}
              enter="transition ease-out duration-300"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-300"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <KioskEnterPhone
                phone={phone}
                setPhone={setPhone}
                handleReset={handleReset}
                noSurveyMessage={noSurveyMessage}
                handlePhoneSubmit={handlePhoneSubmit}
                handlePatientSubmit={handlePatientSubmit}
                patients={patients}
                error={error}
                loading={loading}
                clinicName={clinicInfo.name}
                clinicLogo={clinicInfo.logo}
              />{' '}
            </Transition>
          ) : currentScreen === KioskScreens.AreYouNew ? (
            <Transition
              show={show}
              enter="transition ease-out duration-300"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-300"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <div className="flex min-h-screen mx-auto justify-center flex-col p-4">
                <div className="flex items-start mx-auto justify-center w-full max-w-4xl flex-col p-4">
                  <div className="pb-4">
                    <SurveyQuestionTitle
                      title={`Are you a new or established patient?`}
                    />
                  </div>
                  <SurveyQuestionRadio
                    id="areYouNew"
                    options={[
                      {
                        display: `New patient`,
                        id: 'newPatient',
                      },
                      {
                        display: `Established patient`,
                        id: 'establishedPatient',
                      },
                    ]}
                    onSelect={onSelectAreYouNew}
                    disabled={false}
                    includeOther={false}
                    hideNext={true}
                    forceAnswer={true}
                    primaryColor={clinicInfo?.primaryColor}
                  />
                </div>
              </div>{' '}
            </Transition>
          ) : currentScreen === KioskScreens.NewPatient ? (
            <Transition
              show={show}
              enter="transition ease-out duration-300"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-300"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <div className="flex min-h-screen mx-auto justify-center flex-col p-4">
                <div className="flex items-start mx-auto justify-center w-full max-w-4xl flex-col p-4">
                  <div className="pb-4">
                    <SurveyQuestionTitle
                      title={`Have you recently completed an intake questionnaire from our office?`}
                    />
                  </div>
                  <SurveyQuestionRadio
                    id="recentlyCompleted"
                    options={[
                      {
                        display: `Yes`,
                        id: 'yes',
                      },
                      {
                        display: `No`,
                        id: 'no',
                      },
                    ]}
                    onSelect={onSelectRecentlyCompleted}
                    disabled={false}
                    includeOther={false}
                    hideNext={true}
                    forceAnswer={true}
                    primaryColor={clinicInfo?.primaryColor}
                  />
                </div>
              </div>{' '}
            </Transition>
          ) : currentScreen === KioskScreens.RecentlyCompletedYes ? (
            <Transition
              show={show}
              enter="transition ease-out duration-300"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-300"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <KioskSimpleDemographicForm
                handlePatientSubmit={handlePatientSubmit}
                changeScreens={changeScreens}
                phone={phone}
              />{' '}
            </Transition>
          ) : currentScreen === KioskScreens.RecentlyCompletedNo ? (
            <Transition
              show={show}
              enter="transition ease-out duration-300"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-300"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <KioskSimpleDemographicForm
                handlePatientSubmit={handlePatientSubmit}
                changeScreens={changeScreens}
                phone={phone}
              />{' '}
            </Transition>
          ) : currentScreen === KioskScreens.EstablishedPatient ? (
            <Transition
              show={show}
              enter="transition ease-out duration-300"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-300"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <KioskSimpleDemographicForm
                handlePatientSubmit={handlePatientSubmit}
                changeScreens={changeScreens}
                phone={phone}
              />{' '}
            </Transition>
          ) : currentScreen === KioskScreens.NeedAssistancePending ? (
            <Transition
              show={show}
              enter="transition ease-out duration-300"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-300"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <KioskNeedAssistancePending
                logout={() => {
                  setPhone('');
                  changeScreens(KioskScreens.EnterPhone);
                }}
              />{' '}
            </Transition>
          ) : currentScreen === KioskScreens.EnterMethod && methods ? (
            <Transition
              show={show}
              enter="transition ease-out duration-300"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-300"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <RequestMethodSelect
                methods={methods}
                loading={loading}
                onSubmitMethod={submitForwardRequest}
                kiosk
              />{' '}
            </Transition>
          ) : currentScreen === KioskScreens.ForwardRequest ? (
            <Transition
              show={show}
              enter="transition ease-out duration-300"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-300"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <KioskForwardRequest
                method={selectedMethod}
                methodValue={forwardMessage}
                language={request.language}
              />
            </Transition>
          ) : currentScreen === KioskScreens.Complete ? (
            <Transition
              show={show}
              enter="transition ease-out duration-300"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-300"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <KioskComplete
                logout={() => {
                  setPhone('');
                  changeScreens(KioskScreens.EnterPhone);
                }}
              />{' '}
            </Transition>
          ) : (
            <div>Invalid state</div>
          )}
        </div>
      </KioskInactivityModal>
    </MeContext.Provider>
  );
};

export default KioskLoggedIn;
