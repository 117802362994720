import { Transition } from '@headlessui/react';
import React, { useContext } from 'react';
import { MeContext } from '../../contexts/me.context';

type Props = {
  title: string;
  subtitle?: string;
  message: string;
  buttons?: React.ReactNode;
  show: boolean;
};

const KioskAssistance: React.FC<Props> = ({
  title,
  subtitle,
  message,
  buttons,
  show,
}) => {
  const {
    clinicInfo: { primaryColor },
  } = useContext(MeContext);

  return (
    <Transition
      show={show}
      enter="transition ease-out duration-300"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-300"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      {(nodeRef) => (
        <div
          ref={nodeRef}
          className="sm:min-h-screen flex items-center justify-center px-4 w-full"
        >
          <div className="max-w-5xl mx-auto">
            <div className="text-center">
              <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                {title}
                <br />
                <span
                  style={{
                    color: primaryColor,
                  }}
                >
                  {subtitle}
                </span>
              </h2>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                {message}
              </p>
              <span className="mt-8 inline-flex">
                {buttons}
                {/* <button
                  type="button"
                  className="inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white hover:opacity-90 focus:outline-none transition ease-in-out duration-150"
                  style={{
                    backgroundColor: primaryColor,
                  }}
                  onClick={() => nextStep()}
                >
                  {verbiage[language === 'es' ? 'es' : 'en'].next}
                </button> */}
              </span>
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
};

export default KioskAssistance;
