import { Any } from '@chiroup/core';
import { Listbox, Transition } from '@headlessui/react';
import React from 'react';

type Props = {
  options: Any[];
  value: number | string;
  onChange: (val: Any) => void;
  suffix?: string;
  className?: string;
  primaryColor: string;
};

export const InlineListBox: React.FC<Props> = ({
  options,
  value,
  onChange,
  suffix,
  className = '',
  primaryColor,
}) => {
  const onChangeVal = (e: Any) => {
    onChange(e);
  };

  return (
    <Listbox
      as="span"
      value={value}
      onChange={onChangeVal}
      className={className}
    >
      {({ open }) => (
        <Listbox.Button
          className="relative cursor-pointer font-extrabold hover:opacity-90 border-dashed border-b-4 hover:border-opacity-90 focus:outline-none transition ease-in-out duration-150"
          style={{
            color: primaryColor,
            borderColor: primaryColor,
          }}
        >
          <span>
            {value}
            {suffix}
          </span>

          <Transition
            show={open}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="absolute right-0 mt-1 w-full rounded-md bg-white shadow-lg"
          >
            <Listbox.Options
              static
              className="max-h-60 rounded-md py-1 text-base leading-6 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm sm:leading-5 min-w-min bg-white"
            >
              {options.map((option) => (
                <Listbox.Option key={option} value={option}>
                  {({ selected, active }) => (
                    <div
                      className={`${
                        active ? 'text-white' : 'text-gray-900'
                      } select-none relative py-2 pl-8 pr-4 cursor-pointer`}
                      style={active ? { backgroundColor: primaryColor } : {}}
                    >
                      <span
                        className={`${
                          selected ? 'font-semibold' : 'font-normal'
                        } block truncate`}
                      >
                        {option}
                      </span>
                      {selected && (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-1.5`}
                          style={
                            active
                              ? { color: '#ffffff' }
                              : { color: primaryColor }
                          }
                        >
                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      )}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </Listbox.Button>
      )}
    </Listbox>
  );
};
