import { SurveyQuestionTitle } from '@chiroup/components';
import {
  CommunicationMethodTypes,
  formatPhone,
  Genders,
  Patient,
  RequestStep,
} from '@chiroup/core';
import { Transition } from '@headlessui/react';
import { useState } from 'react';
import { MeContext } from '../../contexts/me.context';
import { KioskScreens } from '../../helpers/constants';
import kioskService from '../../services/kiosk.service';
import Button, { ButtonColors } from '../common/Button';
import Request from '../request/Request';
import KioskEnterPhone from './KioskEnterPhone';
import KioskInactivityModal from './KioskInactivityModal';
import { signOut } from '@aws-amplify/auth';

type Props = {
  clinicInfo: {
    name: string;
    logo: string;
    primaryColor: string;
    accentColor: string;
    flags: string[];
  };
};

const KioskLoggedInLegacy: React.FC<Props> = ({ clinicInfo }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [request, setRequest] = useState<any>();
  const [phone, setPhone] = useState<string>();
  const [patients, setPatients] = useState<Partial<Patient>[]>([]);
  const [noSurveyMessage, setNoSurveyMessage] = useState<string | null>(null);
  const [currentScreen, setCurrentScreen] = useState(KioskScreens.EnterPhone);
  const [show, setShow] = useState(true);
  const [methods, setMethods] = useState<{
    email: string;
    phone: string;
    language: string;
  }>({ email: '', phone: '', language: '' });

  const handlePhoneSubmit = async (phone: string) => {
    if (!phone) {
      return;
    }
    setPhone(phone);
    await fetchData({ phone });
  };

  const handlePatientSubmit = async (patientId: string) => {
    if (!patientId) {
      return;
    }
    return await fetchData({ patientId });
  };

  const handleReset = (changeScreen = false) => {
    setError('');
    setPhone('');
    setPatients([]);
    setNoSurveyMessage(null);
    setRequest(null);
    if (changeScreen) {
      setCurrentScreen(KioskScreens.EnterPhone);
    }
  };

  const updateRequest = (
    data: {
      prMap: { [key: string]: string };
      steps: RequestStep[];
      language: 'en' | 'es';
      gender: Genders;
      age: number;
      id: string;
    },
    callback?: () => void,
  ) => {
    const values = Object.values(data?.prMap);
    setRequest({ ...data, id: values?.[0] });
    if (callback) {
      setTimeout(() => {
        callback();
      }, 1000);
    }
  };

  const fetchData = async (request: { phone?: string; patientId?: string }) => {
    setError('');
    setLoading(true);
    setNoSurveyMessage(null);
    try {
      if (request.phone) {
        const res = await kioskService
          .getRequest(request)
          .then((res) => res.patients);
        if (res?.length) {
          if (res?.length > 1) {
            setPatients(res);
          } else {
            await handlePatientSubmit(res[0].ID);
          }
        } else {
          setError('Invalid Phone Number');
        }
      } else if (request.patientId) {
        const res = await kioskService.getRequest(request);
        if (res.pateintId) {
          setRequest(res);
          setLoading(false);
          return true;
        }

        if (res.methods) {
          setMethods(res.methods.data);
        }
        if (res.steps) {
          const values = Object.values(res.prMap);
          setRequest({ ...res, id: values?.[0] });
          changeScreens(KioskScreens.Request);
          setLoading(false);
          return false;
        } else if (res.noSurvey) {
          setNoSurveyMessage(res.noSurvey);
        } else {
          setError('Something went wrong');
        }
      }
      setLoading(false);
      return true;
    } catch (err: any) {
      console.error('error???', err);
      setLoading(false);
      let errMessage =
        err?.response?.data?.message ||
        'No patient records found with this number. Please see receptionist.';
      if (errMessage === 'PATIENT_NOT_FOUND') {
        changeScreens(KioskScreens.PhoneNotFound);
      } else {
        changeScreens(KioskScreens.EnterPhone);
        if (err?.response?.status === 401) {
          errMessage += ' Redirecting in 5 seconds.';
          setTimeout(() => {
            signOut();
            window.location.reload();
          }, 5000);
        }
        setError(errMessage);
      }
      return true;
    }
  };

  const changeScreens = (newScreen: KioskScreens, logoutAfter?: number) => {
    setShow(false);
    setTimeout(async () => {
      setCurrentScreen(newScreen);
      setShow(true);
    }, 500);

    if (logoutAfter) {
      setTimeout(() => {
        handleReset(true);
      }, logoutAfter);
    }
  };

  const forwardMethod = async () => {
    let res;
    if (methods?.email && methods?.phone) {
      changeScreens(KioskScreens.EnterMethod);
      return;
    } else if (methods?.email) {
      setLoading(true);
      res = await kioskService.forwardRequest(
        request.id,
        CommunicationMethodTypes.email,
        methods.email,
      );
    } else if (methods?.phone) {
      setLoading(true);
      res = await kioskService.forwardRequest(
        request.id,
        CommunicationMethodTypes.text,
        methods.phone,
      );
    }
    if (res.message) {
      changeScreens(KioskScreens.ForwardRequest);
    } else {
      setError('Failed to forward request');
    }
    setLoading(false);
  };

  return (
    <MeContext.Provider
      value={{
        clinicInfo,
      }}
    >
      <KioskInactivityModal
        onInactive={() => handleReset(true)}
        activatePrompt={!!(phone || request)}
      >
        <Transition
          show={show}
          enter="transition ease-out duration-300"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-300"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div>
            {currentScreen === KioskScreens.Request ? (
              <Request
                data={request}
                id={request?.id}
                kiosk
                updateRequest={updateRequest}
                validationCode={request?.validationCode}
                guardianName={request?.guardianName}
                patientName={request?.patientName}
                handleReset={handleReset}
                forwardMethod={forwardMethod}
                methods={methods}
                loading={loading}
                hideButtons
                encounterId={request?.encounterId}
              />
            ) : currentScreen === KioskScreens.PhoneNotFound ? (
              <div className="flex min-h-screen mx-auto justify-center flex-col p-4">
                <div className="flex items-start mx-auto justify-center w-full max-w-4xl flex-col p-4">
                  <div className="pb-4">
                    <SurveyQuestionTitle
                      title={`Our system does not recognize the phone number ${formatPhone(
                        phone,
                        true,
                      )}. Please see the front desk for assistance.`}
                    />
                  </div>
                  <div className="mx-auto">
                    <Button
                      text={'Logout'}
                      color={ButtonColors.plain}
                      className="mt-4"
                      type="button"
                      onClick={() => {
                        setCurrentScreen(KioskScreens.EnterPhone);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : currentScreen === KioskScreens.EnterPhone ? (
              <KioskEnterPhone
                phone={phone}
                setPhone={setPhone}
                handleReset={handleReset}
                noSurveyMessage={noSurveyMessage}
                handlePhoneSubmit={handlePhoneSubmit}
                handlePatientSubmit={handlePatientSubmit}
                patients={patients}
                error={error}
                loading={loading}
                clinicName={clinicInfo.name}
                clinicLogo={clinicInfo.logo}
              />
            ) : (
              <div>Invalid state</div>
            )}
          </div>
        </Transition>
      </KioskInactivityModal>
    </MeContext.Provider>
  );
};

export default KioskLoggedInLegacy;
