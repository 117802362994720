import {
  CarePlanComplaint,
  Genders,
  RequestStep,
  SurveyProgress,
  SurveyQuestion,
  SurveyQuestionAnswer,
  SurveyIds,
} from '@chiroup/core';
import React, { useContext, useEffect, useState } from 'react';
import requestService from '../../../services/request.service';
import InterCCS from '../../body/InterCCS';
import { MeContext } from '../../../contexts/me.context';
import {
  SurveyComplete,
  SurveyIntro,
  SurveyQuestionComponent,
} from '@chiroup/components';
import InterReeval from '../../reeval/InterReeval';

enum SurveyStatus {
  intro = 'intro',
  question = 'question',
  complete = 'complete',
}

type Props = {
  age: number;
  ccsFollowups?: string[];
  complaints?: Partial<CarePlanComplaint>;
  complete: (goToNext?: boolean) => Promise<void>;
  endMessage: string;
  gender: Genders;
  kiosk: boolean;
  language: 'en' | 'es';
  nextStep: (id?: string) => void;
  prMap: { [key: string]: string };
  previousStartQuestion?: string;
  remainingSteps: number[];
  requestId: string;
  startMessage: string;
  startProgress?: SurveyProgress;
  startQuestion?: SurveyQuestion;
  started: boolean;
  subtitle: string;
  surveyId: string;
  surveyIds?: string[];
  title: string;
  updateRequest?: (
    data: {
      prMap: { [key: string]: string };
      steps: RequestStep[];
      language: 'en' | 'es';
      gender: Genders;
      age: number;
      id: string;
    },
    callback?: () => void,
  ) => void;
  validationCode: string | number;
  weeks?: number;
  encounterId: string;
  surveyType: SurveyIds;
};

const RequestSurvey: React.FC<Props> = ({
  requestId,
  surveyId,
  complete,
  title,
  subtitle,
  startMessage,
  endMessage,
  startQuestion,
  previousStartQuestion,
  started,
  startProgress,
  language = 'en',
  gender,
  age,
  nextStep,
  ccsFollowups,
  updateRequest,
  surveyIds,
  validationCode,
  prMap,
  complaints,
  weeks,
  remainingSteps,
  kiosk,
  encounterId,
  surveyType,
}) => {
  const {
    clinicInfo: { primaryColor },
  } = useContext(MeContext);
  const [hideSteps, setHideSteps] = useState(false);
  const [surveyStatus, setSurveyStatus] = useState<SurveyStatus>(
    started ? SurveyStatus.question : SurveyStatus.intro,
  );
  const [disabled, setDisabled] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(startQuestion);
  const [previousQuestion, setPreviousQuestion] = useState(
    previousStartQuestion,
  );
  const [progress, setProgress] = useState(startProgress);

  useEffect(() => {
    setProgress({
      completed: 0,
      total: 1,
    });
  }, [surveyId]);

  const nextStepIntermediary = () => {
    setSurveyStatus(SurveyStatus.intro);
    nextStep(surveyId);
  };

  const markSurveyComplete = () => {
    setSurveyStatus(SurveyStatus.complete);
  };

  useEffect(() => {
    setCurrentQuestion(startQuestion);
  }, [startQuestion]);

  const start = () => {
    setHideSteps(true);
    setSurveyStatus(SurveyStatus.question);
    setTimeout(() => {
      setHideSteps(false);
    }, 333);
  };

  const onSelect = async (val: SurveyQuestionAnswer) => {
    if (!currentQuestion?.id) {
      return;
    }
    try {
      setDisabled(true);
      setHideSteps(true);
      setTimeout(async () => {
        const { question: nextQuestion, progress: progressRes } =
          await requestService.submitSurveyAnswer(
            requestId,
            surveyId,
            currentQuestion.id,
            val,
            validationCode,
            prMap,
            remainingSteps,
            kiosk,
            encounterId,
          );
        goToQuestion(nextQuestion, currentQuestion.id, progressRes);
      }, 333);
    } catch (err) {
      console.error(err);
      setHideSteps(false);
    }
    setDisabled(false);
  };

  const goToQuestion = async (
    nextQuestion: SurveyQuestion,
    previousQuestionId?: string,
    progressRes?: SurveyProgress,
  ) => {
    if (nextQuestion) {
      setCurrentQuestion(nextQuestion);
    } else {
      await complete();
      setSurveyStatus(SurveyStatus.complete);
    }
    setPreviousQuestion(previousQuestionId);
    setProgress(progressRes);
    setHideSteps(false);
  };

  const goBack = async () => {
    if (!previousQuestion) {
      return;
    }
    try {
      const {
        question,
        previousQuestion: previousQuestionId,
        progress: progressRes,
      } = await requestService.getQuestion(
        requestId,
        surveyId,
        previousQuestion,
      );
      goToQuestion(question, previousQuestionId, progressRes);
    } catch (err) {
      console.error(err);
      setHideSteps(false);
    }
  };

  return (
    <div className="flex items-center justify-center w-full min-h-screen bg-gray-100 ">
      <SurveyIntro
        show={surveyStatus === SurveyStatus.intro}
        title={title}
        subtitle={subtitle}
        startMessage={startMessage}
        start={start}
        language={language}
        primaryColor={primaryColor}
      />
      {surveyStatus === SurveyStatus.question &&
        currentQuestion &&
        (surveyType === 'ccs' ||
        surveyType === 'additionalComplaintSurvey' ||
        surveyType === 'abbreviatedCCS' ? (
          <InterCCS
            startQuestion={currentQuestion}
            id={requestId}
            surveyId={surveyId}
            language={language}
            gender={gender}
            age={age}
            complete={complete}
            ccsFollowups={ccsFollowups}
            updateRequest={updateRequest}
            surveyIds={surveyIds}
            validationCode={validationCode}
            prMap={prMap}
            markSurveyComplete={markSurveyComplete}
            kiosk={kiosk}
            encounterId={encounterId}
            additionalComplaints={surveyType === 'additionalComplaintSurvey'}
            abbreviatedCCS={surveyType === 'abbreviatedCCS'}
          />
        ) : surveyType === 'reevaluation' ? (
          <InterReeval
            id={requestId}
            surveyId={surveyId}
            language={language}
            complete={complete}
            ccsFollowups={ccsFollowups}
            updateRequest={updateRequest}
            surveyIds={surveyIds}
            validationCode={validationCode}
            prMap={prMap}
            markSurveyComplete={markSurveyComplete}
            complaints={complaints}
            startQuestion={currentQuestion}
            weeks={weeks}
            age={age}
            gender={gender}
            kiosk={kiosk}
            encounterId={encounterId}
          />
        ) : (
          <SurveyQuestionComponent
            show={!hideSteps && surveyStatus === SurveyStatus.question}
            question={currentQuestion.question}
            type={currentQuestion.type}
            typeOptions={currentQuestion.typeOptions}
            options={currentQuestion.options}
            id={currentQuestion.id}
            onSelect={onSelect}
            disabled={disabled}
            images={currentQuestion.images}
            showPrevious={!!previousQuestion}
            goBack={goBack}
            progress={progress}
            language={language}
            primaryColor={primaryColor}
          />
        ))}
      {surveyStatus === SurveyStatus.complete && (
        <SurveyComplete
          show={surveyStatus === SurveyStatus.complete}
          message={endMessage}
          language={language}
          nextStep={nextStepIntermediary}
          primaryColor={primaryColor}
        />
      )}
    </div>
  );
};

export default RequestSurvey;
