import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import App from './App';
import './styles/index.css';
import { Amplify, ResourcesConfig } from 'aws-amplify';
import { fetchAuthSession } from '@aws-amplify/auth';

if (import.meta.env.VITE_STAGE !== 'local') {
  Sentry.init({
    dsn: 'https://4e31ca6b060ff97a5d318de5b6ad29a8@o4506354335612928.ingest.sentry.io/4506356700741632',
    // Add tracePropagationTargets may break the app - need to allow baggage header
    // tracePropagationTargets: [VITE_HEALTHCOM_API],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],
    environment: import.meta.env.VITE_STAGE,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const authConfig: ResourcesConfig['Auth'] = {
  Cognito: {
    // allowGuestAccess: true,
    userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID as string,
    userPoolClientId: import.meta.env.VITE_COGNITO_APP_CLIENT_ID as string,
    identityPoolId: import.meta.env.VITE_IDENTITY_POOL_ID as string,
  },
};

Amplify.configure(
  {
    Auth: authConfig,
    API: {
      REST: {
        api: {
          endpoint: import.meta.env.VITE_HEALTHCOM_API as string,
        },
        'api-noauth': {
          endpoint: import.meta.env.VITE_HEALTHCOM_API as string,
        },
      },
    },
  },
  {
    API: {
      REST: {
        headers: async (...args) => {
          // Only do this for api endpoint
          const authToken = (
            await fetchAuthSession()
          ).tokens?.idToken?.toString();
          return {
            Authorization: `Bearer ${authToken}`,
          };
        },
      },
    },
  },
);

// Amplify.addPluggable(
//   new AWSIoTProvider({
//     // eslint-disable-next-line
//     aws_pubsub_region: 'us-east-1',
//     // eslint-disable-next-line
//     aws_pubsub_endpoint:
//       'wss://a184k4bey93r69-ats.iot.us-east-1.amazonaws.com/mqtt',
//   })
// );

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(
  // <StrictMode>
  <App />,
  // </StrictMode>
);
