import { Input } from '@chiroup/components';
import { SurveyOption, SurveyQuestionAnswer } from '@chiroup/core';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { questionContentVerbiage } from './SurveyQuestionContent';

type Props = {
  id: string;
  options?: SurveyOption[];
  onSelect: (val: SurveyQuestionAnswer) => Promise<void>;
  disabled: boolean;
  includeOther?: boolean;
  hideNext: boolean;
  language?: 'en' | 'es';
  forceAnswer?: boolean;
  primaryColor: string;
};

export const SurveyQuestionRadio: React.FC<Props> = ({
  id,
  options,
  onSelect,
  disabled,
  includeOther,
  hideNext = false,
  language = 'en',
  forceAnswer = false,
  primaryColor,
}) => {
  const [showOther, setShowOther] = useState(false);
  const [otherValue, setOtherValue] = useState<string>();
  const otherRef = useRef<HTMLInputElement>(null);

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const isOther = val === 'other';
    setShowOther(isOther);
    if (!isOther) {
      await onSelect({ type: 'radio', value: val });
      setOtherValue(undefined);
    }
  };

  useEffect(() => {
    if (showOther) {
      otherRef.current?.focus();
    } else {
      setOtherValue(undefined);
    }
  }, [showOther]);

  const onChangeOther = async (val: string) => {
    setOtherValue(val);
    if (hideNext) {
      await onSelect({ type: 'radio', value: 'other', otherValue: val });
      setOtherValue(undefined);
    }
  };

  const handleSubmit = async () => {
    await onSelect({ type: 'radio', value: 'other', otherValue });
    setOtherValue(undefined);
  };

  return (
    <>
      {options?.map((option, optionIndex: number) => (
        <div
          key={optionIndex}
          className={['flex items-center', optionIndex ? 'mt-4' : ''].join(' ')}
        >
          <input
            id={`question-${id}-option-${optionIndex}`}
            name={`question-${id}`}
            type="radio"
            className="form-radio h-10 w-10 transition duration-150 ease-in-out focus:ring-0"
            style={{
              color: primaryColor,
            }}
            onChange={onChange}
            value={option.id}
            disabled={disabled}
            data-cy={option.display}
          />
          <label
            htmlFor={`question-${id}-option-${optionIndex}`}
            className="ml-3"
          >
            <span className="block leading-5">{option.display}</span>
          </label>
        </div>
      ))}
      {includeOther && (
        <div className="flex flex-col mt-4">
          <div className="flex items-center">
            <input
              id={`question-${id}-option-other`}
              name={`question-${id}`}
              type="radio"
              className="form-radio h-10 w-10 transition duration-150 ease-in-out focus:ring-0"
              style={{
                color: primaryColor,
              }}
              onChange={onChange}
              value="other"
              disabled={disabled}
            />
            {showOther ? (
              <Input
                name="other"
                className="ml-3 w-full md:w-96"
                value={otherValue}
                onChange={onChangeOther}
                disabled={disabled}
                ref={otherRef}
              />
            ) : (
              <label htmlFor={`question-${id}-option-other`} className="ml-3">
                <span className="block leading-5">Other</span>
              </label>
            )}
          </div>
          {showOther && !hideNext && (
            <div>
              <button
                onClick={handleSubmit}
                disabled={disabled}
                className="cursor-pointer rounded-md shadow items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white hover:opacity-90 focus:outline-none focus:ring transition duration-150 ease-in-out mt-8"
                style={{
                  backgroundColor: primaryColor,
                }}
                data-cy="next-button"
              >
                {questionContentVerbiage[language === 'es' ? 'es' : 'en'].next}
              </button>
            </div>
          )}
          {!showOther && !hideNext && !forceAnswer && (
            <div>
              <button
                onClick={handleSubmit}
                disabled={disabled}
                className="cursor-pointer rounded-md shadow items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white hover:opacity-90 focus:outline-none focus:ring transition duration-150 ease-in-out mt-8"
                style={{
                  backgroundColor: primaryColor,
                }}
                data-cy="none-of-the-above-button"
              >
                {
                  questionContentVerbiage[language === 'es' ? 'es' : 'en']
                    .noneOfTheAbove
                }
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
