import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MeContext } from '../../contexts/me.context';

const verbiage = {
  en: {
    emailSent: 'Email sent!',
    textSent: 'Text message sent!',
    email: 'The information you requested was sent to',
    text: 'The information you requested was texted to',
    // kioskQuestionsOrConcerns:
    // 'Please let us know if you have any questions or concerns.',
    thankYou: 'Thank you for your time.',
    // logout: 'Logout',
  },
  es: {
    emailSent: '¡Email enviado!',
    textSent: '¡Mensaje de texto enviado!',
    email: 'La información que solicitaste fue enviada a',
    text: 'La información que solicitó fue enviada por mensaje de texto a',
    // kioskQuestionsOrConcerns:
    //   'Háganos saber si tiene alguna pregunta o inquietud.',
    thankYou: 'Gracias por tu tiempo.',
    // logout: 'Cerrar sesión',
  },
};

type Props = {
  language: 'en' | 'es';
  method: string;
  methodValue: string;
};

const KioskForwardRequest: React.FC<Props> = ({
  language = 'en',
  method,
  methodValue,
}) => {
  const {
    clinicInfo: { primaryColor },
  } = useContext(MeContext);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/kiosk');
      window.location.reload();
    }, 5000);
  }, [navigate]);

  return (
    <div className="w-full justify-center">
      <div className="sm:min-h-screen flex items-center justify-center px-4">
        <div className="max-w-4xl py-8">
          <h2 className="text-4xl tracking-tight leading-8 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
            {method === 'email'
              ? verbiage[language === 'es' ? 'es' : 'en'].emailSent
              : verbiage[language === 'es' ? 'es' : 'en'].textSent}
          </h2>
          <h3 className="text-xl tracking-tight leading-6 font-extrabold text-gray-900 sm:text-2xl sm:leading-none md:text-4xl mt-2">
            <span
              style={{
                color: primaryColor,
              }}
            >
              {verbiage[language === 'es' ? 'es' : 'en'].thankYou}
            </span>
          </h3>
          <p className="mt-8">
            {method === 'email'
              ? `${
                  verbiage[language === 'es' ? 'es' : 'en'].email
                } ${methodValue}`
              : `${
                  verbiage[language === 'es' ? 'es' : 'en'].text
                } ${methodValue}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default KioskForwardRequest;
