import { Input, SurveyQuestionTitle } from '@chiroup/components';
import React, { useState } from 'react';
import Button, { ButtonColors } from '../common/Button';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

type Props = {
  authResponse: string;
  onSubmitCode: (code: number) => void;
  onHandleNewCode: () => void;
  loading: boolean;
  language?: string;
  timeLeft?: number;
  invalidCode?: boolean;
};

const RequestCodeInput: React.FC<Props> = ({
  authResponse,
  onSubmitCode,
  loading,
  onHandleNewCode,
  language = 'en',
  timeLeft,
  invalidCode = false,
}) => {
  dayjs.extend(duration);
  const [val, setVal] = useState<string>('');
  const onChange = (code: string) => {
    setVal(code);
  };

  const submitCode = () => {
    onSubmitCode(+val);
  };

  const formatTime = (time: number = 0, spanish = false) => {
    const duration = dayjs.duration(time, 'seconds');
    const minutes = String(duration.minutes()).padStart(1, '0');
    const seconds = String(duration.seconds()).padStart(2, '0');
    const secondsText = spanish ? ' segundos' : ' seconds';
    const minutesText = spanish ? ' minutos' : ' minutes';
    return `${minutes}:${seconds}${+minutes > 0 ? minutesText : secondsText}`;
  };

  const spanish = language === 'es';
  return (
    <div className="min-h-screen flex items-center mx-auto justify-center w-full max-w-xl flex-col p-4">
      <p className="pb-4 font-medium w-full text-center">{authResponse}</p>
      <SurveyQuestionTitle
        title={spanish ? 'Ingrese su código' : 'Enter your code'}
      />
      <div>
        {timeLeft ? (
          <p className="text-sm text-center italic">{`${
            spanish ? 'El código caduca en' : 'Code expires in'
          } ${formatTime(timeLeft, spanish)}`}</p>
        ) : (
          <p className="text-sm text-center italic">
            {spanish ? 'Código expirado' : 'Code expired'}
            <span>
              <button
                onClick={onHandleNewCode}
                className="pl-2 text-small font-normal text-indigo-600 hover:text-indigo-400"
              >
                {spanish ? 'Enviar código nuevamente.' : `Send again`}
              </button>
            </span>
          </p>
        )}
        {invalidCode && (
          <p className="italic text-sm text-red-500 text-center">
            {spanish
              ? 'Usted ha introducido un código inválido. Se ha enviado un nuevo código.'
              : 'You have entered an invalid code. A new code has been sent.'}
          </p>
        )}
      </div>
      <Input
        name={`requestCode`}
        inputClassName="bg-transparent border-none focus:ring-opacity-0 text-center text-8xl sm:text-12xl"
        value={val}
        onChange={onChange}
        placeholder="00000"
        autoFocus
        maxLength={5}
        autoComplete="one-time-code"
        disabled={loading}
      />
      <div className="italic text-small font-light pt-2">
        <p>
          {spanish ? 'No recibiste un código?' : `Didn't get a code?`}
          <span>
            <button
              onClick={onHandleNewCode}
              className="pl-2 text-small font-normal text-indigo-600 hover:text-indigo-400"
            >
              {spanish ? 'Enviar código nuevamente.' : `Send again`}
            </button>
          </span>
        </p>
      </div>
      <div>
        <Button
          text={spanish ? 'Enviar' : 'Submit'}
          color={ButtonColors.primary}
          className="mt-4"
          type="button"
          disabled={!val || !(String(val).length === 5)}
          onClick={submitCode}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default RequestCodeInput;
