import {
  BodyParts,
  CCSBodyPickerQuestionIds,
  CCSPrimaryQuestionIds,
  CCSSecondaryQuestionIds,
  Genders,
  RequestStep,
  SourceOfPain,
  surveyIds as theSurveyIds,
  SurveyQuestion,
  SurveyQuestionIfType,
  abbreviatedCCSQuestionsIds,
} from '@chiroup/core';
import {
  createChiefComplaintQuestions,
  createAbbreviatedChiefComplaintQuestions,
} from './ccsQuestions';

export const ccsBodyPickerQuestion = {
  id: 'e89903d4-5f69-4c64-a3fc-7827c9179514',
  createdAt: 1609451279109,
  question:
    'Please click on the body to select the parts that are causing you pain.',
  type: 'bodypicker',
  updatedAt: 1609451279109,
};

export const ccsBodyPickerQuestionSpanish = {
  id: 'e89903d4-5f69-4c64-a3fc-7827c9179514',
  createdAt: 1609451279109,
  question:
    'En la siguiente pantalla, haga clic en el cuerpo para seleccionar las partes que le causan dolor.',
  type: 'bodypicker',
  updatedAt: 1609451279109,
};

const addChiefComplaintQuestions = (
  parts: BodyParts,
  language: string,
  currentQuestion: SurveyQuestion,
  questions: SurveyQuestion[],
  spanishQuestions: SurveyQuestion[],
  setCurrentQuestion: (val: SurveyQuestion) => void,
  setQuestions: (val: SurveyQuestion[]) => void,
  setSpanishQuestions: (val: SurveyQuestion[]) => void,
  isProvider = false,
  additionalComplaint = false,
  aaWI?: string,
  startingPoblemArea = 0,
  abbreviatedCCS = false,
) => {
  const currentQuestionId = currentQuestion?.id;
  const lastQuestionOrder = questions?.length - 1;
  const newEnglishQuestions = abbreviatedCCS
    ? questions.concat(createAbbreviatedChiefComplaintQuestions(parts, 'en'))
    : questions.concat(
        createChiefComplaintQuestions(
          parts,
          lastQuestionOrder,
          currentQuestionId === CCSBodyPickerQuestionIds.primary
            ? CCSPrimaryQuestionIds
            : CCSSecondaryQuestionIds,
          'en',
          currentQuestionId === CCSBodyPickerQuestionIds.primary ? 1 : 2,
          isProvider,
          aaWI,
          startingPoblemArea,
          additionalComplaint,
        ),
      );

  const newSpanishQuestions =
    spanishQuestions && abbreviatedCCS
      ? spanishQuestions.concat(
          createAbbreviatedChiefComplaintQuestions(parts, 'es'),
        )
      : spanishQuestions
        ? spanishQuestions.concat(
            createChiefComplaintQuestions(
              parts,
              lastQuestionOrder,
              currentQuestionId === CCSBodyPickerQuestionIds.primary
                ? CCSPrimaryQuestionIds
                : CCSSecondaryQuestionIds,
              'es',
              currentQuestionId === CCSBodyPickerQuestionIds.primary ? 1 : 2,
              isProvider,
              aaWI,
              startingPoblemArea,
              additionalComplaint,
            ),
          )
        : [];

  const nextQuestionIndex = getNextQuestionIndex({
    questions: newEnglishQuestions,
  });
  const nextQuestion = ((language === 'es'
    ? newSpanishQuestions
    : newEnglishQuestions) || [])?.[nextQuestionIndex];
  setCurrentQuestion(nextQuestion);
  setQuestions(newEnglishQuestions || []);
  if (setSpanishQuestions && language === 'es') {
    setSpanishQuestions(newSpanishQuestions || []);
  }
};

export const submitStuff = async ({
  questions,
  kiosk,
  spanishQuestions,
  setIsSubmitting,
  isSubmitting,
  submitSurvey,
  complete,
  markSurveyComplete,
  setSubmitText,
  submitVerbiage,
  validationCode,
  language = 'en',
  surveyIds,
  ccsFollowups,
  updateRequest,
  complaints,
}: {
  questions: SurveyQuestion[];
  kiosk: boolean;
  spanishQuestions: SurveyQuestion[];
  setIsSubmitting: (val: boolean) => void;
  isSubmitting: boolean;
  submitSurvey: (
    questions: SurveyQuestion[],
    kiosk: boolean,
    spanishQuestions?: SurveyQuestion[],
    ccsFollowupSurveyIds?: string[],
    validationCode?: string | number,
  ) => Promise<any>;
  complete: () => void;
  markSurveyComplete: () => void;
  setSubmitText: (val: string) => void;
  submitVerbiage: {
    more: { en: string; es: string };
    done: { en: string; es: string };
  };
  validationCode: string | number;
  language?: string;
  surveyIds?: string[];
  ccsFollowups?: string[];
  updateRequest?: (
    data: {
      prMap: { [p: string]: string };
      steps: RequestStep[];
      language: 'en' | 'es';
      gender: Genders;
      age: number;
      id: string;
    },
    callback?: () => void,
  ) => void;
  complaints?: any[];
}) => {
  if (isSubmitting) {
    return;
  }
  setIsSubmitting(true);

  const bodyPickerQuestions = questions.filter(
    (question) =>
      (question.id === CCSBodyPickerQuestionIds.primary ||
        question.id === CCSBodyPickerQuestionIds.secondary) &&
      question.answer?.value?.parts,
  );

  const bodyPartsPicked = bodyPickerQuestions.reduce(
    (obj: { [key: string]: boolean }, question) => {
      Object.keys(question.answer?.value?.parts).forEach(
        (frontOrBack: string) => {
          Object.keys(question.answer?.value?.parts[frontOrBack])?.forEach(
            (part: string) => {
              if (
                question.answer?.value?.parts?.[frontOrBack][part].right ||
                question.answer?.value?.parts?.[frontOrBack][part].left
              ) {
                obj[part] = true;
              }
            },
          );
        },
      );
      return obj;
    },
    {},
  );

  const followUpSurveyIdsRaw: string[] = !complaints
    ? getCCSFollowups(bodyPickerQuestions, bodyPartsPicked)
    : bodyPickerQuestions.length
      ? Array.from(
          new Set([
            ...getCCSFollowups(bodyPickerQuestions, bodyPartsPicked),
            ...getComplaintsCCSFollowups(complaints),
          ]),
        )
      : getComplaintsCCSFollowups(complaints);

  const followUpSurveyIds: string[] = followUpSurveyIdsRaw.filter((id) => {
    if (
      id === theSurveyIds.ndi &&
      followUpSurveyIdsRaw.includes(theSurveyIds.ndi) &&
      followUpSurveyIdsRaw.includes(theSurveyIds.dash)
    ) {
      return false;
    } else {
      return !(
        id === theSurveyIds.lefs &&
        followUpSurveyIdsRaw.includes(theSurveyIds.oswestry) &&
        followUpSurveyIdsRaw.includes(theSurveyIds.lefs)
      );
    }
  });

  let ccsFollowupSurveyIds: string[] =
    followUpSurveyIds?.filter(
      (match: string) => ccsFollowups?.includes(match),
    ) || [];
  if (surveyIds) {
    ccsFollowupSurveyIds = ccsFollowupSurveyIds.filter(
      (id) => !surveyIds.includes(id),
    );
  }
  if (ccsFollowupSurveyIds.length) {
    setSubmitText(submitVerbiage.more[language === 'es' ? 'es' : 'en']);
  }
  //if abbreviated survey and the last question is yes then add ccs to the followups.
  const answeredYesOnAbbreviatedSurvey = questions.some(
    (q) =>
      q.id === abbreviatedCCSQuestionsIds.moreComplaintsQuestionId &&
      q.answer?.value === 'Yes',
  );

  if (answeredYesOnAbbreviatedSurvey) {
    ccsFollowupSurveyIds = ccsFollowups || [];
  }
  try {
    const res = await submitSurvey(
      questions,
      kiosk,
      language === 'es' ? spanishQuestions : undefined,
      ccsFollowupSurveyIds,
      validationCode,
    );

    if (ccsFollowupSurveyIds?.length) {
      setIsSubmitting(false);
      return updateRequest?.(
        res as {
          steps: RequestStep[];
          language: 'en' | 'es';
          gender: Genders;
          age: number;
          id: string;
          prMap: { [key: string]: string };
        },
        () => markSurveyComplete(),
      );
    } else if (markSurveyComplete) {
      markSurveyComplete();
    }
  } catch (err) {
    console.error({ err });
    setIsSubmitting(false);
  }
  setIsSubmitting(false);
  return complete();
};

export const bodyPickerSubmit = (
  parts: BodyParts,
  sourceOfPain: SourceOfPain,
  spanishQuestions: SurveyQuestion[],
  questions: SurveyQuestion[],
  kiosk: boolean,
  language: string,
  currentQuestion: SurveyQuestion,
  setIsSubmitting: (val: boolean) => void,
  isSubmitting: boolean,
  submitSurvey: (
    questions: SurveyQuestion[],
    kiosk: boolean,
    spanishQuestions?: SurveyQuestion[],
    ccsFollowupSurveyIds?: string[],
    validationCode?: string | number,
  ) => Promise<any>,
  complete: () => void,
  markSurveyComplete: () => void,
  setSubmitText: (val: string) => void,
  submitVerbiage: {
    more: { en: string; es: string };
    done: { en: string; es: string };
  },
  setSpanishQuestions: React.Dispatch<React.SetStateAction<SurveyQuestion[]>>,
  setQuestions: React.Dispatch<React.SetStateAction<SurveyQuestion[]>>,
  setCurrentQuestion: (val: SurveyQuestion) => void,
  validationCode: string | number,
  isProvider = false,
  additionalComplaint = false,
  aaWI?: string,
  surveyIds?: string[],
  ccsFollowups?: string[],
  updateRequest?: (
    data: {
      prMap: { [key: string]: string };
      steps: RequestStep[];
      language: 'en' | 'es';
      gender: Genders;
      age: number;
      id: string;
    },
    callback?: () => void,
  ) => void,
  startingProblemArea?: number,
  abbreviatedCCS = false,
  remainingSteps?: number[],
) => {
  const newQuestions = [...questions];
  const newSpanishQuestions = spanishQuestions ? [...spanishQuestions] : [];
  const currentQuestionIndex = newQuestions.findIndex(
    (q) => q.id === currentQuestion.id,
  );

  if (newQuestions[currentQuestionIndex]) {
    newQuestions[currentQuestionIndex].answer = {
      type: 'bodypicker',
      value: {
        parts,
        sourceOfPain,
      },
    };
  }
  setQuestions(newQuestions);
  if (setSpanishQuestions && language === 'es') {
    if (newSpanishQuestions[currentQuestionIndex]) {
      newSpanishQuestions[currentQuestionIndex].answer = {
        type: 'bodypicker',
        value: {
          parts,
          sourceOfPain,
        },
      };
    }
    setSpanishQuestions(newSpanishQuestions);
  }
  // If this is the third bodypicker, don't ask questions
  if (currentQuestion.id === CCSBodyPickerQuestionIds.tertiary) {
    return submitStuff({
      questions,
      kiosk,
      spanishQuestions,
      setIsSubmitting,
      isSubmitting,
      submitSurvey,
      complete,
      markSurveyComplete,
      setSubmitText,
      submitVerbiage,
      validationCode,
      language,
      surveyIds,
      ccsFollowups,
      updateRequest,
    });
  }

  return addChiefComplaintQuestions(
    parts,
    language,
    currentQuestion,
    questions,
    spanishQuestions,
    setCurrentQuestion,
    setQuestions,
    setSpanishQuestions,
    additionalComplaint,
    isProvider,
    aaWI,
    startingProblemArea,
    abbreviatedCCS,
  );
};

export const getSurveyQuestion = (
  questions: SurveyQuestion[],
  id: string,
): SurveyQuestion => {
  return questions.find((q) => q.id === id) || questions[0];
};

export const getNextQuestionIndex = ({
  questions,
  age,
  gender,
}: {
  questions: SurveyQuestion[];
  age?: number;
  gender?: Genders;
}): number => {
  return questions.findIndex((q) => {
    // Already answered...
    if (q.answer) {
      return false;
    }
    if (!q.ifSome?.length && !q.if?.length) {
      return true;
    }
    // If there are conditions, check them and return true if all are met...
    if (q.ifSome?.length) {
      return q.ifSome.some((qif: any) => {
        if (qif.id) {
          if (qif.type === SurveyQuestionIfType.equal) {
            const dependentQ = getSurveyQuestion(questions, qif.id);
            const dependentA = dependentQ.answer?.value;
            if (Array.isArray(dependentA)) {
              return (
                dependentA &&
                dependentA.some((qv) =>
                  Array.isArray(qif.value)
                    ? qif.value.includes(qv)
                    : qif.value === qv,
                )
              );
            } else if (
              typeof dependentA === 'string' ||
              typeof dependentA === 'number'
            ) {
              return dependentA && Array.isArray(qif.value)
                ? qif.value.includes(dependentA)
                : qif.value === dependentA;
            }
          } else if (qif.type === SurveyQuestionIfType.greaterThan) {
            const dependentQ = getSurveyQuestion(questions, qif.id);
            const dependentA = dependentQ.answer?.value;
            return dependentA > qif.value;
          } else if (qif.type === SurveyQuestionIfType.lessThan) {
            const dependentQ = getSurveyQuestion(questions, qif.id);
            const dependentA = dependentQ.answer?.value;
            return dependentA < qif.value;
          } else if (qif.type === SurveyQuestionIfType.notAnswered) {
            const dependentQ = getSurveyQuestion(questions, qif.id);
            const dependentA = dependentQ.answer?.value;
            return !dependentA;
          }
        }
        return false;
      });
    }
    return q.if?.every((qif: any) => {
      if (!qif) {
        // Somehow it is undefined...this definitely happens for the same incident questions
        return true;
      }
      if (qif?.demographic) {
        if (qif.property === 'gender') {
          if (!gender) {
            return true;
          }
          return Array.isArray(qif.value)
            ? qif.value.includes(gender)
            : qif.value === gender;
        } else if (qif.property === 'age') {
          if (!age) {
            return true;
          }
          if (qif.type === SurveyQuestionIfType.equal) {
            return age === qif.value;
          } else if (qif.type === SurveyQuestionIfType.greaterThan) {
            return age > qif.value;
          } else if (qif.type === SurveyQuestionIfType.lessThan) {
            return age < qif.value;
          }
        }
      } else if (qif?.type === SurveyQuestionIfType.equal) {
        const dependentQ = getSurveyQuestion(questions, qif.id);
        const dependentA = dependentQ.answer?.value;
        if (Array.isArray(dependentA)) {
          return (
            dependentA &&
            dependentA.some((qv) =>
              Array.isArray(qif.value)
                ? qif.value.includes(qv)
                : qif.value === qv,
            )
          );
        } else if (
          typeof dependentA === 'string' ||
          typeof dependentA === 'number'
        ) {
          return dependentA && Array.isArray(qif.value)
            ? qif.value.includes(dependentA)
            : qif.value === dependentA;
        }
      } else if (qif?.type === SurveyQuestionIfType.greaterThan) {
        const dependentQ = getSurveyQuestion(questions, qif.id);
        const dependentA = dependentQ.answer?.value;
        return dependentA > qif.value;
      } else if (qif?.type === SurveyQuestionIfType.lessThan) {
        const dependentQ = getSurveyQuestion(questions, qif.id);
        const dependentA = dependentQ.answer?.value;
        return dependentA < qif.value;
      } else if (qif.type === SurveyQuestionIfType.notAnswered) {
        const dependentQ = getSurveyQuestion(questions, qif.id);
        const dependentA = dependentQ.answer?.value;
        return !dependentA;
      }
      return false;
    });
  });
};

const getCCSFollowups = (
  bodyPickerQuestions: SurveyQuestion[],
  bodyPartsPicked: { [key: string]: boolean },
) => {
  return bodyPickerQuestions.reduce((arr: string[], question) => {
    Object.keys(question.answer?.value?.parts).forEach(
      (frontOrBack: string) => {
        Object.keys(question.answer?.value?.parts[frontOrBack])?.forEach(
          (part: string) => {
            if (
              question.answer?.value?.parts?.[frontOrBack][part].right ||
              question.answer?.value?.parts?.[frontOrBack][part].left
            ) {
              if (part === 'neck' && !arr.includes(theSurveyIds.ndi)) {
                arr.push(theSurveyIds.ndi);
              }
              if (
                (part === 'upper-back' || part === 'upper-lower-back') &&
                !arr.includes(theSurveyIds.oswestry) &&
                !bodyPartsPicked['neck' as keyof typeof bodyPartsPicked]
              ) {
                arr.push(theSurveyIds.oswestry);
              }
              if (
                part === 'lower-back' &&
                !arr.includes(theSurveyIds.oswestry)
              ) {
                arr.push(theSurveyIds.oswestry);
              }
              if (
                (part === 'shoulder' ||
                  part === 'elbow' ||
                  part === 'hand' ||
                  part === 'wrist' ||
                  part === 'arm') &&
                !arr.includes(theSurveyIds.dash) &&
                !bodyPartsPicked['neck' as keyof typeof bodyPartsPicked]
              ) {
                arr.push(theSurveyIds.dash);
              }
              if (
                (part === 'hip' ||
                  part === 'knee' ||
                  part === 'thigh' ||
                  part === 'foot' ||
                  part === 'ankle' ||
                  part === 'leg') &&
                !arr.includes(theSurveyIds.lefs) &&
                !bodyPartsPicked[
                  'lower-back' as keyof typeof bodyPartsPicked
                ] &&
                !bodyPartsPicked[
                  'upper-back' as keyof typeof bodyPartsPicked
                ] &&
                !bodyPartsPicked[
                  'uppper-lower-back' as keyof typeof bodyPartsPicked
                ]
              ) {
                arr.push(theSurveyIds.lefs);
              }
            }
          },
        );
      },
    );
    return arr;
  }, []);
};

const getComplaintsCCSFollowups = (complaints: any[]) => {
  return complaints
    ?.map((c: any) => c.complaint)
    ?.reduce((arr: string[], rawPart: any) => {
      const part = rawPart.replace(/-/g, ' ').toLowerCase();
      if (part === 'neck' && !arr.includes(theSurveyIds.ndi)) {
        arr.push(theSurveyIds.ndi);
      }
      if (
        (part === 'upper back' || part === 'upper lower back') &&
        !arr.includes(theSurveyIds.oswestry) &&
        !complaints.some(
          (c: any) => c.complaint.replace(/-/g, ' ').toLowerCase() === 'neck',
        )
      ) {
        arr.push(theSurveyIds.oswestry);
      }
      if (part === 'lower back' && !arr.includes(theSurveyIds.oswestry)) {
        arr.push(theSurveyIds.oswestry);
      }
      if (
        (part === 'shoulder' ||
          part === 'elbow' ||
          part === 'hand' ||
          part === 'wrist' ||
          part === 'arm') &&
        !arr.includes(theSurveyIds.dash) &&
        !complaints.some(
          (c: any) => c.complaint.replace(/-/g, ' ').toLowerCase() === 'neck',
        )
      ) {
        arr.push(theSurveyIds.dash);
      }
      if (
        (part === 'hip' ||
          part === 'knee' ||
          part === 'thigh' ||
          part === 'foot' ||
          part === 'ankle' ||
          part === 'leg') &&
        !arr.includes(theSurveyIds.lefs) &&
        !complaints
          ?.map((c: any) => c.complaint)
          ?.some((c: string) =>
            ['lower back', 'upper back', 'uppper lower back'].includes(
              c.replace(/-/g, ' ').toLowerCase(),
            ),
          )
      ) {
        arr.push(theSurveyIds.lefs);
      }
      return arr;
    }, []);
};
