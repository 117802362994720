import React, { useContext } from 'react';
import { MeContext } from '../../contexts/me.context';
import KioskAssistance from './KioskAssistance';

type Props = {
  logout: () => void;
};

const KioskNeedAssistancePending: React.FC<Props> = ({ logout }) => {
  const {
    clinicInfo: { primaryColor },
  } = useContext(MeContext);

  return (
    <KioskAssistance
      title="Not recognized"
      subtitle="Assistance required"
      message="You were not recognized in our system. In order to continue, please see the front office staff and let them know that your account is pending."
      buttons={
        <button
          type="button"
          className="inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white hover:opacity-90 focus:outline-none transition ease-in-out duration-150"
          style={{
            backgroundColor: primaryColor,
          }}
          onClick={logout}
        >
          Logout
        </button>
      }
      show={true}
    />
  );
};

export default KioskNeedAssistancePending;
