import { Genders, RequestStep } from '@chiroup/core';
import React from 'react';
import { MeContext } from '../../contexts/me.context';
import Request from './Request';

type Props = {
  id?: string;
  data?: any;
  handleReset: () => void;
  updateRequest: (
    data: {
      prMap: { [key: string]: string };
      steps: RequestStep[];
      language: 'en' | 'es';
      gender: Genders;
      age: number;
      id: string;
    },
    callback?: () => void,
  ) => void;
};

const DirectRequest: React.FC<Props> = ({
  id,
  data,
  updateRequest,
  handleReset,
}) => {
  return (
    <MeContext.Provider
      value={{
        clinicInfo: data?.clinicInfo || {
          name: '',
          logo: '',
          primaryColor: '#4f46e5',
          accentColor: '#4f46e5',
        },
      }}
    >
      <Request
        data={data}
        id={id}
        updateRequest={updateRequest}
        validationCode={data?.validationCode}
        handleReset={handleReset}
        kiosk={false}
        encounterId={data?.encounterId}
      />
    </MeContext.Provider>
  );
};

export default DirectRequest;
