import {
  classNames,
  SurveyOption,
  SurveyProgress,
  SurveyQuestionAnswer,
  SurveyQuestionTypeOptions,
} from '@chiroup/core';
import { Transition } from '@headlessui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SurveyQuestionContent } from './SurveyQuestionContent';
import { SurveyQuestionTitle } from './SurveyQuestionTitle';

const verbiage = {
  en: {
    back: 'Back',
  },
  es: {
    back: 'Atrás',
  },
};

type Props = {
  show: boolean;
  question: string;
  type: string;
  typeOptions?: SurveyQuestionTypeOptions;
  options?: SurveyOption[];
  id: string;
  onSelect: (val: SurveyQuestionAnswer) => Promise<void>;
  disabled: boolean;
  images?: string[];
  showPrevious?: boolean;
  goBack?: () => void;
  progress?: SurveyProgress;
  language?: 'en' | 'es';
  primaryColor: string;
  parts?: any;
  source?: any;
  myScreen?: boolean;
  height?: number;
};

export const SurveyQuestionComponent: React.FC<Props> = ({
  show,
  question,
  type,
  typeOptions,
  options,
  id,
  onSelect,
  disabled,
  images,
  showPrevious,
  parts,
  source,
  goBack = () => {},
  progress,
  language = 'en',
  primaryColor,
  myScreen = false,
  height = 400,
}) => {
  const [scroll, setScroll] = useState(
    document.documentElement.scrollHeight ===
      document.documentElement.clientHeight
      ? 100
      : 0,
  );

  const onScroll = () => {
    const scrolled = document.documentElement.scrollTop;
    const maxHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrollPercent = (scrolled / maxHeight) * 100;
    setScroll(maxHeight ? scrollPercent : 100);
  };

  useEffect(() => {
    onScroll();
  }, [question]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    window.addEventListener('click', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('click', onScroll);
    };
  }, []);

  const percentDone = useMemo(() => {
    const completed = progress?.completed || 0;
    const total = progress?.total || 0;
    const percent = Math.round((completed / total) * 100);
    return percent || 0;
  }, [progress]);

  return (
    <div className={classNames('', myScreen ? '' : '')}>
      {!show && myScreen ? (
        <div style={{ height: `${height - 75}px` }}></div>
      ) : (
        <Transition
          show={show}
          enter="transition ease-out duration-300"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-300"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            className={classNames(
              '',
              myScreen
                ? ' flex flex-col items-center w-full my-20'
                : 'flex flex-col items-center w-full my-20',
            )}
            style={{
              marginTop: 100,
              marginBottom: 100,
            }}
          >
            <div className="justify-center w-full max-w-4xl">
              <SurveyQuestionTitle title={question} center={type === 'date'} />
              <div className="relative">
                <SurveyQuestionContent
                  parts={parts}
                  source={source}
                  id={id}
                  type={type}
                  typeOptions={typeOptions}
                  options={options}
                  onSelect={onSelect}
                  disabled={disabled}
                  images={images}
                  language={language}
                  primaryColor={primaryColor}
                />
                {scroll < 90 && <div className="hide-bottom" />}
              </div>
            </div>
          </div>
        </Transition>
      )}
      {scroll < 100 && (
        <div
          className="fixed flex items-center justify-center w-10 h-10 p-2 bg-white rounded-full shadow-lg cursor-pointer animate-bounce dark:bg-gray-800 lg:w-16 lg:h-16 bottom-20 right-4"
          onClick={() => {
            window.scrollBy(0, 800);
          }}
        >
          <svg
            className="w-6 h-6 lg:h-10 lg:w-10"
            style={{
              color: primaryColor,
            }}
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
          </svg>
        </div>
      )}
      <div
        className={classNames(
          'w-full',
          myScreen
            ? 'sticky inset-x-0 bottom-0 bg-white '
            : 'fixed inset-x-0 bottom-0 bg-gray-100 px-4',
        )}
      >
        <div className="flex items-center justify-between w-full mb-2">
          <div>
            {showPrevious && (
              <div
                className="flex items-center text-center cursor-pointer"
                onClick={goBack}
              >
                <svg
                  className="w-5 h-5"
                  style={{
                    color: primaryColor,
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>

                <div className="text-xs font-medium text-gray-500">
                  {verbiage[language === 'es' ? 'es' : 'en'].back}
                </div>
              </div>
            )}
          </div>
          <div className="text-right">
            <span
              className="inline-block text-xs font-semibold"
              style={{
                color: primaryColor,
              }}
            >
              {percentDone}%
            </span>
          </div>
        </div>
        <div className="relative flex w-full h-2 mb-4 overflow-hidden text-xs rounded">
          <div
            style={{
              width: `${percentDone}%`,
              backgroundColor: primaryColor,
            }}
            className="flex flex-col justify-center text-center text-white shadow-none whitespace-nowrap"
          />
          <div
            style={{
              backgroundColor: primaryColor,
              opacity: 0.2,
            }}
            className="absolute top-0 left-0 flex flex-col justify-center w-full h-2 text-center text-white shadow-none whitespace-nowrap"
          />
        </div>
      </div>
    </div>
  );
};
