export const CCSPrimaryQuestionIds = {
  mainQuestionId: 'f1c9598a-3ed7-4dd2-a82b-d9ec4685b90d',
  providerCompleteContinueQuestionId: '5f2cb48c-f08d-4e14-abb2-42d2eae74a83',
  bilateralQuestionId: '9b0db0d9-0d5c-4a03-b253-3f2e58f70fa8',
  brieflyDescribeQuestionId: 'ca95b392-b30b-4ede-b6c2-22c21df22a67',
  bestDescribesQuestionPainId: '2db776d4-9ba4-486a-b6fb-bc3b4b940ded',
  bestDescribesQuestionAlteredId: 'ae92ed5a-13bd-47aa-a52f-4418193bf9ea',
  bestDescribesQuestionWeaknessId: 'a03db71d-9727-4eda-8bc8-904f1d5431d3',
  symptomsArePresentQuestionId: '16057592-9156-49d2-a62c-bec02a67b62c',
  aggravatesQuestionId: 'f9f802b7-b6e6-4321-96db-e63acf7b982f',
  intensityQuestionId: 'cd332278-fde2-4c8d-9d05-994841d59d18',
  intensity2QuestionId: '182455bb-d3bb-4929-9cce-ac5977fad63a',
  symptomsBeginQuestionId: '9b50d7a6-c761-4210-81dc-f07bd7f1341b',
  causedQuestionId: 'b1b16ecd-1518-45c9-8941-e0338311367f',
  presentEpisodeQuestionId: '9cc7a5ec-4174-46ea-8721-e8146239b791',
  onsetQuestionId: 'af5ee359-87af-4e8d-9f8a-01bc0960f9aa',
  sameSymptomsId: '5cf64bf7-5a33-4b64-b629-169e3686a94d',
  estimateQuestionId: 'c9858082-1e54-4e58-b2a1-b2c6ed550528',
  sleepPositionQuestionId: 'cd6a2f05-80b4-4a90-b7a7-a1a404256bf6',
  mattressQuestionId: '0071ebff-daa1-400b-b13b-67db76d35e7a',
  providerQuestionId: '62145a16-cef8-41c5-b811-f5baca2e04f9',
  currentMattressQuestionId: 'd1b86620-0a10-4559-8113-2099faaacfb7',
  pillowQuestionId: 'c58c9ff6-7d66-40e1-9d0e-50238a2e4569',
  reliefQuestionId: 'cab06475-af1a-4a51-b2bb-a144161588ce',
  interferedQuestionId: 'db9fbeb4-bdac-4421-8eb7-fa094795ce18',
  generalInterferedQuestionId: '2a39a978-cbe8-4481-b44e-27baf2e74c52',
  pregnantQuestionId: '11d8fd69-4914-4447-8422-4724bb469816',
  pregnantConfirmedDateQuestionId: '3ab7d7a7-0ac8-4e44-ab64-74eb64b4c164',
  pregnantSuspectedDateQuestionId: '83d36817-d459-4dcf-84bd-b3a046784dd5',
  yellowFlagsQuestionId: '359510c4-7bd7-4f6d-bebb-70bda43e4efb',
  employerQuestionId: 'd44d9e29-f8fe-4627-abef-702ada991162',
  contactPersonQuestionId: '87dfd621-1855-4d0a-8094-77b9f26958f7',
  contactPersonInfoQuestionId: '2b00660f-67a7-47cb-8a19-d470028861df',
  occupationQuestionId: 'd33240f5-f38d-486d-843b-dc3f47a3fd41',
  whenDidInjuryQuestionId: '5aeaaab3-8f74-42c5-a366-32913554c005',
  whereDidInjuryQuestionId: '84ac0df6-7faf-4f35-a4ee-9a42c1428b2d',
  descriptionInjuryQuestionId: '838dc0ac-868d-437e-9545-de9949a7d007',
  lostWorkInjuryQuestionId: 'be478c24-9695-4df8-9d06-156276213b35',
  howOftenLostWorkInjuryQuestionId: '09d9f6be-c9e0-4101-9b92-80035a44761d',
  currentlyOffWorkInjuryQuestionId: '62E66C6F-E45C-49F5-9857-E8F4F75036F4',
  didYouReportInjuryQuestionId: '69f4f84e-eba6-4219-8eee-55ab74f4f724',
  whoReportInjuryQuestionId: '5bfeb618-5bcd-482c-b673-766b36d9a917',
  attorneyInjuryQuestionId: 'de9bcb3d-386d-4882-b1d7-2134bfc3ffe7',
  attorneyNameInjuryQuestionId: '46a379d0-a7af-40f4-be24-18a9a4617bb9',
  dateOfAccidentQuestionId: '9f896cb2-a708-4a90-bce4-098fce2cce8f',
  descriptionOfAccidentQuestionId: 'de3202d2-4188-4bbe-8972-ffb46cd7a96c',
  secondaryCollisionsQuestionId: 'fe973af2-3600-4720-94c7-400fb16c9705',
  vehicleTypeQuestionId: 'c2079ea7-a3b7-412b-b9c9-2f267bf2aa49',
  speedEstimateQuestionId: 'cdefe00a-8d67-401f-93dd-e9eea10783a4',
  otherVehicleTypeQuestionId: 'c6c1ddf3-e978-4490-a7d7-eb2c0e02ac6b',
  otherSpeedEstimateQuestionId: '5a76948f-9966-4c4b-bee9-082f73cbfd6f',
  otherSpeedEstimateQuestionRadioId: '40b6eb6e-e406-4c77-aae3-8a8eae92c638',
  vehicleDamageQuestionId: '91bbc768-1063-4843-8eb0-c835d3a2b995',
  vehicleDrivableQuestionId: '3ff0e33c-0ee1-4040-bccd-7f61ffb62558',
  vehicleSittingQuestionId: '2fab1845-e0ad-4641-9fa8-34b48a3ace7b',
  roadConditionsQuestionId: '71133834-6a72-4344-9eb2-5a04bb1da4a2',
  impactLookingQuestionId: '4fb95a6d-5fd0-484c-b6a0-1fcfff0ff9e7',
  onBrakeQuestionId: '49113292-5d25-4dda-9e64-5aa07fce876c',
  autoWereYouQuestionId: '94ebec50-5c39-4e69-b5ba-714c94575682',
  seatbeltQuestionId: '5ffae648-3661-4fc2-b3a2-fa5b5e33a7e9',
  airbagQuestionId: '5a1717d7-3b44-4e15-be30-084a09f8e55b',
  noticeSymptomsQuestionId: '803965d6-c376-4053-b845-1c2088836e27',
  seekCareQuestionId: 'b6e4db03-fd03-4a3a-b8e1-c4b505afe0e2',
  percentOfDaysQuestionId: 'a83b8d43-9a39-42d7-9414-4ca6f8fe2c25',
};

export const abbreviatedCCSQuestionsIds = {
  moreComplaintsQuestionId: 'c6f0e9dc-1a8e-4e2b-b9ae-2a1b38229e7f',
};

export const CCSSecondaryQuestionIds = {
  mainQuestionId: '391e6d57-d9ff-4914-88cb-91694b01d241',
  bilateralQuestionId: 'cbdab02e-41b0-446d-b556-41f5da72778f',
  brieflyDescribeQuestionId: 'f0366767-3602-4959-877c-76893a636442',
  bestDescribesQuestionPainId: '27fdd754-ff09-4f60-9ec9-c00a332e9e9c',
  bestDescribesQuestionAlteredId: '6e4b41cb-10b7-462a-8d0f-b6d6cba9fe1e',
  bestDescribesQuestionWeaknessId: '59c5c3c2-045e-48b1-97e3-18683ba50cb7',
  symptomsArePresentQuestionId: '3798e9a4-4613-41b8-b0a1-5f9523071f28',
  aggravatesQuestionId: '3dbce669-9445-4abb-8c09-08deebdf9624',
  intensityQuestionId: '7f714f87-2780-4588-a357-38ccc6337030',
  intensity2QuestionId: '7d7fd4e0-3eff-4de3-8d8e-616b47411eef',
  symptomsBeginQuestionId: 'df4d9f77-f2a1-4c15-817c-34d022c75004',
  causedQuestionId: 'cff051a1-0d94-4ac0-b746-248d069d19d5',
  presentEpisodeQuestionId: '0ffd498c-ca0e-4906-b415-ef83e8994447',
  onsetQuestionId: '5b095af2-9382-4643-a567-e2958517b4dc',
  sameSymptomsId: 'd5ffa2d5-50a5-4ebb-82e5-dc68e7000c32',
  estimateQuestionId: '29604bce-338c-41d6-bbae-d57bd232d379',
  sleepPositionQuestionId: '940e2702-f9b6-4c4b-a858-9023d165e9ee',
  mattressQuestionId: '803e9ecb-825d-4a86-a08d-abf84a27ed17',
  providerQuestionId: '3796cda1-28c3-4507-970f-77082545ffd4',
  currentMattressQuestionId: 'e5a33a17-e498-428c-a381-cc1819c9e35e',
  pillowQuestionId: 'a5eafc0a-277e-4d40-bb72-85ba2173fb5f',
  reliefQuestionId: '8d6f9e10-1cbf-4c79-a460-648a8a1a4d1f',
  interferedQuestionId: 'd564555a-8b24-423c-816a-7ddabf9a0949',
  generalInterferedQuestionId: '2e89cf0c-375f-4a1d-b070-2e74de77947b',
  pregnantQuestionId: '68eaf475-4bfe-4b3c-a19f-dbdd468d2381',
  pregnantConfirmedDateQuestionId: '754c7577-5b8f-4f38-8930-dbebd3773f78',
  pregnantSuspectedDateQuestionId: '517ed429-155a-4538-805c-ced6fb93bc38',
  yellowFlagsQuestionId: '07bc666f-4e5a-4e01-8a4e-e9c06bdd4351',
  employerQuestionId: '70e648d6-bea7-45c5-964d-c13f56dfd2c7',
  contactPersonQuestionId: 'bbd540ba-41fa-4a74-a48e-9bb6725574ad',
  contactPersonInfoQuestionId: '3e1155b9-8055-459a-b23c-d6b29ea864e6',
  occupationQuestionId: 'f07d6039-5043-492f-af00-19e2f7955db6',
  whenDidInjuryQuestionId: 'f4d2f3ba-799a-4674-a177-576031f6184c',
  whereDidInjuryQuestionId: '5f5d6067-8598-410c-bd09-1965eb533ad4',
  descriptionInjuryQuestionId: 'd20a1532-7e83-4f94-a56e-f0952213ccda',
  lostWorkInjuryQuestionId: '6496a360-048a-41d5-a9b4-db3e4886361b',
  howOftenLostWorkInjuryQuestionId: '7b2e7341-498b-4f7e-b811-31277a869b8d',
  currentlyOffWorkInjuryQuestionId: '56DB2C94-AABB-4C47-AF45-F3CD7D2B97F4',
  didYouReportInjuryQuestionId: '033ab488-d276-4cb2-9494-d78403384888',
  whoReportInjuryQuestionId: 'e017d413-4d75-4e72-95a7-dc20e7878fe3',
  attorneyInjuryQuestionId: '889f9515-6b50-4728-80d3-34d123359a0c',
  attorneyNameInjuryQuestionId: '1748aca5-75d9-44eb-ab0c-9d9cba73bd7f',
  dateOfAccidentQuestionId: '3688eff5-17e5-4014-b042-10d9c3ea4e1f',
  descriptionOfAccidentQuestionId: '51efcf36-9e3b-4ac3-bbcd-1f4b84dd51b9',
  secondaryCollisionsQuestionId: 'dc5df510-5e78-4d37-aeb5-c6b41f8053ad',
  vehicleTypeQuestionId: '18ad1fde-bd06-496f-8b4c-800b7d3ffba5',
  speedEstimateQuestionId: '40f5581f-d128-4e67-b901-6b12647f29e5',
  otherVehicleTypeQuestionId: '3336e43d-226f-4967-8429-07ef33262d19',
  otherSpeedEstimateQuestionId: '22a09bbf-093f-44d4-8394-25046e8a655d',
  otherSpeedEstimateQuestionRadioId: '5fbc0eb9-f582-437b-a3bc-8215dabeb44d',
  vehicleDamageQuestionId: 'ec7d2417-9c31-447c-8456-7757f7f08353',
  vehicleDrivableQuestionId: '9a8adf92-99b8-461b-bd8f-d09ec985b6e2',
  vehicleSittingQuestionId: 'a808d3aa-ff32-4cef-b2b0-14c346d94817',
  roadConditionsQuestionId: '34ec667b-a7f5-4ca8-bb23-f1f10bcbf731',
  impactLookingQuestionId: '69713304-9ce5-4b4d-9118-1e62c0079fe3',
  onBrakeQuestionId: '91d2314b-905e-4d94-b206-26e89f7c7093',
  autoWereYouQuestionId: '6fcf2301-5393-4907-825b-8bb68e0942de',
  seatbeltQuestionId: '1ffc8e26-5c2d-4efb-8358-576bbb5eec0c',
  airbagQuestionId: '75dcd59e-7c58-44f6-978b-8e634f0ef570',
  noticeSymptomsQuestionId: '2badfd40-4675-4984-8659-f10a1d03cf07',
  seekCareQuestionId: '02c467f8-490a-4367-a316-5fa19135c1d4',
  percentOfDaysQuestionId: '61f99c17-8e14-4aba-b891-6a290616e0e3',
  sameIncidentQuestionId: '2ddd3530-4454-44f5-bc2e-8c10a88877ac',
};

export const CCSBodyPickerQuestionIds = {
  primary: 'e89903d4-5f69-4c64-a3fc-7827c9179514',
  secondary: 'b2469a41-1079-43e7-afcc-a5ba4cf73698',
  tertiary: '0036805b-e48f-49df-8549-11c6d1ce9bea',
};

export const ReevalSurveyQuestionIds = {
  reevalSymptomsQuestionId: '4fbff72a-d32a-433b-bf2d-92a941cd1344',
  reevalSymptomsPercentImprovedQuestionId:
    '37586d68-b15c-4c21-b2d3-0f0b87fd18b8',
  reevalsymptomsSpreadQuestionId: 'e5504754-d0d8-429e-910e-b6456c091815',
  reevalNewProblemsQuestionId: '3df08dd7-121d-4fcf-a70b-58d622884561',
};

export const CCSBodyPickerQuestionIdsArr = [
  CCSBodyPickerQuestionIds.primary,
  CCSBodyPickerQuestionIds.secondary,
  CCSBodyPickerQuestionIds.tertiary,
];
