export enum KioskScreens {
  EnterPhone = 'EnterPhone',
  PhoneNotFound = 'PhoneNotFound',
  Request = 'Request',
  AreYouNew = 'AreYouNew',
  NewPatient = 'NewPatient',
  EstablishedPatient = 'EstablishedPatient',
  RecentlyCompletedYes = 'RecentlyCompletedYes',
  RecentlyCompletedNo = 'RecentlyCompletedNo',
  EnterMethod = 'EnterMethod',
  ForwardRequest = 'ForwardRequest',
  NeedAssistancePending = 'NeedAssistancePending',
  AppointmentNotFound = 'AppointmentNotFound',
  Complete = 'Complete',
}
