import { Patient } from '@chiroup/core';
import { ChiroUpAPI } from '@chiroup/client-core';

const kioskService = () => {
  const login = async (pairCode: string): Promise<any> => {
    return ChiroUpAPI.post('api-noauth', `/kiosk/login`, {
      body: { pairCode },
    });
  };

  const kioskMe = async (): Promise<{
    name: string;
    logo: string;
    primaryColor: string;
    accentColor: string;
    flags: string[];
  }> => {
    return ChiroUpAPI.get('api', `/kiosk/me`, {});
  };

  const getRequest = async (request: {
    phone?: string;
    patientId?: string;
  }): Promise<any> => {
    return ChiroUpAPI.get('api', `/kiosk/requests`, {
      queryParams: {
        phone: request.phone,
        patientId: request.patientId,
      },
    });
  };

  const createPendingPatient = async (patient: Partial<Patient>) => {
    return ChiroUpAPI.post('api', `/kiosk/pending-patient`, {
      body: patient,
    });
  };

  const forwardRequest = async (
    requestId: string,
    method: string,
    maskedMethod: string,
  ): Promise<any> => {
    return ChiroUpAPI.post('api', `/kiosk/forward-request`, {
      body: { requestId, method, maskedMethod },
    });
  };

  const createVisit = async (
    requestId: string,
    prMap: { [key: string]: string },
    validationCode: string,
    reeval: boolean,
  ): Promise<void> => {
    ChiroUpAPI.post('api', `/kiosk/visit`, {
      body: { requestId, prMap, validationCode, reeval },
    });
  };

  return {
    login,
    getRequest,
    kioskMe,
    createPendingPatient,
    forwardRequest,
    createVisit,
  };
};

export default kioskService();
