import {
  Genders,
  RequestStep,
  SurveyQuestion,
  SurveyQuestionAnswer,
} from '@chiroup/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SurveyQuestionComponent } from '../survey';
import Body from './Body';
import CCSInstructions from './CCSInstructions';
import { bodyPickerSubmit, getNextQuestionIndex, submitStuff } from './helpers';

const submitVerbiage = {
  more: {
    en: 'Hang tight, we have a few more things we need to ask...',
    es: 'Espera un momento, tenemos algunas cosas más que debemos preguntar...',
  },
  done: { en: 'Submitting survey', es: 'La encuesta esta siendo enviada' },
};

type Props = {
  myScreen?: boolean;
  setQuestionChanging?: React.Dispatch<React.SetStateAction<boolean>>;
  primaryColor: string;
  language: 'en' | 'es' | undefined;
  gender: Genders;
  age: number;
  complete: () => void;
  ccsFollowups?: string[];
  kiosk: boolean;
  prMap: { [key: string]: string };
  markSurveyComplete: () => void;
  updateRequest?: (
    data: {
      prMap: { [key: string]: string };
      steps: RequestStep[];
      language: 'en' | 'es';
      gender: Genders;
      age: number;
      id: string;
    },
    callback?: () => void,
  ) => void;
  validationCode: string | number;
  surveyIds?: string[];
  startQuestion: SurveyQuestion;
  submitSurvey: (
    questions: SurveyQuestion[],
    kiosk: boolean,
    spanishQuestions?: SurveyQuestion[],
    ccsFollowupSurveyIds?: string[],
    validationCode?: string | number,
  ) => Promise<any>;
  startingProblemArea?: number;
  additionalComplaints?: boolean;
  abbreviatedCCS?: boolean;
};

export const ChiefComplaintSurvey = ({
  primaryColor,
  language,
  gender,
  age,
  complete,
  ccsFollowups,
  markSurveyComplete,
  updateRequest,
  validationCode,
  surveyIds,
  startQuestion,
  submitSurvey,
  kiosk,
  myScreen = false,
  setQuestionChanging,
  startingProblemArea,
  additionalComplaints = false,
  abbreviatedCCS = false,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [questions, setQuestions] = useState<SurveyQuestion[]>([]);
  const [spanishQuestions, setSpanishQuestions] = useState<SurveyQuestion[]>([
    startQuestion,
  ]);
  const [currentQuestion, setCurrentQuestion] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [submitText, setSubmitText] = useState<string>(
    submitVerbiage.done[language === 'es' ? 'es' : 'en'],
  );
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState<boolean>(true);
  const [aaWI, setAaWI] = useState<string>();
  const [instructions, setInstructions] = useState<boolean>(true);
  const modalHeight = useRef(0);

  const progress = useMemo(() => {
    const questionsCopy = [...questions];
    const total = questionsCopy?.length;
    const reverseIndexOfLastAnswer = questionsCopy
      .reverse()
      .findIndex((q) => !!q.answer);
    const reverseIndexOfLastAnswerOrZero =
      reverseIndexOfLastAnswer > -1
        ? reverseIndexOfLastAnswer
        : questionsCopy.length;
    const completed = questionsCopy.length - reverseIndexOfLastAnswerOrZero;
    return {
      completed: completed || 0,
      total: total || 1,
    };
  }, [questions]);

  const disableSurvey = async () => {
    return new Promise((resolve, reject) => {
      setDisabled(true);
      setShow(false);
      setQuestionChanging?.(true);
      // Wait 333ms for the question to disappear
      setTimeout(() => {
        resolve(true);
      }, 333);
    });
  };

  const enableSurvey = async () => {
    return new Promise((resolve, reject) => {
      // Wait 333ms for the question to have been changed
      setTimeout(() => {
        setDisabled(false);
        setShow(true);
        setQuestionChanging?.(false);
        resolve(true);
      }, 333);
    });
  };

  const onSelect = async (val: SurveyQuestionAnswer) => {
    const height = document.getElementById('survey-modal');
    if (height) {
      modalHeight.current = height.clientHeight;
    }
    await disableSurvey();
    if (
      currentQuestion?.id === '5f2cb48c-f08d-4e14-abb2-42d2eae74a83' &&
      val.value === '1' &&
      myScreen
    ) {
      return submitStuff({
        questions,
        kiosk,
        spanishQuestions,
        setIsSubmitting,
        isSubmitting,
        submitSurvey,
        complete,
        markSurveyComplete,
        setSubmitText,
        submitVerbiage,
        validationCode,
        language,
        surveyIds,
        ccsFollowups,
        updateRequest,
      });
    }
    if (
      val.value ===
        (language === 'es' ? 'Accidente automovilístico' : 'Auto accident') ||
      val.value ===
        (language === 'es'
          ? 'Lesión relacionada con el empleo'
          : 'Employment-related injury')
    ) {
      setAaWI(val.value);
    }

    const currentQuestionIndex = questions.findIndex(
      (q: { id: string }) => q.id === currentQuestion.id,
    );
    const newQuestions = [...questions];
    const newQuestionsSpanish = spanishQuestions ? [...spanishQuestions] : [];
    newQuestions[currentQuestionIndex].answer = val;
    if (language === 'es') {
      newQuestionsSpanish[currentQuestionIndex].answer = val;
    }

    const nextQuestionIndex = getNextQuestionIndex({ questions: newQuestions });
    const nextQuestion = ((language === 'es'
      ? newQuestionsSpanish
      : newQuestions) || [])?.[nextQuestionIndex];
    if (!nextQuestion) {
      setDisabled(false);
      return submitStuff({
        questions,
        kiosk,
        spanishQuestions,
        setIsSubmitting,
        isSubmitting,
        submitSurvey,
        complete,
        markSurveyComplete,
        setSubmitText,
        submitVerbiage,
        validationCode,
        language,
        surveyIds,
        ccsFollowups,
        updateRequest,
      });
    }
    setQuestions(newQuestions);
    setQuestionChanging?.(true);
    if (setSpanishQuestions && language === 'es') {
      setSpanishQuestions(newQuestionsSpanish);
    }

    if (
      typeof nextQuestion?.typeOptions?.notLessThan === 'string' ||
      typeof nextQuestion?.typeOptions?.notLessThan === 'number'
    ) {
      const currentQuestionId = currentQuestion.id;
      nextQuestion.typeOptions.notLessThan =
        getValueOfQuestion(currentQuestionId);
    }
    if (
      typeof nextQuestion?.typeOptions?.notMoreThan === 'string' ||
      typeof nextQuestion?.typeOptions?.notMoreThan === 'number'
    ) {
      const currentQuestionId = currentQuestion.id;
      nextQuestion.typeOptions.notMoreThan =
        getValueOfQuestion(currentQuestionId);
    }

    setCurrentQuestion(nextQuestion);

    await enableSurvey();
    return;
  };

  const getValueOfQuestion = (questionId: string) => {
    const question = questions.find(
      (question: { id: string }) => question.id === questionId,
    );
    if (question) {
      return question.answer?.value;
    }
  };

  useEffect(() => {
    setCurrentQuestion(startQuestion);
    setQuestions([startQuestion]);
  }, [startQuestion]);

  const goBack = async () => {
    await disableSurvey();
    const currentQuestionIndex = questions.findIndex(
      (q: { id: string }) => q.id === currentQuestion.id,
    );
    const mostRecentQuestionThatWasAnswered = questions
      .slice(0, currentQuestionIndex)
      .reverse()
      .find((q) => q.answer);

    if (!mostRecentQuestionThatWasAnswered) return;

    const mostRecentQuestionThatWasAnsweredIndex = questions.findIndex(
      (q) => q.id === mostRecentQuestionThatWasAnswered?.id,
    );

    if (mostRecentQuestionThatWasAnswered?.type === 'bodypicker') {
      const thisProblemArea = mostRecentQuestionThatWasAnswered.problemArea;
      setQuestions((prev) => {
        const questionsBeforeIndex = prev.slice(
          0,
          mostRecentQuestionThatWasAnsweredIndex + 1,
        );
        return questionsBeforeIndex.filter(
          (q) => q.problemArea !== thisProblemArea || q.type === 'bodypicker',
        );
      });
    } else {
      setQuestions((prev) => {
        return prev.map((q, i) => {
          if (i > mostRecentQuestionThatWasAnsweredIndex) {
            return { ...q, answer: undefined };
          }
          return q;
        });
      });
    }
    setCurrentQuestion({ ...mostRecentQuestionThatWasAnswered, answer: null });
    await enableSurvey();
  };

  return instructions && !myScreen ? (
    <CCSInstructions
      setInstructions={setInstructions}
      language={language}
      primaryColor={primaryColor}
    />
  ) : (
    currentQuestion &&
      (currentQuestion.type === 'bodypicker' ? (
        <Body
          show={show}
          bodyPickerSubmit={bodyPickerSubmit}
          language={language}
          id={currentQuestion.id}
          primaryColor={primaryColor}
          spanishQuestions={spanishQuestions}
          questions={questions}
          currentQuestion={currentQuestion}
          setSpanishQuestions={setSpanishQuestions}
          setQuestions={setQuestions}
          setCurrentQuestion={setCurrentQuestion}
          aaWI={aaWI}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          complete={complete}
          markSurveyComplete={markSurveyComplete}
          submitSurvey={submitSurvey}
          submitVerbiage={submitVerbiage}
          setSubmitText={setSubmitText}
          validationCode={validationCode}
          surveyIds={surveyIds}
          ccsFollowups={ccsFollowups}
          updateRequest={updateRequest}
          kiosk={kiosk}
          myScreen={myScreen}
          startingProblemArea={startingProblemArea}
          additionalComplaints={additionalComplaints}
          abbreviatedCCS={abbreviatedCCS}
        />
      ) : (
        <SurveyQuestionComponent
          show={show}
          question={currentQuestion.question}
          type={currentQuestion.type}
          typeOptions={currentQuestion.typeOptions}
          options={currentQuestion.options}
          id={currentQuestion.id}
          onSelect={onSelect}
          disabled={disabled || !!currentQuestion.answer}
          images={currentQuestion.images}
          progress={progress}
          language={language}
          primaryColor={primaryColor}
          myScreen={myScreen}
          showPrevious
          goBack={goBack}
          height={modalHeight?.current}
        />
      ))
  );
};
