import { DateValueEstimate } from '@chiroup/core';
import React from 'react';
import { InlineListBox } from './InlineListBox';

type Props = {
  value: DateValueEstimate;
  onChange: (val: DateValueEstimate) => void;
  primaryColor: string;
  language?: string;
};

const DatepickerEstimate: React.FC<Props> = ({
  value,
  onChange,
  primaryColor,
  language,
}) => {
  const onChangeValue = (val: number) => {
    onChange({ ...value, value: val });
  };

  const onChangeUnit = (val: string) => {
    onChange({ ...value, unit: val });
  };

  return (
    <div className="mb-4 p-6">
      <p className="text-lg text-gray-500 sm:text-xl">
        {language === 'es'
          ? 'Dinos tu mejor estimado:'
          : 'Give us your best guess:'}
      </p>
      <div className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
        {language === 'es' ? 'Hace ' : 'About '}
        <InlineListBox
          value={value.value}
          onChange={onChangeValue}
          options={Array.from(Array(30)).map((item, i) => i + 1)}
          className="w-64"
          primaryColor={primaryColor}
        />{' '}
        <InlineListBox
          options={['day', 'week', 'month', 'year']}
          value={value.unit}
          onChange={onChangeUnit}
          suffix={value.value > 1 ? 's' : ''}
          primaryColor={primaryColor}
        />
        {language === 'es' ? ' atras.' : ' ago.'}
      </div>
    </div>
  );
};

export default DatepickerEstimate;
