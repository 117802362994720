import { BodyParts, SourceOfPain, bodyParts } from '@chiroup/core';
import maleRear from '../images/male-back.png';
import maleFront from '../images/male-front.png';

type Props = {
  parts?: BodyParts;
  source?: SourceOfPain;
};

export const PatientCreateCCSPicker: React.FC<Props> = ({ parts, source }) => {
  const createSource = (source: any, side: string) => {
    return side === source.side ? (
      <svg
        x={source.x - 15}
        y={source.y - 15}
        width="30"
        height="30"
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
        className="z-50 cursor-pointer"
      >
        <circle
          cx="20"
          cy="20"
          fill="none"
          r="10"
          stroke="#f472b6"
          strokeWidth="2"
        >
          <animate
            attributeName="r"
            from="8"
            to="20"
            dur="1.5s"
            begin="0s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="1.5s"
            begin="0s"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="20" cy="20" fill="#f472b6" r="10" />
      </svg>
    ) : (
      <div></div>
    );
  };
  return (
    <div className="relative">
      <div className="flex justify-center svg-container">
        <div className="flex flex-row gap-6 w-full">
          {Object.entries(parts || {}).map(([side, val], i: number) => {
            return (
              <div key={i}>
                <svg
                  viewBox="0 0 405 1200"
                  className="body-picker svg-content avoid-break"
                >
                  <image
                    width="405"
                    height="1200"
                    xlinkHref={side === 'front' ? maleFront : maleRear}
                  />
                  {bodyParts.male[side].map((part, i) => (
                    <path
                      key={i}
                      className={
                        val[part.part]?.[part.leftOrRight] ? 'selected' : ''
                      }
                      fill="transparent"
                      d={part.d}
                    />
                  ))}
                  {createSource(source, side)}
                </svg>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
