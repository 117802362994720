export enum LSType {
  user,
  clinic,
}

const useLocalStorage = () => {
  const getItem = (key: string, json = true) => {
    try {
      const item = localStorage.getItem(key);
      return item ? (json ? JSON.parse(item) : item) : null;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const setItem = (key: string, val: any) => {
    try {
      val = typeof val === 'string' ? val : JSON.stringify(val);
      localStorage.setItem(key, val);
    } catch (err) {
      console.error(err);
    }
  };

  const removeItem = (key: string) => {
    try {
      localStorage.removeItem(key);
    } catch (err) {
      console.error(err);
    }
  };

  return { setItem, getItem, removeItem };
};

export default useLocalStorage;
