import { ArrowPathIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

const ASSETS = import.meta.env.VITE_HEALTHCOM_ASSETS;

const verbiage = {
  continue: {
    es: 'Seguir',
    en: 'Continue',
  },
  replay: {
    mobile: {
      es: 'Reproducir',
      en: 'Replay',
    },
    desktop: {
      es: 'Reproducir las instrucciones',
      en: 'Replay instructions',
    },
  },
};

type Props = {
  setInstructions: (arg: boolean) => void;
  language?: string;
  primaryColor: string;
};

const CCSInstructions: React.FC<Props> = ({
  setInstructions,
  language,
  primaryColor,
}) => {
  const [video, setVideo] = useState<string>();
  const [playing, setPlaying] = useState<boolean>(true);
  // const [show, setShow] = useState<boolean>(false);

  // useEffect(() => {
  //   setTimeout(
  //     () => {
  //       setShow(true);
  //     },
  //     process.env.NX_STAGE === 'local' ||
  //       process.env.NX_STAGE === 'dev' ||
  //       process.env.NX_STAGE === 'staging'
  //       ? 0
  //       : 16000
  //   );
  // });

  useEffect(() => {
    if (window.matchMedia('(max-width: 639px)').matches) {
      setVideo(`${ASSETS}/bodypicker-video-instructions-mobile.mp4`);
    } else {
      setVideo(`${ASSETS}/bodypicker-video-instructions-desktop.mp4`);
    }
  }, []);

  return (
    <div className="h-screen w-full absolute bg-white">
      <div className="relative object-cover">
        <ReactPlayer
          url={video}
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload',
              },
              forceHLS: video?.includes('.m3u8'),
            },
          }}
          playing={playing}
          playsinline
          width="100vw"
          height="100vh"
          onEnded={() => setPlaying(false)}
          className=""
          style={{ objectFit: 'cover' }}
        />
        <div
          style={{ position: 'absolute', right: '2%' }}
          className="instruction-bottom-right-button"
        >
          <button
            onClick={() => setInstructions(false)}
            className={[
              'cursor-pointer rounded-md shadow items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white hover:opacity-90 focus:outline-none focus:ring transition duration-150 ease-in-out mt-8',
            ].join(' ')}
            style={{
              backgroundColor: primaryColor,
            }}
            data-cy="continue-button"
          >
            {language === 'es' ? verbiage.continue.es : verbiage.continue.en}
          </button>
        </div>
        {!playing && (
          <div
            style={{ position: 'absolute', left: '2%' }}
            className="instruction-bottom-left-button"
          >
            <span
              className="flex gap-1 text-sm"
              onClick={() => setPlaying(true)}
            >
              <ArrowPathIcon className="h-5 w-5" />

              {video === `${ASSETS}/bodypicker-video-instructions-desktop.mp4`
                ? language === 'es'
                  ? verbiage.replay.desktop.es
                  : verbiage.replay.desktop.en
                : language === 'es'
                  ? verbiage.replay.mobile.es
                  : verbiage.replay.mobile.en}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CCSInstructions;
