import {
  CarePlanComplaint,
  Genders,
  RequestStep,
  surveyIdsTypes,
} from '@chiroup/core';
import React from 'react';
import Consent from './consent/Consent';
import BasicInformation from './steps/BasicInformation';
import Insurance from './steps/Insurance';
import RequestSurvey from './steps/RequestSurvey';
import UploadInsuranceCard from './steps/UploadInsuranceCard';
import UploadPhotoId from './steps/UploadPhotoId';

type Props = {
  age: number;
  ccsFollowups?: string[];
  complaints?: Partial<CarePlanComplaint>;
  complete: (goToNext?: boolean) => Promise<void>;
  gender: Genders;
  guardian?: boolean;
  guardianName?: string;
  kiosk: boolean;
  language: 'en' | 'es';
  nextStep: (id?: string) => void;
  patientName?: string;
  prMap: { [key: string]: string };
  remainingSteps: number[];
  requestId: string;
  step: RequestStep;
  steps: RequestStep[];
  surveyIds?: string[];
  clinicId?: number;
  updateRequest?: (
    data: {
      age: number;
      gender: Genders;
      guardian?: boolean;
      guardianName?: string;
      id: string;
      language: 'en' | 'es';
      patientName?: string;
      prMap: { [key: string]: string };
      steps: RequestStep[];
    },
    callback?: () => void,
  ) => void;
  validationCode: string | number;
  weeks?: number;
  encounterId: string;
};

const RequestStepComponent: React.FC<Props> = ({
  step,
  nextStep,
  requestId,
  complete,
  language = 'en',
  gender,
  age,
  ccsFollowups,
  updateRequest,
  surveyIds,
  kiosk,
  validationCode,
  steps,
  guardian,
  patientName,
  guardianName,
  prMap,
  complaints,
  weeks,
  remainingSteps,
  encounterId,
  clinicId,
}) => {
  if (step.type === 'uploadPhotoId' && !kiosk) {
    return (
      <UploadPhotoId
        step={step}
        language={language}
        requestId={requestId}
        nextStep={nextStep}
        complete={complete}
        kiosk={kiosk}
        validationCode={validationCode}
        prMap={prMap}
      />
    );
  }

  if (step.type === 'uploadInsuranceCard' && !kiosk) {
    return (
      <UploadInsuranceCard
        step={step}
        language={language}
        requestId={requestId}
        nextStep={nextStep}
        complete={complete}
        validationCode={validationCode}
        kiosk={kiosk}
        prMap={prMap}
      />
    );
  }

  if (step.type === 'insurance' && !kiosk) {
    return (
      <Insurance
        step={step}
        requestId={requestId}
        // nextStep={nextStep}
        complete={complete}
        validationCode={validationCode}
        // kiosk={kiosk}
        prMap={prMap}
        clinicId={clinicId}
        language={language}
      />
    );
  }

  if (step.type === 'basicInformation') {
    return (
      <BasicInformation
        step={step}
        requestId={requestId}
        language={language}
        nextStep={nextStep}
        complete={complete}
        validationCode={validationCode}
        age={age}
        prMap={prMap}
      />
    );
  }

  if (step.type === 'survey' && step.patientSurveyId && step.survey) {
    return (
      <RequestSurvey
        requestId={requestId}
        surveyId={step.patientSurveyId}
        complete={complete}
        title={
          language === 'es' && step?.survey?.esTitle
            ? step?.survey?.esTitle
            : step.survey.title
        }
        subtitle={step.survey.subtitle}
        startMessage={step.survey.startMessage}
        endMessage={step.survey.endMessage}
        startQuestion={step.survey.currentQuestion}
        previousStartQuestion={step.survey.previousQuestion}
        started={step.survey.started}
        startProgress={step.survey.progress}
        language={language}
        gender={gender}
        age={age}
        nextStep={nextStep}
        ccsFollowups={ccsFollowups}
        surveyIds={surveyIds}
        validationCode={validationCode}
        updateRequest={updateRequest}
        prMap={prMap}
        complaints={complaints}
        weeks={weeks}
        remainingSteps={remainingSteps}
        kiosk={kiosk}
        encounterId={encounterId}
        surveyType={surveyIdsTypes[step.survey.id]}
      />
    );
  }

  if (step.type === 'consent') {
    return (
      <Consent
        steps={steps}
        requestId={requestId}
        patientConsentId={step.patientConsentId}
        consent={step.consent}
        complete={complete}
        guardian={guardian}
        patientName={patientName}
        guardianName={guardianName}
        kiosk={kiosk}
        validationCode={validationCode}
        prMap={prMap}
      />
    );
  }

  // TODO: Make this better - not useful to the user.
  return <div>{`${step.type} is not a valid step`}</div>;
};

export default RequestStepComponent;
