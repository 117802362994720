import {
  BodyParts,
  bodyParts,
  CCSBodyPickerQuestionIds,
  classNames,
  defaultBodyParts,
  Genders,
  getPartsSelectedExtra,
  LeftOrRight,
  Part,
  RequestStep,
  Side,
  SourceOfPain,
  SurveyQuestion,
} from '@chiroup/core';
import maleBack from '../images/male-back.png';
import maleFront from '../images/male-front.png';
import { Transition } from '@headlessui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ConfirmModal } from '../ConfirmModal';
import { OpenClosedStates } from '../states';

const questionContentVerbiage = {
  en: {
    next: 'Next',
    noneOfTheAbove: 'None of the above',
  },
  es: {
    next: 'Continuar',
    noneOfTheAbove: 'Ninguna de las anteriores',
  },
};

type Props = {
  show: boolean;
  setIsSubmitting: (val: boolean) => void;
  isSubmitting: boolean;
  submitSurvey: (
    questions: SurveyQuestion[],
    kiosk: boolean,
    spanishQuestions?: SurveyQuestion[],
    ccsFollowupSurveyIds?: string[],
    validationCode?: string | number,
  ) => Promise<any>;
  complete: () => void;
  kiosk: boolean;
  markSurveyComplete: () => void;
  setSubmitText: (val: string) => void;
  submitVerbiage: {
    more: { en: string; es: string };
    done: { en: string; es: string };
  };
  validationCode: string | number;
  surveyIds?: string[];
  ccsFollowups?: string[];
  bodyPickerSubmit: (
    parts: BodyParts,
    sourceOfPain: SourceOfPain,
    spanishQuestions: SurveyQuestion[],
    questions: SurveyQuestion[],
    kiosk: boolean,
    language: string,
    currentQuestion: SurveyQuestion,
    setIsSubmitting: (val: boolean) => void,
    isSubmitting: boolean,
    submitSurvey: (
      questions: SurveyQuestion[],
      kiosk: boolean,
      spanishQuestions?: SurveyQuestion[],
      ccsFollowupSurveyIds?: string[],
      validationCode?: string | number,
    ) => Promise<any>,
    complete: () => void,
    markSurveyComplete: () => void,
    setSubmitText: (val: string) => void,
    submitVerbiage: {
      more: { en: string; es: string };
      done: { en: string; es: string };
    },
    setSpanishQuestions: React.Dispatch<React.SetStateAction<SurveyQuestion[]>>,
    setQuestions: React.Dispatch<React.SetStateAction<SurveyQuestion[]>>,
    setCurrentQuestion: (val: SurveyQuestion) => void,
    validationCode: string | number,
    additionalComplaint: boolean,
    isProvider?: boolean,
    aaWI?: string,
    surveyIds?: string[],
    ccsFollowups?: string[],
    updateRequest?: (
      data: {
        prMap: { [key: string]: string };
        steps: RequestStep[];
        language: 'en' | 'es';
        gender: Genders;
        age: number;
        id: string;
      },
      callback?: () => void,
    ) => void,
    startingProblemArea?: number,
    abbreviatedCCS?: boolean,
  ) => void;
  language?: 'en' | 'es';
  id?: string;
  primaryColor?: string;
  currentQuestion: SurveyQuestion;
  spanishQuestions: SurveyQuestion[];
  questions: SurveyQuestion[];
  setSpanishQuestions: React.Dispatch<React.SetStateAction<SurveyQuestion[]>>;
  setQuestions: React.Dispatch<React.SetStateAction<SurveyQuestion[]>>;
  setCurrentQuestion: (value: SurveyQuestion) => void;
  aaWI?: string;
  updateRequest?: (
    data: {
      prMap: { [key: string]: string };
      steps: RequestStep[];
      language: 'en' | 'es';
      gender: Genders;
      age: number;
      id: string;
    },
    callback?: () => void,
  ) => void;
  startingProblemArea?: number;
  myScreen?: boolean;
  additionalComplaints?: boolean;
  abbreviatedCCS?: boolean;
};

const Body = ({
  show,
  bodyPickerSubmit,
  language = 'en',
  id,
  primaryColor,
  currentQuestion,
  spanishQuestions,
  questions,
  setSpanishQuestions,
  setQuestions,
  setCurrentQuestion,
  isSubmitting,
  setIsSubmitting,
  aaWI,
  submitSurvey,
  complete,
  markSurveyComplete,
  setSubmitText,
  submitVerbiage,
  validationCode,
  surveyIds,
  ccsFollowups,
  updateRequest,
  startingProblemArea,
  kiosk,
  myScreen = false,
  additionalComplaints = false,
  abbreviatedCCS = false,
}: Props) => {
  const [genderSelected] = useState<Genders>(Genders.male);
  const [sideSelected, setSideSelected] = useState<Side>('rear');
  const [selected, setSelected] = useState(defaultBodyParts);
  const [confirmContiguousModal, setConfirmContiguousModal] = useState(
    OpenClosedStates.Closed,
  );
  const [sourceOfPain, setSourceOfPain] = useState<
    SourceOfPain & { reset: boolean }
  >({ side: null, x: null, y: null, reset: false });

  const selectPart = (side: Side, part: Part, leftOrRight: LeftOrRight) => {
    setSelected((prev: any) => ({
      ...prev,
      [side]: {
        ...prev[side],
        [part]: {
          ...prev[side][part],
          [leftOrRight]: !prev[side][part][leftOrRight],
        },
      },
    }));
  };

  useEffect(() => {
    setQuestions((prev: SurveyQuestion[]) => {
      const primaryQuestion = prev.find(
        (q) => q.id === CCSBodyPickerQuestionIds.primary,
      );
      if (primaryQuestion) {
        primaryQuestion.problemArea = startingProblemArea
          ? startingProblemArea + 1
          : 1;
      }
      return prev;
    });
  }, [startingProblemArea, setQuestions]);

  useEffect(() => {
    const someAreSelected = Object.values(selected).some((parts) =>
      Object.values(parts).some((sides) => sides.left || sides.right),
    );
    if (!someAreSelected) {
      setSourceOfPain({ side: null, x: null, y: null, reset: false });
    }
  }, [selected]);

  const turnOver = () => {
    setSideSelected((prev) => (prev === 'front' ? 'rear' : 'front'));
  };

  const getConnectedParts = useCallback(
    (
      parts: {
        frontOrBack: Side;
        part: Part;
        leftOrRight: LeftOrRight;
        partString: string;
        connectedParts: string[];
      }[],
      connectedParts: {
        frontOrBack: Side;
        part: Part;
        leftOrRight: LeftOrRight;
        partString: string;
        connectedParts: string[];
      }[] = [],
    ): {
      frontOrBack: Side;
      part: Part;
      leftOrRight: LeftOrRight;
      partString: string;
      connectedParts: string[];
    }[] => {
      // We haven't started yet, so lets add the first part and get started...
      if (parts?.length && !connectedParts.length) {
        return getConnectedParts(parts, [parts[0]]);
      } else {
        const partsThatAreNotConnectedYet = parts.filter(
          (part) =>
            !connectedParts.some((connectedPart) => {
              return part.partString === connectedPart.partString;
            }),
        );
        if (!partsThatAreNotConnectedYet?.length) {
          return connectedParts;
        }
        const partsConnectedToTheConnectedParts =
          partsThatAreNotConnectedYet.filter((part) => {
            return connectedParts.some((connectedPart) => {
              return part.connectedParts.includes(connectedPart.partString);
            });
          });
        if (!partsConnectedToTheConnectedParts?.length) {
          return connectedParts;
        }
        return getConnectedParts(
          parts,
          connectedParts.concat(partsConnectedToTheConnectedParts),
        );
      }
    },
    [],
  );

  const areAllPartsContiguous = useCallback(
    (
      parts: {
        frontOrBack: Side;
        part: Part;
        leftOrRight: LeftOrRight;
        partString: string;
        connectedParts: string[];
      }[],
    ): boolean => {
      const partsThatAreContiguous = getConnectedParts(parts);
      return partsThatAreContiguous?.length === parts?.length;
    },
    [getConnectedParts],
  );

  const submit = useCallback(() => {
    bodyPickerSubmit(
      selected,
      {
        side: sourceOfPain.side,
        x: sourceOfPain.x,
        y: sourceOfPain.y,
      },
      spanishQuestions,
      questions,
      kiosk,
      language,
      currentQuestion,
      setIsSubmitting,
      isSubmitting,
      submitSurvey,
      complete,
      markSurveyComplete,
      setSubmitText,
      submitVerbiage,
      setSpanishQuestions,
      setQuestions,
      setCurrentQuestion,
      validationCode,
      additionalComplaints,
      myScreen,
      aaWI,
      surveyIds,
      ccsFollowups,
      updateRequest,
      startingProblemArea,
      abbreviatedCCS,
    );
  }, [
    bodyPickerSubmit,
    selected,
    sourceOfPain.side,
    sourceOfPain.x,
    sourceOfPain.y,
    spanishQuestions,
    questions,
    kiosk,
    language,
    currentQuestion,
    setIsSubmitting,
    isSubmitting,
    submitSurvey,
    complete,
    markSurveyComplete,
    setSubmitText,
    submitVerbiage,
    setSpanishQuestions,
    setQuestions,
    setCurrentQuestion,
    validationCode,
    additionalComplaints,
    myScreen,
    aaWI,
    surveyIds,
    ccsFollowups,
    updateRequest,
    startingProblemArea,
    abbreviatedCCS,
  ]);

  const onSubmit = useCallback(() => {
    if (id !== CCSBodyPickerQuestionIds.tertiary) {
      const partsSelected = getPartsSelectedExtra(selected);
      const allContiguous = areAllPartsContiguous(partsSelected);

      if (!allContiguous) {
        return setConfirmContiguousModal(OpenClosedStates.Open);
      }
    }

    submit();
  }, [id, submit, selected, areAllPartsContiguous]);

  const disabled = useMemo(() => {
    return !Object.values(selected).some((whatever) =>
      Object.values(whatever).some(
        (part) => part.left === true || part.right === true,
      ),
    );
  }, [selected]);

  const onClickSourceOfPain = (
    body: string,
    side: 'front' | 'rear',
    e: any,
  ) => {
    if (!sourceOfPain.side) {
      const elem = document.getElementById(body);
      const rect = elem?.getBoundingClientRect();
      if (rect) {
        const actualBodyHeight = elem?.clientHeight;
        const scale = 1200 / (actualBodyHeight || 1200);
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setSourceOfPain({
          side,
          x: x * scale,
          y: y * scale,
          reset: false,
        });
      }
    }
  };

  const repositionSourceOfPain = () => {
    setSourceOfPain({
      side: null,
      x: null,
      y: null,
      reset: true,
    });
  };

  useEffect(() => {
    const sheetToBeRemoved = document.getElementById('slider-styles');
    const sheetParent = sheetToBeRemoved?.parentNode;
    if (sheetParent) {
      sheetParent.removeChild(sheetToBeRemoved);
    }
    const sheet = document.createElement('style');
    sheet.setAttribute('id', 'slider-styles');
    sheet.innerHTML = `
          @keyframes tronFilterHover {
              0% {
                  -webkit-filter: drop-shadow(-0.75px 0px 6px ${primaryColor});
                  filter: drop-shadow(-0.75px 0px 6px ${primaryColor});
                  stroke: ${primaryColor};
              }
              100% {
                  -webkit-filter: drop-shadow(-0.75px 0px 6px ${primaryColor});
                  filter: drop-shadow(-0.75px 0px 6px ${primaryColor});
                  stroke: ${primaryColor};
              }
          }

          @keyframes tronFilter {
              0% {
                  -webkit-filter: drop-shadow(-0.75px 0px 6px ${primaryColor});
                  filter: drop-shadow(-0.75px 0px 6px ${primaryColor});
                  stroke: ${primaryColor};
              }
              100% {
                  -webkit-filter: drop-shadow(-0.75px 0px 6px ${primaryColor});
                  filter: drop-shadow(-0.75px 0px 6px ${primaryColor});
                  stroke: ${primaryColor};
              }
          }
          `;
    document.body.appendChild(sheet);
    return () => {
      const sheetToBeRemoved = document.getElementById('slider-styles');
      const sheetParent = sheetToBeRemoved?.parentNode;
      if (sheetParent) {
        sheetParent.removeChild(sheetToBeRemoved);
      }
    };
  }, [primaryColor]);

  return (
    <>
      <Transition
        show={show}
        enter="transition ease-out duration-300"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-300"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className="w-full"
      >
        <>
          <div className="flex-row justify-center hidden w-full gap-24 mx-auto lg:flex">
            <div className="text-xl font-medium text-gray-500 sm:text-2xl">
              <div
                className={classNames(
                  'flex flex-col py-12',
                  myScreen
                    ? 'h-[800px] max-h-screen w-auto'
                    : 'w-auto h-screen',
                )}
              >
                <div className="relative flex flex-row justify-center w-auto h-full">
                  <div className="flex items-center w-auto h-full">
                    <span className="mt-24 text-4xl text-gray-700">
                      {language === 'es' ? 'I' : 'L'}
                    </span>
                  </div>
                  <svg
                    viewBox="0 0 405 1200"
                    className="body-picker"
                    version="1.1"
                    style={{
                      height: '100%',
                      width: 'auto',
                    }}
                    id="rear-desktop"
                  >
                    <image
                      width="405"
                      height="1200"
                      xlinkHref={maleBack}
                      style={{
                        height: '100%',
                        width: 'auto',
                      }}
                    />
                    {bodyParts[genderSelected].rear.map((part, i) => (
                      <path
                        key={i}
                        className={
                          selected?.rear?.[part.part]?.[part.leftOrRight]
                            ? 'selected'
                            : ''
                        }
                        onClick={(e) => {
                          onClickSourceOfPain('rear-desktop', 'rear', e);
                          if (!sourceOfPain.reset) {
                            selectPart('rear', part.part, part.leftOrRight);
                          }
                        }}
                        fill="transparent"
                        d={part.d}
                      />
                    ))}
                    {sourceOfPain?.side === 'rear' &&
                      typeof sourceOfPain.x === 'number' &&
                      typeof sourceOfPain.y === 'number' && (
                        <svg
                          x={sourceOfPain.x - 15}
                          y={sourceOfPain.y - 15}
                          width="30"
                          height="30"
                          viewBox="0 0 40 40"
                          xmlns="http://www.w3.org/2000/svg"
                          className="z-50 cursor-pointer"
                          onClick={repositionSourceOfPain}
                        >
                          <circle
                            cx="20"
                            cy="20"
                            fill="none"
                            r="10"
                            stroke="#f472b6"
                            strokeWidth="2"
                          >
                            <animate
                              attributeName="r"
                              from="8"
                              to="20"
                              dur="1.5s"
                              begin="0s"
                              repeatCount="indefinite"
                            />
                            <animate
                              attributeName="opacity"
                              from="1"
                              to="0"
                              dur="1.5s"
                              begin="0s"
                              repeatCount="indefinite"
                            />
                          </circle>
                          <circle cx="20" cy="20" fill="#f472b6" r="10" />
                        </svg>
                      )}
                  </svg>
                  <div className="flex items-center h-full">
                    <span className="mt-24 text-4xl text-gray-700">
                      {language === 'es' ? 'D' : 'R'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-xl font-medium text-gray-500 sm:text-2xl">
              <div
                className={classNames(
                  'flex flex-col py-12',
                  myScreen
                    ? 'h-[800px] max-h-screen w-auto'
                    : 'w-auto h-screen',
                )}
              >
                <div className="relative flex flex-row justify-center w-auto h-full">
                  <div className="flex items-center w-auto h-full">
                    <span className="mt-24 text-4xl text-gray-700">
                      {' '}
                      {language === 'es' ? 'D' : 'R'}
                    </span>
                  </div>
                  <svg
                    viewBox="0 0 405 1200"
                    className="body-picker"
                    version="1.1"
                    style={{
                      height: '100%',
                      width: 'auto',
                    }}
                    id="front-desktop"
                  >
                    <image
                      width="405"
                      height="1200"
                      xlinkHref={maleFront}
                      style={{
                        height: '100%',
                        width: 'auto',
                      }}
                    />

                    {bodyParts[genderSelected].front.map((part, i) => (
                      <path
                        key={i}
                        className={
                          selected?.front?.[part.part]?.[part.leftOrRight]
                            ? 'selected'
                            : ''
                        }
                        onClick={(e) => {
                          onClickSourceOfPain('front-desktop', 'front', e);
                          if (!sourceOfPain.reset) {
                            selectPart('front', part.part, part.leftOrRight);
                          }
                        }}
                        fill="transparent"
                        d={part.d}
                      />
                    ))}
                    {sourceOfPain?.side === 'front' &&
                      typeof sourceOfPain.x === 'number' &&
                      typeof sourceOfPain.y === 'number' && (
                        <svg
                          x={sourceOfPain.x - 15}
                          y={sourceOfPain.y - 15}
                          width="30"
                          height="30"
                          viewBox="0 0 40 40"
                          xmlns="http://www.w3.org/2000/svg"
                          className="z-50 cursor-pointer"
                          onClick={repositionSourceOfPain}
                        >
                          <circle
                            cx="20"
                            cy="20"
                            fill="none"
                            r="10"
                            stroke="#f472b6"
                            strokeWidth="2"
                          >
                            <animate
                              attributeName="r"
                              from="8"
                              to="20"
                              dur="1.5s"
                              begin="0s"
                              repeatCount="indefinite"
                            />
                            <animate
                              attributeName="opacity"
                              from="1"
                              to="0"
                              dur="1.5s"
                              begin="0s"
                              repeatCount="indefinite"
                            />
                          </circle>
                          <circle cx="20" cy="20" fill="#f472b6" r="10" />
                        </svg>
                      )}
                  </svg>
                  <div className="flex items-center h-full">
                    <span className="mt-24 text-4xl text-gray-700">
                      {language === 'es' ? 'I' : 'L'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center w-full lg:hidden">
            <div className="justify-center w-full max-w-4xl">
              <div className="flex flex-col">
                <div className="text-xl font-medium text-gray-500 sm:text-2xl">
                  <div
                    className={classNames(
                      'flex flex-col py-12',
                      myScreen
                        ? 'h-[800px] max-h-screen w-auto'
                        : 'w-auto h-screen',
                    )}
                  >
                    <div className="relative flex flex-row justify-center w-auto h-full">
                      <div className="flex items-center w-auto h-full">
                        <span className="mt-24 text-4xl text-gray-700">
                          {sideSelected === 'front' ? 'R' : 'L'}
                        </span>
                      </div>
                      <svg
                        viewBox="0 0 405 1200"
                        className="body-picker"
                        version="1.1"
                        style={{
                          height: '100%',
                          width: 'auto',
                        }}
                        id="mobile"
                      >
                        <image
                          width="405"
                          height="1200"
                          xlinkHref={
                            sideSelected === 'rear' ? maleBack : maleFront
                          }
                          style={{
                            height: '100%',
                            width: 'auto',
                          }}
                        />

                        {bodyParts[genderSelected][sideSelected].map(
                          (part, i) => (
                            <path
                              key={i}
                              className={
                                selected?.[sideSelected]?.[part.part]?.[
                                  part.leftOrRight
                                ]
                                  ? 'selected'
                                  : ''
                              }
                              onClick={(e) => {
                                onClickSourceOfPain('mobile', sideSelected, e);
                                if (!sourceOfPain.reset) {
                                  selectPart(
                                    sideSelected,
                                    part.part,
                                    part.leftOrRight,
                                  );
                                }
                              }}
                              fill="transparent"
                              data-cy={i}
                              d={part.d}
                            />
                          ),
                        )}
                        {sourceOfPain?.side === sideSelected &&
                          typeof sourceOfPain.x === 'number' &&
                          typeof sourceOfPain.y === 'number' && (
                            <svg
                              x={sourceOfPain.x - 15}
                              y={sourceOfPain.y - 15}
                              width="30"
                              height="30"
                              viewBox="0 0 40 40"
                              xmlns="http://www.w3.org/2000/svg"
                              className="z-50 cursor-pointer"
                              onClick={repositionSourceOfPain}
                            >
                              <circle
                                cx="20"
                                cy="20"
                                fill="none"
                                r="10"
                                stroke="#f472b6"
                                strokeWidth="2"
                              >
                                <animate
                                  attributeName="r"
                                  from="8"
                                  to="20"
                                  dur="1.5s"
                                  begin="0s"
                                  repeatCount="indefinite"
                                />
                                <animate
                                  attributeName="opacity"
                                  from="1"
                                  to="0"
                                  dur="1.5s"
                                  begin="0s"
                                  repeatCount="indefinite"
                                />
                              </circle>
                              <circle cx="20" cy="20" fill="#f472b6" r="10" />
                            </svg>
                          )}
                      </svg>
                      <div className="flex items-center h-full">
                        <span className="mt-24 text-4xl text-gray-700">
                          {sideSelected === 'front' ? 'L' : 'R'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="absolute z-10 flex justify-center left-4 bottom-4">
                    <div className="flex justify-center">
                      <div className="flex items-center text-center">
                        <div className="text-base font-medium leading-6 text-gray-900">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            onClick={turnOver}
                            className="w-10 h-10 cursor-pointer"
                          >
                            <polyline points="23 4 23 10 17 10" />
                            <path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            onClick={onSubmit}
            disabled={disabled}
            className="absolute items-center justify-center px-5 py-3 mt-8 text-base font-medium text-white transition duration-150 ease-in-out border border-transparent rounded-md shadow cursor-pointer right-4 bottom-4 hover:opacity-90 focus:outline-none focus:ring"
            style={{
              backgroundColor: primaryColor,
            }}
            data-cy="next-button"
          >
            {questionContentVerbiage[language === 'es' ? 'es' : 'en'].next}
          </button>
        </>
      </Transition>
      <ConfirmModal
        isOpen={confirmContiguousModal === OpenClosedStates.Open}
        confirm={() => submit()}
        close={() => {
          setSelected(defaultBodyParts);
          setConfirmContiguousModal(OpenClosedStates.Closed);
          setSourceOfPain({ side: null, x: null, y: null, reset: false });
        }}
        confirmText={language === 'es' ? 'Continuar' : `Continue`}
        cancelText={language === 'es' ? 'Intentar nuevamente' : `Try again`}
        title={language === 'es' ? '¿Estás seguro?' : `Are you sure?`}
        description={
          language === 'es'
            ? 'Parece que algunas áreas que selecciono no están relacionadas entre ellas. ¿Está seguro que desea continuar?'
            : `It looks like you selected some areas that might not be related. Are you sure you want to continue?`
        }
        icon={
          <QuestionMarkCircleIcon className="w-6 h-6 text-gray-600 dark:text-darkGray-400" />
        }
        primaryColor={primaryColor}
      />
    </>
  );
};

export default Body;
