import React from 'react';

type Props = {
  selectedYear: number;
  onChange: (val: number) => void;
  close: () => void;
  startIndex: number;
  years: number[];
  primaryColor: string;
};

const YearSelect: React.FC<Props> = ({
  onChange,
  selectedYear,
  close,
  startIndex,
  years,
  primaryColor,
}) => {
  const handleYearSelect = (year: number) => {
    onChange(year);
    close();
  };

  return (
    <div className="grid grid-cols-3 gap-x-10 gap-y-2">
      {years
        .slice(startIndex, startIndex + 18)
        .reverse()
        .map((year) => {
          const isSelected = year === selectedYear;

          return (
            <div
              onClick={() => handleYearSelect(year)}
              key={year}
              className={`col-span-1 text-center cursor-pointer px-3  rounded-full transition duration-150 ease-in-out hover:bg-gray-100 focus:bg-gray-100 focus:text-gray-900 focus:outline-none p-1  ${
                isSelected ? `text-white` : 'text-gray-600 hover:text-gray-900'
              }`}
              style={isSelected ? { backgroundColor: primaryColor } : {}}
            >
              {year}
            </div>
          );
        })}
    </div>
  );
};

export default YearSelect;
