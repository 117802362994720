import { ReevalSurvey } from '@chiroup/components';
import { Genders, RequestStep, SurveyQuestion } from '@chiroup/core';
import { useContext } from 'react';
import { MeContext } from '../../contexts/me.context';
import requestService from '../../services/request.service';

type Props = {
  ccsFollowups?: string[];
  startQuestion: SurveyQuestion;
  complete: () => void;
  id: string;
  kiosk: boolean;
  language: 'en' | 'es' | undefined;
  surveyId: string;
  surveyIds?: string[];
  complaints: any;
  markSurveyComplete: () => void;
  updateRequest?: (
    data: {
      prMap: { [key: string]: string };
      steps: RequestStep[];
      language: 'en' | 'es';
      gender: Genders;
      age: number;
      id: string;
    },
    callback?: () => void,
  ) => void;
  validationCode: string | number;
  prMap: { [key: string]: string };
  weeks?: number;
  gender: Genders;
  age: number;
  encounterId?: string;
};

const InterReeval: React.FC<Props> = ({
  ccsFollowups,
  complete,
  id,
  language,
  surveyId,
  surveyIds,
  updateRequest,
  validationCode,
  prMap,
  markSurveyComplete,
  complaints,
  startQuestion,
  weeks,
  age,
  gender,
  kiosk,
  encounterId,
}: Props) => {
  const {
    clinicInfo: { primaryColor },
  } = useContext(MeContext);

  const submit = (
    questions: SurveyQuestion[],
    kiosk: boolean,
    spanishQuestions?: SurveyQuestion[],
    ccsFollowupSurveyIds?: string[],
    validationCode?: string | number,
    encounterId?: string,
  ) => {
    return requestService.submitSurvey(
      id,
      surveyId,
      questions,
      kiosk,
      prMap,
      spanishQuestions,
      ccsFollowupSurveyIds,
      validationCode,
      encounterId,
    );
  };

  return (
    <ReevalSurvey
      language={language}
      primaryColor={primaryColor}
      complete={complete}
      ccsFollowups={ccsFollowups}
      markSurveyComplete={markSurveyComplete}
      updateRequest={updateRequest}
      surveyIds={surveyIds}
      validationCode={validationCode}
      prMap={prMap}
      submitSurvey={submit}
      complaints={complaints || []}
      startQuestion={startQuestion}
      weeks={weeks}
      age={age}
      gender={gender}
      kiosk={kiosk}
    />
  );
};

export default InterReeval;
